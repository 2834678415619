import { v4 } from 'uuid';

export interface TieudeLangngheChiaseHocsinhDNG {
	ID: string;
	title: string;
	subtitle: string;
	content: string;
}
export const tieuDelangngheChiaseHocsinhDNGDefaultValue: TieudeLangngheChiaseHocsinhDNG = {
	content:
		'Chúng tôi đã ngày càng khẳng định được uy tín và vị thế với các đối tác trong nước và phía Nhật Bản, đặc biệt là sự tin yêu của các bậc phụ huynh về các em học sinh trên khắp mọi miền Tổ quốc',
	subtitle: 'Lắng nghe chia sẻ từ các học sinh về',
	title: 'DNG EDUCATION',
	ID: v4(),
};
