import { DetailedHTMLProps, ImgHTMLAttributes } from 'react';
import { v4 } from 'uuid';
import { externalPublicMediaUrl, routerBasename } from '../../environments/environments';

export const Image = (props: DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) => {
	function newSrc() {
		if (props.src) {
			if (process.env.NODE_ENV === 'production') {
				if (props.src.includes('http')) {
					return props.src;
				} else {
					return `${routerBasename}${props.src}`;
				}
			} else {
				if (props.src.includes('http')) {
					return props.src;
				} else {
					return `${externalPublicMediaUrl}${props.src}`;
				}
			}
		} else {
			return undefined;
		}
	}
	return (
		<img
			{...props}
			className={props.className}
			src={newSrc()}
			width={props.width}
			height={props.height}
			alt={props.alt || v4()}
		/>
	);
};
