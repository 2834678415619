import { v4 } from 'uuid';

export interface DvPageTieudeLoiichVuottroi {
	ID: string;
	title: string;
	subtitle: string;
}
export const dvPageTieudeLoiichVuottroiDefaultValue: DvPageTieudeLoiichVuottroi = {
	subtitle: 'DU HỌC NGHỀ VỚI',
	title: '5 LỢI ÍCH VƯỢT TRỘI',
	ID: v4(),
};
