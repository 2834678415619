import * as React from 'react';
import ButtonUnstyled, { ButtonUnstyledProps } from '@mui/base/ButtonUnstyled';

export const PrimaryHoverGradientBlueCyanButton = React.forwardRef(function PrimaryButton(
	props: ButtonUnstyledProps,
	ref: React.ForwardedRef<HTMLButtonElement>,
) {
	return (
		<ButtonUnstyled
			{...props}
			ref={ref}
			className='text-lg font-bold rounded-xl hover:bg-gradient-to-br from-blue-700 to-cyan-500 hover:text-white py-2 px-4 duration-500 h-fit active:scale-90 active:duration-100 flex items-center'
		/>
	);
});

export const PrimaryBlackTextButton = React.forwardRef(function PrimaryButton(
	props: ButtonUnstyledProps,
	ref: React.ForwardedRef<HTMLButtonElement>,
) {
	return (
		<ButtonUnstyled
			{...props}
			ref={ref}
			className='text-lg py-2 px-4 hover:scale-105 active:scale-90 active:bg-black active:text-white rounded-2xl duration-500 flex justify-center items-center'
		/>
	);
});

export const PrimaryBlackButton = React.forwardRef(function PrimaryButton(
	props: ButtonUnstyledProps,
	ref: React.ForwardedRef<HTMLButtonElement>,
) {
	return (
		<ButtonUnstyled
			{...props}
			ref={ref}
			className='text-lg py-1 px-4 bg-black text-white border-2 border-transparent hover:bg-white hover:text-black hover:border-black active:scale-90 rounded-2xl duration-500 font-bold'
		/>
	);
});

export const PrimaryGreenButton = React.forwardRef(function PrimaryButton(
	props: ButtonUnstyledProps,
	ref: React.ForwardedRef<HTMLButtonElement>,
) {
	return (
		<ButtonUnstyled
			{...props}
			ref={ref}
			className='text-lg py-1 px-4 bg-green-500 text-white border-2 border-transparent hover:bg-white hover:text-green-500 hover:border-green-500 active:scale-90 rounded-2xl duration-500 font-bold'
		/>
	);
});

export const PrimaryOrangeButton = React.forwardRef(function PrimaryButton(
	props: ButtonUnstyledProps,
	ref: React.ForwardedRef<HTMLButtonElement>,
) {
	return (
		<ButtonUnstyled
			{...props}
			ref={ref}
			className='text-lg py-1 px-4 bg-[#f75947] text-white border-2 border-transparent hover:bg-white hover:text-[#f75947] hover:border-[#f75947] active:scale-90 rounded-2xl duration-500 font-bold'
		/>
	);
});

export const PrimaryEmeraldButton = React.forwardRef(function PrimaryButton(
	props: ButtonUnstyledProps,
	ref: React.ForwardedRef<HTMLButtonElement>,
) {
	return (
		<ButtonUnstyled
			{...props}
			ref={ref}
			className='text-lg py-1 px-4 bg-orange-500 text-white border-2 border-transparent hover:bg-white hover:text-orange-500 hover:border-orange-500 active:scale-90 rounded-2xl duration-500 font-bold'
		/>
	);
});

export const PrimaryOutlinedBlackButton = React.forwardRef(function PrimaryButton(
	props: ButtonUnstyledProps,
	ref: React.ForwardedRef<HTMLButtonElement>,
) {
	return (
		<ButtonUnstyled
			{...props}
			ref={ref}
			className='text-lg py-1 px-4 bg-white border-2 border-black border-transparent hover:bg-black hover:text-white active:scale-90 rounded-2xl duration-500 font-bold'
		/>
	);
});
