import React, { Fragment, useState } from 'react';
import { Box, BoxProps, TextField } from '@mui/material';
import { useQuery } from '@apollo/client';
import { gql_queries } from '../models/interface/gql_queries';
import { AppMediaEntity, BlogPostEntity, UserEntity } from '../models/apiGqlExportTypes';
import { WhiteDivider } from '../components/utils/Divider';
import { api_post_data } from '../environments/api_handler';
import { useNavigate } from 'react-router-dom';
import { PrimaryBlackButton } from '../components/utils/Button';
import { BodySingleBlock } from '../components/Layouts/BodySingleBlock';
import { getI18n } from 'react-i18next';
import { mTrans } from '../utils/i18n/locales/masterTranslateData';

export const InfoBox = React.forwardRef(function (props: BoxProps, ref: React.ForwardedRef<any>) {
	const newCls = props.className ? props.className : '';
	return (
		<Box
			ref={ref}
			className={`border-solid border-2 border-black p-4 rounded-2xl flex flex-col justify-between gap-4 ${newCls}`}
		>
			{props.children}
		</Box>
	);
});

const InfoBoxTitle = React.forwardRef(function (props: BoxProps, ref: React.ForwardedRef<any>) {
	return (
		<Box ref={ref} className='text-2xl font-bold uppercase'>
			{props.children}
		</Box>
	);
});

const InfoBoxContent = React.forwardRef(function (props: BoxProps, ref: React.ForwardedRef<any>) {
	return (
		<Box ref={ref} className='text-lg text-center'>
			{props.children}
		</Box>
	);
});

const InfoBoxFooter = React.forwardRef(function (props: BoxProps, ref: React.ForwardedRef<any>) {
	return (
		<Box ref={ref} className='flex flex-wrap justify-end gap-4'>
			{props.children}
		</Box>
	);
});

const BlogBox = (props: BoxProps) => {
	const navigate = useNavigate();
	const blog_post_query = useQuery(gql_queries.all_post_to_manage, {
		fetchPolicy: 'cache-and-network',
	});

	const allPosts: BlogPostEntity[] | undefined = blog_post_query.data?.all_blog_posts_for_management;

	return (
		<InfoBox {...props}>
			<InfoBoxTitle>Blog</InfoBoxTitle>
			<Box>
				<WhiteDivider />
			</Box>
			<InfoBoxContent>
				{allPosts ? allPosts.length : 0} {getI18n().t(mTrans.posts)}
			</InfoBoxContent>
			<InfoBoxFooter>
				<PrimaryBlackButton onClick={() => navigate('/blog')}>{getI18n().t(mTrans['Manage blog'])}</PrimaryBlackButton>
			</InfoBoxFooter>
		</InfoBox>
	);
};

const AdminBox = (props: BoxProps) => {
	const allAdminQuery = useQuery(gql_queries.all_admin_list, {
		fetchPolicy: 'cache-and-network',
	});

	const allAdmin: UserEntity[] | undefined = allAdminQuery.data?.all_admin_list;

	const [newAdmin, setNewAdmin] = useState('');

	return (
		<InfoBox {...props}>
			<InfoBoxTitle>{getI18n().t(mTrans['Admin list'])}</InfoBoxTitle>
			<Box>
				<WhiteDivider />
			</Box>
			<InfoBoxContent>
				{allAdmin ? (
					<>
						{allAdmin.map((item, i) => (
							<Fragment key={i}>
								<Box className='flex gap-4 flex-wrap justify-between items-center'>
									<span className='text-xs'>{item.email}</span>
									<span
										className='text-sm underline cursor-pointer'
										onClick={async () => {
											if (confirm('Are you sure?')) {
												const result = await api_post_data('admin/remove_admin', {
													ID: item.ID,
												});
												if (result.status === 200) {
													await allAdminQuery.refetch();
												}
											}
										}}
									>
										Xóa
									</span>
								</Box>
							</Fragment>
						))}
					</>
				) : (
					<></>
				)}
			</InfoBoxContent>
			<InfoBoxFooter>
				<Box className='w-full flex flex-col gap-4 items-end'>
					<TextField
						fullWidth
						size='small'
						label={getI18n().t(mTrans['New admin email'])}
						value={newAdmin}
						onChange={(e) => {
							setNewAdmin(e.target.value);
						}}
					/>
					<PrimaryBlackButton
						onClick={async () => {
							const result = await api_post_data('admin/set_admin', {
								email: newAdmin,
							});
							if (result.status === 201) {
								await allAdminQuery.refetch();
							}
						}}
					>
						{getI18n().t(mTrans.Confirm)}
					</PrimaryBlackButton>
				</Box>
			</InfoBoxFooter>
		</InfoBox>
	);
};

const MediaBox = (props: BoxProps) => {
	const allTrashMediaQuery = useQuery(gql_queries.allTrashMedias, {
		fetchPolicy: 'cache-and-network',
	});

	const alltrashMedias: AppMediaEntity[] | undefined = allTrashMediaQuery.data?.allTrashMedias;

	return (
		<InfoBox {...props}>
			<InfoBoxTitle>Media</InfoBoxTitle>
			<Box>
				<WhiteDivider />
			</Box>
			<InfoBoxContent>
				{alltrashMedias ? alltrashMedias.length : 0} {getI18n().t(mTrans['trash media'])}
			</InfoBoxContent>
			<InfoBoxFooter>
				<PrimaryBlackButton
					onClick={async () => {
						const result = await api_post_data('admin/delete_all_trash_medias', null);
						if (result.status === 201) {
							await allTrashMediaQuery.refetch();
						}
					}}
				>
					{getI18n().t(mTrans['Delete all trash media'])}
				</PrimaryBlackButton>
			</InfoBoxFooter>
		</InfoBox>
	);
};

const CacheBox = (props: BoxProps) => {
	return (
		<InfoBox {...props}>
			<InfoBoxTitle>Cache</InfoBoxTitle>
			<Box>
				<WhiteDivider />
			</Box>
			<Box className='w-full h-full flex items-center justify-center'>
				<button
					className='button button-gradient button-pink font-bold'
					onClick={async () => {
						await api_post_data('admin/clearCache', null);
					}}
				>
					Reset cache
				</button>
			</Box>
		</InfoBox>
	);
};

export const AdminHomePage = () => {
	return (
		<BodySingleBlock>
			<Box className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-8'>
				<BlogBox className='reveal-from-bottom' />
				<AdminBox className='reveal-from-bottom' />
				<MediaBox className='reveal-from-bottom' />
				<CacheBox className='reveal-from-bottom' />
			</Box>
		</BodySingleBlock>
	);
};
