import { v4 } from 'uuid';

export interface FooterTongquanveCongtyDNG {
	ID: string;
	title: string;
	content: string;
}
export const footerTongquanveCongtyDNGDefaultValue: FooterTongquanveCongtyDNG = {
	content:
		'Công ty TNHH Đinh Nguyễn Gia là đơn vị tư vấn giáo dục uy tín có trụ sở tại TP. HCM. Sở hữu đội ngũ chuyên viên giàu kinh nghiệm cùng mạng lưới đối tác toàn cầu.',
	title: 'CÔNG TY TNHH ĐINH NGUYỄN GIA',
	ID: v4(),
};
