import { v4 } from 'uuid';
import { OverallInformationJoditContent } from './overallInfoJoditContent.defaultValue';

export interface LessonPageTiengDucdanhchoban {
	ID: string;
	title: string;
	subtitle: string;
	content: OverallInformationJoditContent;
	btnText: string;
	btnUrl: string;
	image: string;
}
export const lessonPageTiengDucdanhchoban: LessonPageTiengDucdanhchoban = {
	btnText: 'Đăng ký',
	btnUrl: 'https://www.google.com',
	content: {
		content: 'Khóa học online HIỆU QUẢ với chi phí TIẾT KIỆM. Khóa học CHẤT LƯỢNG, đảm bảo được nguồn ĐẦU RA',
		images: [],
	},
	image: 'defaultImage.webp',
	subtitle: 'ĐÂY LÀ KHÓA TIẾNG ĐỨC DÀNH CHO BẠN',
	title: 'NẾU BẠN ĐANG TÌM KIẾM',
	ID: v4(),
};
