export const jodit_config: any = {
	buttons: [
		'source',
		'|',
		'bold',
		'strikethrough',
		'underline',
		'italic',
		'|',
		'ul',
		'ol',
		'|',
		'outdent',
		'indent',
		'lineHeight',
		'|',
		'font',
		'fontsize',
		'paragraph',
		'brush',
		'|',
		'image',
		'video',
		'table',
		'link',
		'|',
		'align',
		'undo',
		'redo',
		'|',
		'hr',
		'eraser',
		'copyformat',
		'|',
		'print',
		'about',
	],
	readonly: false,
};
