import { AdminAddCategoryPanel } from '../components/Blog/add_category_panel';
import { useDispatch, useSelector } from 'react-redux';
import { QueryResult, useQuery } from '@apollo/client';
import { gql_queries } from '../models/interface/gql_queries';
import { AppState } from '../store/core.store';
import { useEffect, useState } from 'react';
import { BlogPostCategoryDetails } from '../store/reducers/category_info.reducer';
import { blogPostAction } from '../store/reducers/blog_post_info.reducer';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { AdminEditCategoryPanel } from '../components/Blog/edit_category_panel';
import { TableWithSelection } from '../components/utils/TableWithSelection/TableWithSelection';
import { BlogPostCategoryTransferPanel } from '../components/Blog/BlogPostCategoryTransferPanel';
import { HeadCell } from '../models/interface/tableUI.interface';
import { appToolsActions } from '../store/reducers/appTools.reducer';
import { PrimaryGreenButton } from '../components/utils/Button';
import { BodySingleBlock } from '../components/Layouts/BodySingleBlock';
import { getI18n } from 'react-i18next';
import { mTrans } from '../utils/i18n/locales/masterTranslateData';

export const AddCategoryButton = ({ category_query }: { category_query: QueryResult }) => {
	const dispatcher = useDispatch();
	return (
		<PrimaryGreenButton
			className='text-2xl'
			onClick={() => {
				dispatcher(
					appToolsActions.set_apptools_info({
						dialog_isOpen: true,
						dialogContent: <AdminAddCategoryPanel category_query={category_query} />,
					}),
				);
			}}
		>
			<i className='fa-regular fa-plus' />
		</PrimaryGreenButton>
	);
};

export const CategorySelector = ({ category_query }: { category_query: QueryResult }) => {
	const dispatcher = useDispatch();

	const BlockInfo = useSelector((e: AppState) => e.blog_post);

	const [all_cats, set_all_cats] = useState<BlogPostCategoryDetails[]>([]);

	useEffect(() => {
		const cat_data: BlogPostCategoryDetails[] = category_query.data?.all_blog_post_categories;

		if (cat_data && cat_data.length > 0) {
			set_all_cats(cat_data);
		}
	}, [category_query]);

	const data_set = all_cats.map((item) => {
		return {
			value: item.ID ? item.ID : 0,
			label: item.name,
		};
	});

	useEffect(() => {
		if (data_set.length > 0 && !BlockInfo.details.category) {
			dispatcher(
				blogPostAction.set_blog_post_info({
					category: [],
				}),
			);
		}
	}, [data_set]);

	const item_list = () => {
		if (data_set.length > 0) {
			return data_set.map((item, i) => (
				<MenuItem key={i} value={item.value}>
					{item.label}
				</MenuItem>
			));
		}
		return undefined;
	};

	return (
		<>
			{data_set ? (
				<>
					<Box className='min-w-[150px]'>
						<FormControl fullWidth>
							<InputLabel>Danh mục</InputLabel>
							<Select
								value={item_list() && BlockInfo.details.category ? BlockInfo.details.category : []}
								label='Danh mục'
								multiple
								onChange={(e) => {
									const val = e.target.value;
									dispatcher(
										blogPostAction.set_blog_post_info({
											category: Array.isArray(val) ? val : [],
										}),
									);
								}}
							>
								{item_list()}
							</Select>
						</FormControl>
					</Box>
				</>
			) : (
				<></>
			)}
		</>
	);
};

export const CategoryBox = () => {
	const category_query = useQuery(gql_queries.all_blog_post_categories, {
		fetchPolicy: 'no-cache',
	});

	return (
		<Box className='flex gap-4'>
			<CategorySelector category_query={category_query} />
			<AddCategoryButton category_query={category_query} />
		</Box>
	);
};

const head_cells: HeadCell[] = [
	{
		id: 'ID',
		numeric: true,
		hidden: false,
		disablePadding: false,
		label: 'ID',
		filter: false,
	},
	{
		id: 'order',
		numeric: true,
		hidden: false,
		disablePadding: false,
		label: mTrans.Order,
		filter: true,
		clickable: true,
	},
	{
		id: 'name',
		numeric: false,
		hidden: false,
		disablePadding: false,
		label: mTrans['Category Name'],
		filter: true,
		clickable: true,
	},
];

export const CategoryManager = () => {
	const dispatcher = useDispatch();

	const category_query = useQuery(gql_queries.all_blog_post_categories);

	const [tableData, setTableData] = useState<any[]>([]);

	useEffect(() => {
		const data: any[] = category_query.data?.all_blog_post_categories;
		if (data) {
			setTableData(data);
		}
	}, [category_query]);

	const refresh_table_fn = async () => {
		await category_query.refetch();
		return;
	};

	const afterDeleteRowFn = async () => {
		return await category_query.refetch();
	};

	const on_row_click = (value: any) => {
		dispatcher(
			appToolsActions.set_apptools_info({
				dialog_isOpen: true,
				dialogContent: <AdminEditCategoryPanel category_query={category_query} category_ID={value.ID} />,
			}),
		);
	};

	return (
		<BodySingleBlock>
			<Box className='flex w-screen max-w-full justify-center'>
				<Box className='w-full py-[100px]'>
					<Box>
						<PrimaryGreenButton
							onClick={() => {
								dispatcher(
									appToolsActions.set_apptools_info({
										dialog_isOpen: true,
										dialogContent: <AdminAddCategoryPanel category_query={category_query} />,
									}),
								);
							}}
						>
							<i className='fa-regular fa-hand-back-point-right mr-4' />
							{getI18n().t(mTrans['Add new category'])}
						</PrimaryGreenButton>
					</Box>
					<Box className='mb-16'>
						<TableWithSelection
							table_title={getI18n().t(mTrans['Manage categories'])}
							headCells={head_cells}
							tableData={tableData}
							rowMainKey={'ID'}
							deleteRowApiUrlSuffix={'admin/delete_category'}
							afterDeleteRowFn={afterDeleteRowFn}
							refresh_table_fn={refresh_table_fn}
							row_click_action={on_row_click}
						/>
					</Box>
					<Box className='flex gap-8'>
						<PrimaryGreenButton
							onClick={() => {
								dispatcher(
									appToolsActions.set_apptools_info({
										dialog_isOpen: true,
										dialogContent: <BlogPostCategoryTransferPanel />,
									}),
								);
							}}
						>
							<i className='fa-regular fa-hand-back-point-right mr-4' />
							{getI18n().t(mTrans['Transfer posts between categories'])}
						</PrimaryGreenButton>
					</Box>
				</Box>
			</Box>
		</BodySingleBlock>
	);
};
