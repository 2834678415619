import { v4 } from 'uuid';

export interface TruonghocDoitacChienluocItem {
	ID: string;
	image: string;
	link: string;
	order: string;
}

export const truonghocDoitacChienluocSingleItem: TruonghocDoitacChienluocItem = {
	ID: v4(),
	image: 'defaultImage.webp',
	link: 'https://www.google.com',
	order: '1',
};

export const truonghocDoitacChienluocItemsDefaultVal: TruonghocDoitacChienluocItem[] = [
	{ ...truonghocDoitacChienluocSingleItem, ID: v4() },
	{ ...truonghocDoitacChienluocSingleItem, ID: v4() },
	{ ...truonghocDoitacChienluocSingleItem, ID: v4() },
	{ ...truonghocDoitacChienluocSingleItem, ID: v4() },
];
