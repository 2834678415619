import { Box, Card, CardContent, CardHeader, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { api_post_data } from '../../environments/api_handler';
import { QueryResult } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store/core.store';
import { blogPostCategoryInfoActions } from '../../store/reducers/category_info.reducer';
import { appToolsActions } from '../../store/reducers/appTools.reducer';
import { PrimaryGreenButton } from '../utils/Button';
import { getI18n } from 'react-i18next';
import { mTrans } from '../../utils/i18n/locales/masterTranslateData';

export const Category_Editor = () => {
	const dispatcher = useDispatch();

	const category_info = useSelector((e: AppState) => e.blogpost_category_info);

	return (
		<Box className='flex flex-col gap-4'>
			<TextField
				className='mb-3'
				variant='standard'
				label={getI18n().t(mTrans.Name)}
				error={category_info.touched && category_info.details.name === ''}
				onChange={(e) => {
					dispatcher(
						blogPostCategoryInfoActions.set_blog_post_category_details({
							name: e.target.value,
						}),
					);
				}}
				value={category_info.details.name}
			/>
			<TextField
				className='mb-3'
				variant='standard'
				label={getI18n().t(mTrans.Order)}
				onChange={(e) => {
					dispatcher(
						blogPostCategoryInfoActions.set_blog_post_category_details({
							order: parseFloat(e.target.value),
						}),
					);
				}}
				value={category_info.details.order ? category_info.details.order : ''}
			/>
			<div className='text-sm'>
				{`Khung "Thứ tự" là vị trí của danh mục khi hiển thị với khách, số nhỏ sẽ được hiển thị trước`}
			</div>
		</Box>
	);
};

export const AdminAddCategoryPanel = ({ category_query }: { category_query: QueryResult }) => {
	const category_info = useSelector((e: AppState) => e.blogpost_category_info);

	useEffect(() => {
		dispatcher(blogPostCategoryInfoActions.reset_blog_post_category_state());
	}, [category_info.details.ID]);

	const dispatcher = useDispatch();

	const formSubmit = () => {
		dispatcher(
			blogPostCategoryInfoActions.set_blog_post_category_state({
				touched: true,
			}),
		);
		if (window.confirm('Bạn có chắc muốn thêm mục này?')) {
			api_post_data('admin/add_blog_post_category', category_info.details)
				.then(async () => {
					await category_query.refetch();
					dispatcher(
						appToolsActions.set_apptools_info({
							dialog_isOpen: false,
						}),
					);
				})
				.catch((error) => console.log(error));
		}
	};

	return (
		<>
			<Card
				sx={{
					width: 500,
				}}
			>
				<CardHeader title={<div className='font-bold text-2xl'>{getI18n().t(mTrans['Add new category'])}</div>} />
				<CardContent>
					<Box className='mb-8'>
						<Category_Editor />
					</Box>
					<Box className='text-center'>
						<PrimaryGreenButton onClick={formSubmit}>{getI18n().t(mTrans.Confirm)}</PrimaryGreenButton>
					</Box>
				</CardContent>
			</Card>
		</>
	);
};
