import React from 'react';
import { Box, BoxProps } from '@mui/material';

export const InfoBox = React.forwardRef(function (props: BoxProps, ref: React.ForwardedRef<any>) {
	return (
		<Box ref={ref} className='border-solid border-2 border-white p-4 rounded-2xl flex flex-col justify-between gap-4'>
			{props.children}
		</Box>
	);
});
