import { v4 } from 'uuid';

export interface FooterTieudeFormTimhieutiengDuc {
	ID: string;
	title: string;
	subtitle: string;
	content: string;
	image: string;
}
export const footerTieudeFormTimhieutiengDucDefaultValue: FooterTieudeFormTimhieutiengDuc = {
	image: 'defaultImage.webp',
	content: 'Đội ngũ chuyên gia giàu kinh nghiệm của chúng tôi sẵn lòng tư vấn và đưa ra phương án phù hợp nhất',
	subtitle: 'Bạn đang tìm hiểu',
	title: 'Học tiếng Đức & Du học Đức?',
	ID: v4(),
};
