// noinspection HtmlRequiredAltAttribute

import React, { useEffect, Fragment } from 'react';
import { Box, BoxProps } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/core.store';
import { all_screen_size } from '../../../store/reducers/screensize.reducer';
import { menuItem_Categories, menuItems } from '../../../models/menuItems';
import { useLocation, useNavigate } from 'react-router-dom';
import { appToolsActions } from '../../../store/reducers/appTools.reducer';
import './_landingPageLeftSidebar.scss';
import { PrimaryBlackTextButton } from '../../utils/Button';
import { getI18n } from 'react-i18next';

const MenuItem = React.forwardRef(function (props: BoxProps, ref: React.ForwardedRef<HTMLElement>) {
	return (
		<Box ref={ref} {...props} className={'pr-4 cursor-pointer'.concat(' ', props.className ? props.className : '')}>
			{props.children}
		</Box>
	);
});

export const LandingPageLeftSidebar = () => {
	const dispatcher = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const isOpen = useSelector((e: AppState) => e.appTools.leftSideBarIsOpen);

	const screenSize = useSelector((e: AppState) => e.screenSize.value);

	const isXXl = () => {
		switch (screenSize) {
			case all_screen_size.xs:
				return false;
			case all_screen_size.sm:
				return false;
			case all_screen_size.md:
				return false;
			case all_screen_size.lg:
				return false;
			case all_screen_size.xl:
				return false;
			case all_screen_size.xxl:
				return true;
		}
	};

	const userInfo = useSelector((e: AppState) => e.user_info.data);

	useEffect(() => {
		if (isXXl()) {
			dispatcher(
				appToolsActions.set_apptools_info({
					leftSideBarIsOpen: true,
				}),
			);
		} else {
			dispatcher(
				appToolsActions.set_apptools_info({
					leftSideBarIsOpen: false,
				}),
			);
		}
	}, [screenSize]);

	useEffect(() => {
		const leftSidebarEl = document.getElementById('mainLeftSidebarWrapper');
		if (!leftSidebarEl) return;
		if (isOpen) {
			leftSidebarEl.classList.add('isActive');
		} else {
			leftSidebarEl.classList.remove('isActive');
		}
	}, [isOpen]);

	return (
		<Box id='mainLeftSidebarWrapper' className='w-0 xl:w-[250px] min-h-screen h-full bg-white relative'>
			<Box className='absolute right-0 h-full'>
				<Box className='w-[250px] flex flex-col gap-8 sticky px-4 top-0 h-[100vh] overflow-y-scroll pb-[100px]'>
					<Box className='pt-8 flex gap-4 items-center'>
						<img src='/img/dashboard-logo-3bjk23.png' className='w-12 aspect-1' />
						<span className='uppercase font-black text-2xl'>Dashboard</span>
					</Box>
					<Box className='border-t-2 border-black' />
					<Box className='flex flex-col gap-2'>
						{menuItem_Categories.map((cat, i) => (
							<Fragment key={i}>
								<Box className='mb-8'>
									<Box className='text-2xl font-bold uppercase mb-4'>{cat.name}</Box>
									{menuItems
										.filter((menu) => menu.cat === cat.id)
										.map((item, i) => {
											const activeItemCls = item.highlightPath.includes(location.pathname)
												? 'border-r-4 border-black font-bold'
												: '';
											const itemBoxActiveCls = item.highlightPath.includes(location.pathname)
												? 'bg-black text-white'
												: '';
											return (
												<Fragment key={i}>
													<MenuItem
														hidden={
															!item.include_roles.includes(userInfo.role) && item.exclude_roles.includes(userInfo.role)
														}
														className={activeItemCls}
														onClick={() => (item.link ? navigate(item.link) : item.action())}
													>
														<Box className='flex gap-4 items-center'>
															<Box
																className={'w-[40px] aspect-1 flex justify-center items-center rounded-2xl'.concat(
																	' ',
																	itemBoxActiveCls,
																)}
															>
																{item.icon}
															</Box>
															<Box className='py-2'>
																<span>{getI18n().t(item.name)}</span>
															</Box>
														</Box>
													</MenuItem>
												</Fragment>
											);
										})}
									{menuItems
										.filter((menu) => menu.cat === cat.id)
										.filter(
											(menu) =>
												!(!menu.include_roles.includes(userInfo.role) && menu.exclude_roles.includes(userInfo.role)),
										).length === 0 ? (
										<>No item</>
									) : (
										<></>
									)}
								</Box>
							</Fragment>
						))}
					</Box>
					<Box className='flex justify-center'>
						<PrimaryBlackTextButton
							onClick={() => {
								dispatcher(
									appToolsActions.set_apptools_info({
										leftSideBarIsOpen: false,
									}),
								);
							}}
						>
							<i className='fa-regular fa-left mr-2' /> Close
						</PrimaryBlackTextButton>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};
