import { v4 } from 'uuid';

export interface LessonPageNenchonTiengducDNGEduItem {
	ID: string;
	subtitle: string;
	title: string;
	order: string;
}

export const lessonPageNenchonTiengducDNGEduItem: LessonPageNenchonTiengducDNGEduItem = {
	ID: v4(),
	title: 'LỚP HỌC GIỚI HẠN SĨ SỐ, DƯỚI 10 HỌC VIÊN',
	subtitle: 'Tương tác tối đa với giáo viên, tạo động lực học tập với không khí sôi nổi',
	order: '1',
};

export const lessonPageNenchonTiengducDNGEduItemsDefaultValue: LessonPageNenchonTiengducDNGEduItem[] = [
	{ ...lessonPageNenchonTiengducDNGEduItem, ID: v4() },
	{ ...lessonPageNenchonTiengducDNGEduItem, ID: v4() },
	{ ...lessonPageNenchonTiengducDNGEduItem, ID: v4() },
	{ ...lessonPageNenchonTiengducDNGEduItem, ID: v4() },
];
