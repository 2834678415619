import React from 'react';

/* framer-motion and useInView here are used to animate the sections in when we reach them in the viewport
 */
import { motion } from 'framer-motion';
import { FramerMotionUseInView } from './FramerMotionUseInView';
import { BoxProps } from '@mui/material';

function AnimatedSlideInComponent({
	direction = 'left',
	offset = 30,
	children,
}: BoxProps & { direction: string; offset?: number }) {
	const { ref, isInView } = FramerMotionUseInView({ margin: `-${offset}px 0px 0px 0px` });

	const x: any = { target: '0%' };

	if (direction === 'left') x.initial = '-150%';
	else x.initial = '150%';

	return (
		<div ref={ref}>
			<motion.section
				initial={{ x: x.initial }}
				animate={{
					x: isInView ? x.target : 0,
					transitionEnd: {
						x: isInView ? 0 : undefined,
					},
				}}
				transition={{ type: 'spring', damping: 19 }}
			>
				{children}
			</motion.section>
		</div>
	);
}

export function AnimatedSlideInReveal({ disabled, children }: BoxProps & { disabled?: boolean }) {
	if (disabled) {
		return <>{children}</>;
	}

	if (!Array.isArray(children)) children = [children];

	const directions = ['left', 'right'];
	const childrenWithAnimation = Array.isArray(children) ? (
		children.map((child, i) => {
			return (
				<AnimatedSlideInComponent key={i} direction={directions[i % directions.length]}>
					{child}
				</AnimatedSlideInComponent>
			);
		})
	) : (
		<></>
	);
	return <>{childrenWithAnimation}</>;
}
