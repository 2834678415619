import { GoogleOAuthProvider } from '@react-oauth/google';
import { GgLoginBtn } from '../components/LandingPage/ggLogin';
import { globalSettings } from '../environments/environments';
import { Box, Divider } from '@mui/material';
import { BodySingleBlock } from '../components/Layouts/BodySingleBlock';

export const LoginPage = () => {
	return (
		<BodySingleBlock>
			<GoogleOAuthProvider clientId={globalSettings.google_client_id}>
				<Box className='py-[100px] flex flex-col justify-center items-center gap-8'>
					<Box className='text-4xl font-black uppercase'>
						<i className='fa-regular fa-right-to-bracket mr-4' />
						<span>Login with</span>
					</Box>
					<Box className='pt-4 pb-8 w-[400px]'>
						<Divider />
					</Box>
					<GgLoginBtn />
				</Box>
			</GoogleOAuthProvider>
		</BodySingleBlock>
	);
};
