import React, { useEffect } from 'react';
import 'reflect-metadata';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './App.scss';
import { createTheme, ThemeProvider } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { Window_Resize_Detector } from './components/shared/window_resize';
import AOS from 'aos';
import rootSaga from './store/saga/core_saga';
import { AppState, sagaMiddleware } from './store/core.store';
import { useSelector } from 'react-redux';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';

function LoadLanguage() {
	const dashboardLang = useSelector((e: AppState) => e.app_core_state.dashboardLanguage);

	const { i18n } = useTranslation();

	useEffect(() => {
		if (i18n.language !== dashboardLang.locale) {
			i18n.changeLanguage(dashboardLang.locale).catch();
		}
		return;
	}, [i18n.language]);
	return <></>;
}

function App() {
	AOS.init();
	sagaMiddleware.run(rootSaga);

	const theme_options = useSelector((e: AppState) => e.app_core_state.theme_options_client_FE);
	const theme = createTheme(theme_options);

	return (
		<ThemeProvider theme={theme}>
			<HelmetProvider>
				<Helmet>
					<link
						rel='stylesheet'
						href='https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'
					/>
				</Helmet>
				<div className='App prose'>
					<Window_Resize_Detector />
					<LoadLanguage />
					<Outlet />
				</div>
			</HelmetProvider>
		</ThemeProvider>
	);
}

export default App;
