import { v4 } from 'uuid';

export interface DkEmailTrangChu {
	ID: string;
	title: string;
	subtitle: string;
	btnText: string;
	image: string;
}
export const dkEmailTrangChuDefaultValue: DkEmailTrangChu = {
	image: 'defaultImage.webp',
	btnText: 'Đăng ký',
	subtitle: 'ĐĂNG KÝ EMAIL ĐỂ NHẬN ĐƯỢC',
	title: 'THÔNG TIN MỚI NHẤT TỪ DNG EDUCATION',
	ID: v4(),
};
