import JoditEditor from 'jodit-react';
import { Fragment, useEffect, useMemo, useRef } from 'react';
import { Box, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { jodit_config } from '../models/customJoditEditor';
import { useDispatch, useSelector } from 'react-redux';
import { cdnUrl } from '../environments/environments';
import { api_post_data } from '../environments/api_handler';
import { AppState } from '../store/core.store';
import { AppCoreLanguages, LanguagesMap, SingleLanguageSchema } from '../store/reducers/app_core_state.reducer';
import { blogPostAction, BlogPostContent } from '../store/reducers/blog_post_info.reducer';
import { useLocation, useNavigate } from 'react-router-dom';
import { OrangeAlert } from '../components/utils/Alert';
import { AxiosResponse } from 'axios';
import { AppMediaEntity } from '../models/apiGqlExportTypes';
import { CategoryBox } from './CategoryManager';
import { text_normalizer } from '../components/shared/text_normalizer';
import { BlackProgress } from '../components/utils/Progress';
import { appToolsActions } from '../store/reducers/appTools.reducer';
import { PrimaryEmeraldButton, PrimaryGreenButton } from '../components/utils/Button';
import { BodySingleBlock } from '../components/Layouts/BodySingleBlock';
import { getI18n, useTranslation } from 'react-i18next';
import { mTrans } from '../utils/i18n/locales/masterTranslateData';

export const PostAvatar = () => {
	const current_avatar = useSelector((e: AppState) => e.blog_post.details.avatar);

	const addFileRef = useRef<any>();

	const dispatcher = useDispatch();

	const isLoading = useSelector((e: AppState) => e.appTools.is_loading);

	async function upload_file(files: FileList) {
		dispatcher(
			appToolsActions.set_apptools_info({
				is_loading: true,
			}),
		);
		const form_data = new FormData();
		form_data.append('blog_post_image', files[0]);
		await api_post_data('admin/upload_blog_post_image', form_data)
			.then(
				(
					response: AxiosResponse<{
						message: string;
						payload: AppMediaEntity;
					}>,
				) => {
					dispatcher(
						blogPostAction.set_blog_post_info({
							avatar: response.data.payload.path,
						}),
					);
				},
			)
			.catch((error) => console.log(error));
		dispatcher(
			appToolsActions.set_apptools_info({
				is_loading: false,
			}),
		);
		return;
	}

	return (
		<Box className='flex justify-center flex-col gap-8'>
			<Box className='flex justify-center'>
				{current_avatar ? (
					<img
						src={`${cdnUrl}/${current_avatar}`}
						style={{
							width: 500,
							height: 'auto',
							maxWidth: '90vw',
						}}
					/>
				) : (
					<></>
				)}
			</Box>
			<input
				ref={addFileRef}
				hidden
				type='file'
				name='upload'
				onChange={async (e) => {
					if (e.target.files && e.target.files.length > 0) {
						await upload_file(e.target.files);
					}
				}}
			/>
			<Box className='flex'>
				{isLoading ? (
					<>
						<BlackProgress />
					</>
				) : (
					<>
						<PrimaryEmeraldButton onClick={() => addFileRef.current.click()}>
							<i className='fa-regular fa-image mr-4' /> {getI18n().t(mTrans.Thumbnail)}
						</PrimaryEmeraldButton>
					</>
				)}
			</Box>
		</Box>
	);
};

const Post_Title = ({ lang }: { lang: SingleLanguageSchema }) => {
	const dispatcher = useDispatch();

	const blog_post_info = useSelector((e: AppState) => e.blog_post);

	useEffect(() => {
		if (blog_post_info.autoUrl && blog_post_info.autoUrlSrc === lang.locale) {
			dispatcher(
				blogPostAction.set_blog_post_info({
					urlSlug: text_normalizer(blog_post_info.details.content[lang.locale].title),
				}),
			);
		}
	}, [blog_post_info.autoUrl]);

	return (
		<>
			<TextField
				label={getI18n().t(mTrans.Title)}
				error={blog_post_info.touched && !blog_post_info.details.content[lang.locale]?.title}
				value={blog_post_info.details.content[lang.locale]?.title || ''}
				onChange={(e) => {
					const newContent: BlogPostContent = {
						...blog_post_info.details.content[lang.locale],
						title: e.target.value,
					};
					if (blog_post_info.autoUrl && blog_post_info.autoUrlSrc === lang.locale) {
						dispatcher(
							blogPostAction.set_blog_post_info({
								urlSlug: text_normalizer(e.target.value),
								content: {
									...blog_post_info.details.content,
									[lang.locale]: {
										...newContent,
									},
								},
							}),
						);
					} else {
						dispatcher(
							blogPostAction.set_blog_post_info({
								content: {
									...blog_post_info.details.content,
									[lang.locale]: {
										...newContent,
									},
								},
							}),
						);
					}
				}}
			/>
			<FormControlLabel
				control={
					<Checkbox
						checked={blog_post_info.autoUrl && blog_post_info.autoUrlSrc === lang.locale}
						onChange={(e) => {
							if (e.target.checked) {
								dispatcher(
									blogPostAction.set_blog_post_state({
										autoUrl: e.target.checked,
										autoUrlSrc: lang.locale,
									}),
								);
							} else {
								dispatcher(
									blogPostAction.set_blog_post_state({
										autoUrl: false,
										autoUrlSrc: lang.locale,
									}),
								);
							}
						}}
					/>
				}
				label={getI18n().t(mTrans['Auto url'])}
			/>
		</>
	);
};

export const Post_UrlSlug = () => {
	const dispatcher = useDispatch();

	const blog_post_info = useSelector((e: AppState) => e.blog_post);

	return (
		<TextField
			label='Url Slug'
			error={blog_post_info.touched && !blog_post_info.details.urlSlug}
			value={blog_post_info.details.urlSlug}
			onChange={(e) => {
				dispatcher(
					blogPostAction.set_blog_post_info({
						urlSlug: e.target.value,
					}),
				);
			}}
		/>
	);
};

const Post_Excerpt = ({ lang }: { lang: SingleLanguageSchema }) => {
	const dispatcher = useDispatch();

	const blog_post_info = useSelector((e: AppState) => e.blog_post);

	return (
		<>
			<TextField
				label={getI18n().t(mTrans.Excerpt)}
				error={blog_post_info.touched && !blog_post_info.details.content[lang.locale]?.excerpt}
				value={blog_post_info.details.content[lang.locale]?.excerpt || ''}
				onChange={(e) => {
					const newContent: BlogPostContent = {
						...blog_post_info.details.content[lang.locale],
						excerpt: e.target.value,
					};
					dispatcher(
						blogPostAction.set_blog_post_info({
							content: {
								...blog_post_info.details.content,
								[lang.locale]: {
									...newContent,
								},
							},
						}),
					);
				}}
			/>
		</>
	);
};

const Post_Content = ({ lang }: { lang: SingleLanguageSchema }) => {
	const dispatcher = useDispatch();

	const langJoditContent = useSelector((e: AppState) => e.blog_post.langJoditContent);

	const blog_post_info = useSelector((e: AppState) => e.blog_post);

	const jodit_content = useMemo((): string => {
		return langJoditContent[lang.locale].content;
	}, [langJoditContent[lang.locale]]);

	useEffect(() => {
		if (jodit_content) {
			const data = langJoditContent[lang.locale];
			const resultImages = declare_post_images(jodit_content);
			dispatcher(
				blogPostAction.set_blog_post_info({
					images: {
						...blog_post_info.details.images,
						[data.locale]: resultImages,
					},
				}),
			);
		}
	}, [jodit_content]);

	const isLoading = useSelector((e: AppState) => e.appTools.is_loading);

	async function upload_file(files: FileList) {
		dispatcher(
			appToolsActions.set_apptools_info({
				is_loading: true,
			}),
		);
		const form_data = new FormData();
		form_data.append('blog_post_image', files[0]);
		await api_post_data('admin/upload_blog_post_image', form_data)
			.then((response) => {
				const new_content = `${blog_post_info.details.content[lang.locale]?.content}<img src="${cdnUrl}/${
					response.data.payload.path
				}" width="300" />`;
				dispatcher(
					blogPostAction.set_blog_post_state({
						langJoditContent: {
							...blog_post_info.langJoditContent,
							[lang.locale]: {
								locale: lang.locale,
								content: new_content,
							},
						},
					}),
				);
			})
			.catch((error) => console.log(error));
		dispatcher(
			appToolsActions.set_apptools_info({
				is_loading: false,
			}),
		);
		return;
	}

	function declare_post_images(content: string) {
		const img_set: string[] = [];
		const parser = new DOMParser();
		const doc = parser.parseFromString(content, 'text/html');

		Array.from(doc.querySelectorAll('img')).forEach((img) => {
			const src = img.src;
			if (!src.includes(cdnUrl)) return;
			img_set.push(src.replace(`${cdnUrl}/`, ''));
		});

		return img_set;
	}

	const addFileRef = useRef<any>();

	return (
		<>
			<Box className='mb-8'>
				<JoditEditor
					value={jodit_content}
					config={jodit_config}
					onChange={(newContent) => {
						const resultImages = declare_post_images(newContent);
						dispatcher(
							blogPostAction.set_blog_post_info({
								images: {
									...blog_post_info.details.images,
									[lang.locale]: resultImages,
								},
								content: {
									...blog_post_info.details.content,
									[lang.locale]: {
										...blog_post_info.details.content[lang.locale],
										content: newContent,
									},
								},
							}),
						);
					}}
				/>
			</Box>
			<Box className='flex mb-4'>
				<input
					ref={addFileRef}
					hidden
					type='file'
					name='upload'
					onChange={async (e) => {
						if (e.target.files && e.target.files.length > 0) {
							await upload_file(e.target.files);
						}
					}}
				/>
				{isLoading ? (
					<>
						<BlackProgress />
					</>
				) : (
					<>
						<PrimaryEmeraldButton onClick={() => addFileRef.current.click()}>
							<i className='fa-regular fa-image mr-4' /> {getI18n().t(mTrans['Add image'])}
						</PrimaryEmeraldButton>
					</>
				)}
			</Box>
		</>
	);
};

export const BlogPostCreator = ({ lang }: { lang: SingleLanguageSchema }) => {
	const blog_post_info = useSelector((e: AppState) => e.blog_post);

	return (
		<Box className='flex flex-col gap-8'>
			<Post_Title lang={lang} />
			<Post_Excerpt lang={lang} />
			<Post_Content lang={lang} />
			<Box>
				{blog_post_info.touched && !blog_post_info.details.avatar ? (
					<OrangeAlert>{getI18n().t(mTrans['You must upload a thumbnail for this post'])}</OrangeAlert>
				) : (
					<></>
				)}
				{blog_post_info.touched && !blog_post_info.details.content[lang.locale]?.content ? (
					<>
						<OrangeAlert>{getI18n().t(mTrans['Post content is now allowed to be empty'])}</OrangeAlert>
					</>
				) : (
					<></>
				)}
			</Box>
		</Box>
	);
};

const AdminCreateBlogPost = () => {
	const navigate = useNavigate();

	const dispatcher = useDispatch();
	const blog_post_info = useSelector((e: AppState) => e.blog_post);
	const app_is_loading = useSelector((e: AppState) => e.appTools.is_loading);

	const location = useLocation();
	useTranslation();

	useEffect(() => {
		dispatcher(blogPostAction.reset_blog_post_state());
	}, [location.pathname]);

	return (
		<BodySingleBlock>
			<Box className='flex flex-col gap-8'>
				<span className='text-[32px] font-bold'>{getI18n().t(mTrans['Add new post'])}</span>
				<PostAvatar />
				<Box className='flex justify-end'>
					<CategoryBox />
				</Box>
				<Post_UrlSlug />
				{Object.values(AppCoreLanguages).map((lang, i) => (
					<Fragment key={i}>
						<div className='text-2xl font-bold'>
							{getI18n().t(mTrans.Content)} [{lang}]
						</div>
						<BlogPostCreator lang={LanguagesMap[lang]} />
					</Fragment>
				))}
				<Box className='text-end'>
					<Box hidden={!app_is_loading}>
						<BlackProgress />
					</Box>
					<PrimaryGreenButton
						hidden={app_is_loading}
						onClick={async () => {
							dispatcher(
								blogPostAction.set_blog_post_state({
									touched: true,
								}),
							);
							dispatcher(appToolsActions.set_apptools_info({ is_loading: true }));
							const result = await api_post_data('admin/submit_new_blog_post', blog_post_info.details).catch(() => {
								dispatcher(appToolsActions.set_apptools_info({ is_loading: false }));
								return null;
							});
							if (result?.status === 201) {
								dispatcher(
									appToolsActions.set_apptools_info({
										is_loading: false,
									}),
								);
								navigate('/blog');
							}
							return;
						}}
					>
						{getI18n().t(mTrans.Save)}
					</PrimaryGreenButton>
				</Box>
			</Box>
		</BodySingleBlock>
	);
};

export default AdminCreateBlogPost;
