import { Box, Breadcrumbs } from '@mui/material';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { menuItems } from '../../models/menuItems';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/core.store';

export const MyBreadCrumb = () => {
	const location = useLocation();
	const loc_arr = location.pathname != '/' ? location.pathname.substring(1).split('/') : [];

	const breadCrumbInfo = useSelector((e: AppState) => e.breadCrumb);

	const linkClasses =
		'font-black no-underline text-xs md:text-sm text-black opacity-80 hover:underline hover:text-black hover:opacity-100 uppercase';

	const getBreadCrumbs = () => {
		return (
			<Breadcrumbs aria-label='breadcrumb'>
				<Link className={linkClasses} to={breadCrumbInfo.homepage_link ? breadCrumbInfo.homepage_link : '/'}>
					{breadCrumbInfo.homepage_name ? breadCrumbInfo.homepage_name : 'Home'}
				</Link>
				{loc_arr.map((item, i) => {
					if (i === loc_arr.length - 1) {
						const found = menuItems.find((item) => item.link === location.pathname);
						return (
							<Box key={i}>
								{breadCrumbInfo.dynamic_pathname ? (
									<span className={linkClasses.concat(' ', 'opacity-100')}>
										{breadCrumbInfo.isDynamic ? breadCrumbInfo.dynamic_pathname : found ? found.name : ''}
									</span>
								) : (
									<Link className={linkClasses.concat(' ', 'opacity-100')} to={`/${item}`}>
										{breadCrumbInfo.isDynamic ? breadCrumbInfo.dynamic_pathname : found ? found.name : ''}
									</Link>
								)}
							</Box>
						);
					} else if (i < loc_arr.length - 1) {
						let initLink = '';
						for (let k = 0; k <= i; k++) {
							initLink = initLink.concat('/', loc_arr[k]);
						}
						const found = menuItems.find((menu) => menu.link === initLink);
						return (
							<Link key={i} className={linkClasses} to={`${initLink}`}>
								{found ? (found.name ? found.name : found.link) : ''}
							</Link>
						);
					}
					return <span key={i} />;
				})}
			</Breadcrumbs>
		);
	};

	return <Box>{getBreadCrumbs()}</Box>;
};
