import React, { useEffect, useState } from 'react';
import { Box, BoxProps } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { gql_queries } from '../models/interface/gql_queries';
import { OverallInfoPositions } from '../store/reducers/app_overall_info/valueInitialization/app_overall_info_init_value';
import { appOverallInfoActions } from '../store/reducers/app_overall_info/app_overall_info.reducer';
import { PageMainInfoFavicon_EditSection } from '../components/PageMainInfo/children/PageMainInfoFavIcon_EditSection';
import { PageMainInfoLogo_EditSection } from '../components/PageMainInfo/children/PageMainInfoLogo_EditSection';
import { PageMainInfoMainMenuItemsEditSection } from '../components/PageMainInfo/children/PageMainInfoMainMenuItems_EditSection';
import { BodySingleBlock } from '../components/Layouts/BodySingleBlock';
import { BlackProgress } from '../components/utils/Progress';
import { getI18n } from 'react-i18next';
import { mTrans } from '../utils/i18n/locales/masterTranslateData';

const Label = React.forwardRef(function (props: BoxProps, ref: React.ForwardedRef<HTMLButtonElement>) {
	return (
		<Box {...props} ref={ref} className='text-4xl font-bold'>
			{props.children}
		</Box>
	);
});

export const PageMainInfoEditor = () => {
	const dispatcher = useDispatch();

	const overallInfoQuery = useQuery(gql_queries.getOverallInformationForAdminToEdit, {
		fetchPolicy: 'cache-and-network',
		variables: {
			data: {
				positions: [OverallInfoPositions.anywhere],
			},
		},
	});

	const [postLoaded, setPostLoaded] = useState(false);

	useEffect(() => {
		if (overallInfoQuery.loading) {
			setPostLoaded(false);
		} else {
			setPostLoaded(true);
		}
	}, [overallInfoQuery.loading]);

	const [dataIsLoaded, setDataIsLoaded] = useState(false);

	useEffect(() => {
		const rawData = overallInfoQuery.data?.getOverallInformationForAdminToEdit;
		if (!rawData) return;
		dispatcher(appOverallInfoActions.set_app_overall_info_state(rawData));
		setDataIsLoaded(true);
	}, [overallInfoQuery.data]);

	return (
		<BodySingleBlock>
			{postLoaded ? (
				<>
					{overallInfoQuery.data && dataIsLoaded ? (
						<>
							<Box className='grid grid-cols-1 gap-8 py-[100px]'>
								<Label>Favicon</Label>
								<PageMainInfoFavicon_EditSection />
								<Label>Logo</Label>
								<PageMainInfoLogo_EditSection />
								<Label>Menu</Label>
								<PageMainInfoMainMenuItemsEditSection />
							</Box>
						</>
					) : (
						<></>
					)}
				</>
			) : (
				<>
					<Box className='flex flex-col items-center gap-8'>
						<BlackProgress />
						<span className='font-semibold'>{getI18n().t(mTrans.Loading)}...</span>
					</Box>
				</>
			)}
		</BodySingleBlock>
	);
};
