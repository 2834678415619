import { v4 } from 'uuid';

export interface DvPageTopBanner {
	ID: string;
	image: string;
	order: string;
}

export const dvPageTopBannerSingleItem: DvPageTopBanner = {
	ID: v4(),
	image: 'defaultImage.webp',
	order: '1',
};

export const dvPageTopBannerDefaultVal: DvPageTopBanner[] = [
	{ ...dvPageTopBannerSingleItem, ID: v4() },
	{ ...dvPageTopBannerSingleItem, ID: v4() },
	{ ...dvPageTopBannerSingleItem, ID: v4() },
	{ ...dvPageTopBannerSingleItem, ID: v4() },
];
