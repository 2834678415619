import { v4 } from 'uuid';

export interface HomepageDichvuTuvanContent {
	ID: string;
	title: string;
	image: string;
	link: string;
	order: string;
}

export const homepageDichvuTuvanContentSingleItem: HomepageDichvuTuvanContent = {
	ID: v4(),
	title: 'Du học nghề',
	image: 'defaultImage.webp',
	link: 'https://www.google.com',
	order: '1',
};

export const homepageDichvuTuvanContentDefaultVal: HomepageDichvuTuvanContent[] = [
	{ ...homepageDichvuTuvanContentSingleItem, ID: v4() },
	{ ...homepageDichvuTuvanContentSingleItem, ID: v4() },
	{ ...homepageDichvuTuvanContentSingleItem, ID: v4() },
	{ ...homepageDichvuTuvanContentSingleItem, ID: v4() },
];
