import { v4 } from 'uuid';

export interface LessonPageTieudeCackhoahocTiengduc {
	ID: string;
	title: string;
	subtitle: string;
}

export const lessonPageTieudeCackhoahocTiengducDefaultValue: LessonPageTieudeCackhoahocTiengduc = {
	subtitle: 'CÁC KHÓA HỌC TIẾNG ĐỨC',
	title: 'TẠI DNG EDUCATION',
	ID: v4(),
};
