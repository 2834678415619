import { Box } from '@mui/material';
import { TableWithSelection } from '../components/utils/TableWithSelection/TableWithSelection';
import { HeadCell } from '../models/interface/tableUI.interface';
import { QueryResult, useQuery } from '@apollo/client';
import { gql_queries } from '../models/interface/gql_queries';
import React, { useEffect, useState } from 'react';
import { BlogPostEntity } from '../models/apiGqlExportTypes';
import { useNavigate } from 'react-router-dom';
import { AppCoreLanguages, appDefaultLanguage } from '../store/reducers/app_core_state.reducer';
import { BodySingleBlock } from '../components/Layouts/BodySingleBlock';
import { PrimaryGreenButton } from '../components/utils/Button';
import { BlackProgress } from '../components/utils/Progress';
import { getI18n } from 'react-i18next';
import { mTrans } from '../utils/i18n/locales/masterTranslateData';
import { useDispatch, useSelector } from 'react-redux';
import {
	OverallInfoItemNameList,
	OverallInfoPositions,
} from '../store/reducers/app_overall_info/valueInitialization/app_overall_info_init_value';
import { AppOverallInfoState } from '../store/reducers/app_overall_info/app_overall_info.reducer';
import { blogPostAction } from '../store/reducers/blog_post_info.reducer';
import { CategorySelector } from './CategoryManager';
import { api_post_data } from '../environments/api_handler';
import { AppState } from '../store/core.store';

const head_cells: HeadCell[] = [
	{
		id: 'ID',
		numeric: true,
		hidden: false,
		disablePadding: false,
		label: 'ID',
		filter: false,
	},
	{
		id: 'title',
		numeric: false,
		hidden: false,
		disablePadding: false,
		label: mTrans.Title,
		filter: true,
		clickable: true,
	},
];

interface TableProduct extends BlogPostEntity {
	title: string;
}

export const BlogPostManager = () => {
	const navigate = useNavigate();

	const blog_post_query = useQuery(gql_queries.all_post_to_manage, {
		fetchPolicy: 'cache-and-network',
	});

	const [tableData, setTableData] = useState<any[] | null>(null);
	const [postLoaded, setPostLoaded] = useState(false);

	useEffect(() => {
		if (blog_post_query.loading) {
			setPostLoaded(false);
		} else {
			setPostLoaded(true);
		}
	}, [blog_post_query.loading]);

	useEffect(() => {
		let data: any[] = blog_post_query.data?.all_blog_posts_for_management;
		if (!data) {
			return;
		}

		data = data.map((item) => {
			const copy: TableProduct = { ...item };
			if (copy.date) {
				const d = new Date(copy.date);
				if (d.toLocaleString()) {
					copy.date = d.toLocaleString();
				}
			}
			const item_title = copy.content[appDefaultLanguage.abbr].title;
			if (item_title) {
				copy.title = item_title;
			}
			return copy;
		});
		setTableData(data);

		if (blog_post_query.error) {
			console.log(blog_post_query.error);
		}
	}, [blog_post_query]);

	const refresh_table_fn = async () => {
		await blog_post_query.refetch();
		return;
	};

	const afterDeleteRowFn = async () => {
		return await blog_post_query.refetch();
	};

	const on_row_click = (value: any) => {
		navigate(`/blog/edit/${value.ID}`);
	};

	return (
		<BodySingleBlock>
			{postLoaded ? (
				<>
					<Box className='flex flex-col gap-8 w-screen max-w-full items-center py-[100px]'>
						<Box className='flex w-full'>
							<PrimaryGreenButton className='flex gap-4 items-center' onClick={() => navigate('/blog/add-new-post')}>
								<i className='fa-regular fa-plus' /> <span>{getI18n().t(mTrans['Add new post'])}</span>
							</PrimaryGreenButton>
						</Box>
						<Box className='w-full'>
							{tableData ? (
								<TableWithSelection
									table_title={getI18n().t(mTrans['Manage blog'])}
									headCells={head_cells}
									tableData={tableData}
									rowMainKey={'ID'}
									deleteRowApiUrlSuffix={'admin/delete_blog_post'}
									afterDeleteRowFn={afterDeleteRowFn}
									refresh_table_fn={refresh_table_fn}
									row_click_action={on_row_click}
								/>
							) : (
								<></>
							)}
						</Box>
						<Box className='w-full'>
							<Box className='grid grid-cols-1 gap-8'>
								<span
									className='underline cursor-pointer'
									onClick={() => {
										navigate('/blog/select-homepage-post-category');
									}}
								>
									<i className='fa-regular fa-arrow-right mr-2' /> Chọn danh mục bài viết hiển thị trên trang chủ
								</span>
								<span
									className='underline cursor-pointer'
									onClick={() => {
										navigate('/blog/select-service-page-post-category');
									}}
								>
									<i className='fa-regular fa-arrow-right mr-2' /> Chọn danh mục bài viết hiển thị trên trang Tư vấn du
									học
								</span>
							</Box>
						</Box>
					</Box>
				</>
			) : (
				<>
					<Box className='flex flex-col items-center gap-8'>
						<BlackProgress />
						<span className='font-semibold'>{getI18n().t(mTrans.Loading)}...</span>
					</Box>
				</>
			)}
		</BodySingleBlock>
	);
};
