import { TranslateObject } from '../../../../models/interface/i18n.interface';

export const viVNTranslate: TranslateObject = {
	'Homepage posts': 'Bài viết trang chủ',
	'Service page posts': 'Bài viết trang dịch vụ',
	'Edit lesson page': 'Sửa trang khóa học',
	'Edit Footer': 'Sửa chân trang',
	'Edit service page': 'Sửa trang dịch vụ',
	Overall: 'Tổng quan',
	'You are editing post #': 'Bạn đang chỉnh sửa bài viết #',
	'Select category': 'Chọn danh mục',
	'Selected post(s)': 'Bài viết đã chọn',
	'Move posts to other category': 'Đổi danh mục bài viết',
	Excerpt: 'Nội dung rút gọn',
	'Start writing': 'Nhập văn bản',
	'Post content is now allowed to be empty': 'Nội dung bài viết không được để trống',
	'You must upload a thumbnail for this post': 'Bạn phải đăng 1 ảnh bìa cho bài viết này',
	'Are you sure you want to continue?': 'Bạn có chắc muốn thực hiện thao tác này',
	Title: 'Tiêu đề',
	'Delete block': 'Xóa block',
	'Add block': 'Thêm block',
	'Updated successfully': 'Cập nhật thành công',
	'Post saved': 'Đã lưu bài viết',
	Link: 'Liên kết',
	Priority: 'Ưu tiên',
	'Menu name': 'Tên menu',
	Home: 'Trang chủ',
	'Key words': 'Từ khóa',
	'Search term': 'Hạng mục',
	'Manage categories': 'Quản lý danh mục',
	'Choose one': 'Lựa chọn',
	From: 'Từ',
	Name: 'Tên',
	To: 'Đến',
	Close: 'Đóng',
	'Change image': 'Đổi ảnh',
	Save: 'Lưu',
	Loading: 'Đang tải',
	Confirm: 'Xác nhận',
	'Rows per page': 'Hiển thị mỗi trang',
	Narrow: 'Thu hẹp',
	Order: 'Thứ tự',
	'Login with': 'Đăng nhập bằng',
	'Sign in': 'Đăng nhập',
	'Sign out': 'Đăng xuất',
	'Admin list': 'Danh sách Admin',
	'Delete all trash media': 'Xóa hết tệp rác',
	'New admin email': 'Email admin mới',
	'trash media': 'Tệp rác',
	'Add image': 'Thêm ảnh',
	'Add new post': 'Thêm bài viết',
	'Auto url': 'Url tự động',
	'Edit post': 'Sửa bài viết',
	'Manage blog': 'Quản lý blog',
	Content: 'Nội dung',
	Thumbnail: 'Ảnh bìa',
	posts: 'bài viết',
	'Transfer posts to category': '',
	'Transfer posts': 'Chuyển bài viết',
	'Add new category': 'Thêm danh mục',
	'Category List': 'Danh sách danh mục',
	'Category Name': 'Tên danh mục',
	'Edit landing page': 'Sửa trang chủ',
	'Overall information': 'Sửa thông tin tổng',
	'Transfer posts between categories': 'Chuyển bài viết giữa các danh mục',
	Category: 'Danh mục',
	Manage: 'Quản lý',
};
