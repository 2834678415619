import * as React from 'react';
import { createBrowserRouter, Outlet, RouteObject } from 'react-router-dom';
import App from '../App';
import { AdminHomePage } from '../views/AdminHomePage';
import AdminCreateBlogPost from '../views/AdminCreateBlogPost';
import { LoginPage } from '../views/LoginPage';
import { BlogPostManager } from '../views/ManageBlogPost';
import EditBlogPost from '../views/EditBlogPostPage';
import { CategoryManager } from '../views/CategoryManager';
import { PageMainInfoEditor } from '../views/PageMainInfoEditor';
import { AppCoreLayout } from '../components/Layouts/AppCoreLayout';
import { LandingPageEditor } from '../views/LandingPageEditor';
import { ServicePageEditor } from '../views/servicePageEditor';
import { FooterComponentEditor } from '../views/FooterComponentEditor';
import { LessonPageEditor } from '../views/LessonPageEditor';
import { SelectHomepagePostCategory } from '../views/SelectHomepagePostCategory';
import { SelectServicePagePostCategory } from '../views/SelectServicePagePostCategory';

export const appClientRouter: RouteObject[] = [
	{
		index: true,
		element: <AdminHomePage />,
	},
	{
		path: 'blog',
		element: <Outlet />,
		children: [
			{
				index: true,
				element: <BlogPostManager />,
			},
			{
				path: 'add-new-post',
				element: <AdminCreateBlogPost />,
			},
			{
				path: 'select-homepage-post-category',
				element: <SelectHomepagePostCategory />,
			},
			{
				path: 'select-service-page-post-category',
				element: <SelectServicePagePostCategory />,
			},
			{
				path: 'edit',
				element: <Outlet />,
				children: [
					{
						path: ':post_ID',
						element: <EditBlogPost />,
					},
				],
			},
		],
	},
	{
		path: 'category',
		element: <Outlet />,
		children: [
			{
				index: true,
				element: <CategoryManager />,
			},
		],
	},
	{
		path: 'layout',
		element: <Outlet />,
		children: [
			{
				path: 'landing-page',
				element: <LandingPageEditor />,
			},
			{
				path: 'service-page',
				element: <ServicePageEditor />,
			},
			{
				path: 'main-footer',
				element: <FooterComponentEditor />,
			},
			{
				path: 'service-page',
				element: <ServicePageEditor />,
			},
			{
				path: 'lesson-page',
				element: <LessonPageEditor />,
			},
			{
				path: 'page-maininfo',
				element: <PageMainInfoEditor />,
			},
		],
	},
	{
		path: 'sign-in',
		element: <LoginPage />,
	},
];

export const indexRouter = createBrowserRouter([
	{
		path: '/',
		element: <App />,
		children: [
			{
				path: '/',
				element: <AppCoreLayout />,
				children: appClientRouter,
			},
		],
	},
]);
