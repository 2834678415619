import React from 'react';
import { Box, BoxProps } from '@mui/material';

export const OrangeAlert = React.forwardRef(function (props: BoxProps, ref: React.ForwardedRef<HTMLButtonElement>) {
	return (
		<Box
			{...props}
			ref={ref}
			className={`alert border-2 border-solid border-orange-300 text-orange-200 w-full font-bold ${props.className}`}
		>
			<i className='fa-regular fa-bell mr-4' /> {props.children}
		</Box>
	);
});

export const LimeAlert = React.forwardRef(function (props: BoxProps, ref: React.ForwardedRef<HTMLButtonElement>) {
	return (
		<Box
			{...props}
			ref={ref}
			className={`alert border-2 border-solid border-lime-700 text-lime-700 w-full font-bold ${props.className}`}
		>
			<i className='fa-regular fa-bell mr-4' /> {props.children}
		</Box>
	);
});
