import React, { useEffect, useRef } from 'react';
import { Snackbar } from '../utils/Snackbar';
import { Dialog } from '../utils/Dialog';
import { Box, BoxProps } from '@mui/material';
import { LandingPageHeader } from '../LandingPage/LandingPageHeader';
import { Outlet, useLocation } from 'react-router-dom';
import { LandingPageLeftSidebar } from '../LandingPage/leftSidebar/landingPageLeftSidebar';
import { RequestUserInfo } from '../Authentication/request_userinfo';
import { AppState } from '../../store/core.store';
import { useDispatch, useSelector } from 'react-redux';
import { all_screen_size } from '../../store/reducers/screensize.reducer';
import { appToolsActions } from '../../store/reducers/appTools.reducer';
import { AnimatedSlideInReveal } from '../utils/AnimatedSlideInReveal';

export const AppCoreLayout = React.forwardRef(function (props: BoxProps, ref: React.ForwardedRef<HTMLElement>) {
	const location = useLocation();
	const dispatcher = useDispatch();
	const childRef = useRef<any>();
	useEffect(() => {
		document.querySelector('.has-animations')?.classList.add('is-loaded');
		if (childRef.current && childRef.current.init) childRef.current.init();
	}, [location]);

	const leftSideBarIsOpen = useSelector((e: AppState) => e.appTools.leftSideBarIsOpen);

	const screenSize = useSelector((e: AppState) => e.screenSize.value);

	const isXXl = () => {
		switch (screenSize) {
			case all_screen_size.xs:
				return false;
			case all_screen_size.sm:
				return false;
			case all_screen_size.md:
				return false;
			case all_screen_size.lg:
				return false;
			case all_screen_size.xl:
				return false;
			case all_screen_size.xxl:
				return true;
		}
	};

	return (
		<>
			<Snackbar />
			<Dialog />
			<RequestUserInfo />
			<Box
				ref={ref}
				{...props}
				className='w-screen flex has-animations'
				style={{
					justifyContent: leftSideBarIsOpen && !isXXl() ? 'start' : 'center',
				}}
			>
				<Box>
					<LandingPageLeftSidebar />
				</Box>
				<Box className='relative bg-white flex-grow xl:flex-grow-0 min-h-screen flex flex-col pb-[200px]'>
					<LandingPageHeader />
					<Box
						className='absolute 2xl:!hidden top-0 left-0 w-full h-full bg-black/40 z-30'
						hidden={!leftSideBarIsOpen}
						onClick={() => {
							dispatcher(appToolsActions.set_apptools_info({ leftSideBarIsOpen: false }));
						}}
					/>
					<AnimatedSlideInReveal>
						<Outlet />
					</AnimatedSlideInReveal>
				</Box>
			</Box>
		</>
	);
});
