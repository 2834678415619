import { Box } from '@mui/material';
import { Fragment } from 'react';
import { AppCoreLanguages } from '../../../store/reducers/app_core_state.reducer';
import {
	AddBlockItemBtn,
	DeleteBlockItemBtn,
	OverallInfo__Array__ItemTextEditor,
	OverallInfo__ItemSaveButton,
} from '../../OverallInformation/OverallInfoItemTextEditor';
import {
	OverallInfoItemNameList,
	OverallInfoPositions,
} from '../../../store/reducers/app_overall_info/valueInitialization/app_overall_info_init_value';
import { useSelector } from 'react-redux';
import { AppState } from '../../../store/core.store';
import { v4 } from 'uuid';
import { InfoBox } from '../../../views/AdminHomePage';
import { mainNavMenuContentItem } from '../../../store/reducers/app_overall_info/valueInitialization/mainNavMenu.defaultValue';
import { getI18n } from 'react-i18next';
import { mTrans } from '../../../utils/i18n/locales/masterTranslateData';

const Content_MainEditor = (props: { lang: AppCoreLanguages }) => {
	const itemName = OverallInfoItemNameList.mainNavMenuItems;
	const defaultItem = mainNavMenuContentItem;

	const allBlocks = useSelector((e: AppState) => e.app_overall_info[props.lang][itemName]);

	return (
		<>
			{allBlocks && Array.isArray(allBlocks) ? (
				<>
					{allBlocks.map((block, i) => (
						<Fragment key={i}>
							<InfoBox>
								<OverallInfo__Array__ItemTextEditor
									textFieldProps={{
										label: getI18n().t(mTrans['Menu name']),
									}}
									itemID={block.ID}
									lang={props.lang}
									valueKey='title'
									itemName={itemName}
								/>
								<OverallInfo__Array__ItemTextEditor
									textFieldProps={{
										label: getI18n().t(mTrans.Link),
									}}
									itemID={block.ID}
									lang={props.lang}
									valueKey='link'
									itemName={itemName}
								/>
								<OverallInfo__Array__ItemTextEditor
									textFieldProps={{
										label: getI18n().t(mTrans.Priority),
									}}
									itemID={block.ID}
									lang={props.lang}
									valueKey='order'
									itemName={itemName}
								/>
								<Box className='text-end'>
									<DeleteBlockItemBtn lang={props.lang} itemName={itemName} itemID={block.ID} />
								</Box>
							</InfoBox>
						</Fragment>
					))}
					<InfoBox>
						<Box>
							<AddBlockItemBtn
								lang={props.lang}
								itemName={itemName}
								newValue={{
									...defaultItem,
									ID: v4(),
								}}
							/>
						</Box>
					</InfoBox>
				</>
			) : (
				<></>
			)}
		</>
	);
};

const ContentSaveButton = (props: { lang: AppCoreLanguages }) => {
	const itemName = OverallInfoItemNameList.mainNavMenuItems;
	const dataPosition = OverallInfoPositions.anywhere;

	return <OverallInfo__ItemSaveButton lang={props.lang} itemName={itemName} dataPosition={dataPosition} imgArr={[]} />;
};

export const PageMainInfoMainMenuItemsEditSection = () => {
	return (
		<>
			{Object.values(AppCoreLanguages).map((language, i) => (
				<Fragment key={i}>
					<Box className='grid grid-cols-1 gap-8'>
						<Box className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8'>
							<Content_MainEditor lang={language} />
						</Box>
						<Box className='text-end'>
							<ContentSaveButton lang={language} />
						</Box>
					</Box>
				</Fragment>
			))}
		</>
	);
};
