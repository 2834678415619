import { Box } from '@mui/material';
import { Fragment } from 'react';
import { AppCoreLanguages } from '../../../store/reducers/app_core_state.reducer';
import {
	JoditEditorLabel,
	OverallInfo__ItemSaveButton,
	OverallInfo__NonArray__ItemImageUpload,
	OverallInfo__NonArray__ItemJoditEditor,
	OverallInfo__NonArray__ItemTextEditor,
} from '../../OverallInformation/OverallInfoItemTextEditor';
import {
	OverallInfoItemNameList,
	OverallInfoPositions,
} from '../../../store/reducers/app_overall_info/valueInitialization/app_overall_info_init_value';
import { useSelector } from 'react-redux';
import { AppState } from '../../../store/core.store';

const SaveButton = (props: { lang: AppCoreLanguages }) => {
	const itemName = OverallInfoItemNameList.lotrinhDuhocngheDuc;
	const dataPosition = OverallInfoPositions.anywhere;

	const blockInfo = useSelector((e: AppState) => e.app_overall_info[props.lang][itemName]);

	return (
		<OverallInfo__ItemSaveButton
			lang={props.lang}
			itemName={itemName}
			dataPosition={dataPosition}
			imgArr={[blockInfo.firstImg, blockInfo.secondImg].concat(blockInfo.content.images)}
		/>
	);
};

export const HomepageLotrinhDuhocNgheDuc_EditSection = () => {
	const itemName = OverallInfoItemNameList.lotrinhDuhocngheDuc;
	return (
		<>
			{Object.values(AppCoreLanguages).map((language, i) => (
				<Fragment key={i}>
					<Box className='grid grid-cols-1 gap-8'>
						<Box className='flex justify-center'>
							<Box className='w-[500px]'>
								<OverallInfo__NonArray__ItemImageUpload lang={language} itemName={itemName} valueKey='firstImg' />
							</Box>
						</Box>
						<Box className='flex justify-center'>
							<Box className='w-[500px]'>
								<OverallInfo__NonArray__ItemImageUpload lang={language} itemName={itemName} valueKey='secondImg' />
							</Box>
						</Box>
						<OverallInfo__NonArray__ItemTextEditor
							textFieldProps={{
								label: 'Tiêu đề phụ',
							}}
							itemName={itemName}
							valueKey='subtitle'
							lang={language}
						/>
						<OverallInfo__NonArray__ItemTextEditor
							textFieldProps={{
								label: 'Tiêu đề chính',
							}}
							itemName={itemName}
							valueKey='title'
							lang={language}
						/>
						<JoditEditorLabel>Nội dung</JoditEditorLabel>
						<OverallInfo__NonArray__ItemJoditEditor itemName={itemName} valueKey='content' lang={language} />
						<OverallInfo__NonArray__ItemTextEditor
							textFieldProps={{
								label: 'Button text',
							}}
							itemName={itemName}
							valueKey='btnText'
							lang={language}
						/>
						<OverallInfo__NonArray__ItemTextEditor
							textFieldProps={{
								label: 'Button url',
							}}
							itemName={itemName}
							valueKey='btnUrl'
							lang={language}
						/>
						<Box className='text-end'>
							<SaveButton lang={language} />
						</Box>
					</Box>
				</Fragment>
			))}
		</>
	);
};
