import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { Table_UI_Slice } from './reducers/table_UI.reducer';
import {
	appCoreStatePersistConfig,
	appCoreStatePersistKey,
	AppCoreStateSlice,
} from './reducers/app_core_state.reducer';
import { userInfoSlice } from './reducers/userinfo.reducer';
import { screenSizeSlice } from './reducers/screensize.reducer';
import createSagaMiddleware from 'redux-saga';
import { allUsersReducer } from './saga/all_users';
import { BlogPostSlice } from './reducers/blog_post_info.reducer';
import { BlogPost_CategoryInfo_Slice } from './reducers/category_info.reducer';
import { breadCrumbSlice } from './reducers/breadcrumb.reducer';
import { appToolsSlice } from './reducers/appTools.reducer';
import { App_OverallInfo_Slice } from './reducers/app_overall_info/app_overall_info.reducer';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export const sagaMiddleware = createSagaMiddleware();

export const persistConfig = {
	key: 'root',
	storage: storage,
	whitelist: [appCoreStatePersistKey],
	blacklist: [appCoreStatePersistKey],
};

const rootReducers = combineReducers({
	table_UI: Table_UI_Slice.reducer,
	app_core_state: persistReducer(appCoreStatePersistConfig, AppCoreStateSlice.reducer),
	app_overall_info: App_OverallInfo_Slice.reducer,
	screenSize: screenSizeSlice.reducer,
	breadCrumb: breadCrumbSlice.reducer,
	all_users: allUsersReducer,
	user_info: userInfoSlice.reducer,
	blog_post: BlogPostSlice.reducer,
	blogpost_category_info: BlogPost_CategoryInfo_Slice.reducer,
	appTools: appToolsSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducers);

export const reduxStore = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(sagaMiddleware),
});

const makeStore = () => reduxStore;

export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore['getState']>;

export const rootPersistor = persistStore(reduxStore);
