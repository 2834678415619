import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { appToolsActions } from '../../store/reducers/appTools.reducer';
import { BodySingleBlock } from '../Layouts/BodySingleBlock';
import { PrimaryBlackTextButton } from '../utils/Button';
import { AppState } from '../../store/core.store';
import { MyBreadCrumb } from '../utils/Breadcrumb';
import { useTranslation } from 'react-i18next';
import {
	appCoreStateActions,
	DashboardLanguages,
	DashboardLanguagesMap,
} from '../../store/reducers/app_core_state.reducer';

export const LandingPageHeader: React.FC<any> = () => {
	const dispatcher = useDispatch();
	const leftSidebarIsOpen = useSelector((e: AppState) => e.appTools.leftSideBarIsOpen);
	const headerPosition = useSelector((e: AppState) => e.appTools.headerPosition);
	const headerBg = useSelector((e: AppState) => e.appTools.headerBg);
	const headerTextColor = useSelector((e: AppState) => e.appTools.headerTextColor);
	const currentLanguage = useSelector((e: AppState) => e.app_core_state.dashboardLanguage);
	const [windowScrollY, setWindowScrollY] = useState(false);
	const { i18n } = useTranslation();

	useEffect(() => {
		window.addEventListener('scroll', () => {
			if (window.scrollY > 100) {
				setWindowScrollY(true);
			} else {
				setWindowScrollY(false);
			}
		});
	}, []);

	const getNewLang = (lang: DashboardLanguages): DashboardLanguages => {
		switch (lang) {
			case DashboardLanguages.enUS:
				return DashboardLanguages.viVN;
			case DashboardLanguages.viVN:
				return DashboardLanguages.enUS;
		}
	};

	useEffect(() => {
		if (windowScrollY) {
			dispatcher(appToolsActions.set_apptools_info({ headerBg: '#fff' }));
		} else {
			dispatcher(appToolsActions.set_apptools_info({ headerBg: 'transparent' }));
		}
	}, [windowScrollY]);

	return (
		<>
			<BodySingleBlock
				className='w-full top-0 left-0 z-20 !py-0'
				style={{
					position: headerPosition,
				}}
			>
				<Box
					className='flex justify-between items-center py-4'
					style={{
						background: headerBg,
						color: headerTextColor,
					}}
				>
					<Box className='flex gap-4 items-center'>
						<Box className='2xl:hidden' hidden={leftSidebarIsOpen}>
							<img src='/img/dashboard-logo-3bjk23.png' className='w-12 aspect-1' />
						</Box>
						<Box className='hidden xl:block'>
							<MyBreadCrumb />
						</Box>
					</Box>
					<Box>
						<Box className='flex gap-4'>
							<PrimaryBlackTextButton
								onClick={async () => {
									const newLang = getNewLang(currentLanguage.locale);
									dispatcher(
										appCoreStateActions.set_app_core_state({
											dashboardLanguage: DashboardLanguagesMap[newLang],
										}),
									);
									await i18n.changeLanguage(newLang);
									window.location.reload();
								}}
							>
								<span className='font-bold uppercase'>{i18n.language.substring(0, 2)}</span>
							</PrimaryBlackTextButton>
							<PrimaryBlackTextButton
								onClick={() => {
									dispatcher(
										appToolsActions.set_apptools_info({
											leftSideBarIsOpen: !leftSidebarIsOpen,
										}),
									);
								}}
							>
								<i className='fa-regular fa-bars' />
							</PrimaryBlackTextButton>
						</Box>
					</Box>
				</Box>
			</BodySingleBlock>
		</>
	);
};
