export interface UserInfo {
	ID: number;
	email: string;
	role: UserRoles;
}

export enum UserRoles {
	guest = 'guest',
	subscriber = 'subscriber',
	admin = 'admin',
}
