import { v4 } from 'uuid';

export interface FooterTieudeDichvu {
	ID: string;
	title: string;
}
export const footerTieudeDichvuDefaulValue: FooterTieudeDichvu = {
	title: 'Dịch vụ',
	ID: v4(),
};
