import React, { useEffect } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch, useSelector } from 'react-redux';
import { UserInfo, UserRoles } from '../../models/interface/user_info.interface';
import { api_post_data } from '../../environments/api_handler';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AppState, reduxStore } from '../../store/core.store';
import { appToolsActions } from '../../store/reducers/appTools.reducer';

export const loginSuccess = async (res: any): Promise<UserInfo | null> => {
	if (res.data && res.data.message) {
		reduxStore.dispatch(
			appToolsActions.set_apptools_info({
				snackbar_content: res.data.message,
				dialog_isOpen: false,
			}),
		);
	}
	if (res.data && res.data.payload) {
		return res.data.payload;
	} else {
		reduxStore.dispatch(
			appToolsActions.set_apptools_info({
				snackbar_content: 'No access token received',
			}),
		);
		return null;
	}
};

export const GgLoginBtn = () => {
	const dispatcher = useDispatch();
	const navigate = useNavigate();

	const gg_login = useGoogleLogin({
		onSuccess: (tokenResponse) => {
			responseGoogle(tokenResponse);
		},
		onError: () => {
			dispatcher(
				appToolsActions.set_apptools_info({
					snackbar_content: 'Login with Google failed',
				}),
			);
		},
	});

	const userInfo = useSelector((e: AppState) => e.user_info);

	const responseGoogle = (response: any) => {
		if (response && response.access_token) {
			const loginInfo = { access_token: response.access_token };

			api_post_data('auth/google_login', loginInfo)
				.then(async (res) => {
					const success = await loginSuccess(res);
					if (success) {
						navigate('/');
						window.location.reload();
					}
					return;
				})
				.catch((error) => console.log(error));
		} else {
			dispatcher(
				appToolsActions.set_apptools_info({
					snackbar_content: 'Can not get GG access token, sign in by GG failed.',
				}),
			);
		}
	};

	useEffect(() => {
		if (userInfo.data.role === UserRoles.admin) {
			navigate('/');
		}
	}, [userInfo]);

	return (
		<>
			{userInfo.tried_signing_in ? (
				<>
					<Box
						className='border-solid border-lime-300 text-lime-300 border-4 cursor-pointer rounded-[30px] w-[200px] h-[200px] flex justify-center items-center hover:animate-hue-rotate-360'
						onClick={() => gg_login()}
					>
						<i className='fa-brands fa-google text-[64px]' />
					</Box>
				</>
			) : (
				<></>
			)}
		</>
	);
};
