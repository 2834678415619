import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/core.store';
import { getI18n } from 'react-i18next';
import { mTrans } from '../../utils/i18n/locales/masterTranslateData';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { gql_queries } from '../../models/interface/gql_queries';
import { PrimaryBlackButton } from '../utils/Button';
import { api_post_data } from '../../environments/api_handler';

export function TransferPostToCategoryPanel() {
	const selectedPosts = useSelector((e: AppState) => e.blog_post.selectedPosts);
	const [all_categories, set_all_categories] = useState<any[]>([]);
	const [current_cat, set_current_cat] = useState<number>(0);
	const category_query = useQuery(gql_queries.all_blog_post_categories, {
		fetchPolicy: 'cache-and-network',
	});
	useEffect(() => {
		const raw_data = category_query.data?.all_blog_post_categories;

		if (raw_data) set_all_categories(raw_data);
	}, [category_query.data]);

	return (
		<Box className='w-[500px] max-w-[90vw] p-4'>
			<Box className='flex flex-col gap-4'>
				<span className='font-bold text-2xl'>{getI18n().t(mTrans['Move posts to other category'])}</span>
				<span>
					{getI18n().t(mTrans['Selected post(s)'])}: {selectedPosts.join(', ')}
				</span>
				<Box>
					<FormControl fullWidth>
						<InputLabel>{getI18n().t(mTrans['Select category'])}</InputLabel>
						<Select
							value={current_cat ? current_cat : 0}
							label={getI18n().t(mTrans['Select category'])}
							onChange={(e) => {
								set_current_cat(parseInt(e.target.value.toString()) ? parseInt(e.target.value.toString()) : 0);
							}}
						>
							<MenuItem value={0}>{getI18n().t(mTrans['Choose one'])}...</MenuItem>
							{all_categories.map((item: any, i: number) => (
								<MenuItem key={i} value={item.ID}>
									{item.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Box>
				<Box className='flex justify-end'>
					<PrimaryBlackButton
						onClick={async () => {
							await api_post_data('admin/transfer_blogposts_to_category', {
								post_IDs: selectedPosts,
								category_ID: current_cat,
							});
						}}
					>
						{getI18n().t(mTrans.Confirm)}
					</PrimaryBlackButton>
				</Box>
			</Box>
		</Box>
	);
}
