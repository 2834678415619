import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReactNode } from 'react';

export interface HeaderStyleState {
	headerPosition: 'sticky' | 'absolute';
	headerBg: string;
	headerTextColor: string;
}

export type AppToolsState = {
	leftSideBarIsOpen: boolean;
	is_loading: boolean;
	app_has_error: boolean;
	app_error_message: string[];
	snackbar_content: string;
	dialog_isOpen: boolean;
	dialogContent: ReactNode | null;
} & HeaderStyleState;

const appTools_initial_state: AppToolsState = {
	headerBg: '',
	headerPosition: 'sticky',
	headerTextColor: '',
	snackbar_content: '',
	app_error_message: [],
	app_has_error: false,
	is_loading: false,
	leftSideBarIsOpen: false,
	dialog_isOpen: false,
	dialogContent: null,
};

export const appToolsSlice = createSlice({
	name: 'appTools',
	initialState: appTools_initial_state,
	reducers: {
		set_apptools_info: (state, action: PayloadAction<Partial<AppToolsState>>) => {
			Object.assign(state, action.payload);
		},
	},
});

// Action creators are generated for each case reducer function
export const appToolsActions = appToolsSlice.actions;
