import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { gql_queries } from '../../models/interface/gql_queries';
import { userInfoActions } from '../../store/reducers/userinfo.reducer';
import { useLocation, useNavigate } from 'react-router-dom';

export const RequestUserInfo: React.FC = () => {
	const dispatcher = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const user_info_query = useQuery(gql_queries.user_info);

	useEffect(() => {
		const raw_data = user_info_query.data?.user_info;
		if (raw_data) {
			dispatcher(userInfoActions.setUserInfo(raw_data));
		}
		if (user_info_query.error) {
			dispatcher(userInfoActions.user_tried_signing_in(true));
			navigate('/sign-in');
		}
	}, [user_info_query, location.pathname]);

	return <></>;
};
