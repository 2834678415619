import { v4 } from 'uuid';
import { OverallInformationJoditContent } from './overallInfoJoditContent.defaultValue';

export interface LangngheChiaseHocsinhDNGItemContent {
	ID: string;
	authorInfo: OverallInformationJoditContent;
	message: OverallInformationJoditContent;
	image: string;
	order: string;
}

const defaultMsg =
	'Mình đã là du học sinh Đức được 2 năm rồi. Đến thời điểm này, mình vẫn cảm thấy thật may mắn khi lựa chọn DNG Education hỗ trợ tư vấn và làm hồ sơ thủ tục du học Đức giúp mình. Các anh chị tư vấn rất thân thiện và nhiệt tình hỗ trợ, giúp mình xuất sắc vượt qua vòng phỏng vấn cũng như xin visa đi Đức. Cảm ơn DNG Education rất nhiều ạ, các bạn đang có mong muốn đi du học Đức hoặc Mỹ, Úc như mình cứ yên tâm lựa chọn DNG Education nhé!';

export const langngheChiaseHocsinhDNGDefaultItem: LangngheChiaseHocsinhDNGItemContent = {
	ID: v4(),
	authorInfo: {
		content: 'Xuan mai',
		images: [],
	},
	image: 'defaultImage.webp',
	message: {
		content: defaultMsg,
		images: [],
	},
	order: '1',
};

export const langngheChiaseHocsinhDNGDefaultVal: LangngheChiaseHocsinhDNGItemContent[] = [
	{ ...langngheChiaseHocsinhDNGDefaultItem, ID: v4() },
	{ ...langngheChiaseHocsinhDNGDefaultItem, ID: v4() },
	{ ...langngheChiaseHocsinhDNGDefaultItem, ID: v4() },
];
