import { v4 } from 'uuid';

export interface FooterSocialMediaItem {
	ID: string;
	variant: string;
	link: string;
	order: string;
}

export const footerSocialMediaItem: FooterSocialMediaItem = {
	ID: v4(),
	variant: 'youtube',
	link: 'https://www.google.com',
	order: '1',
};

export const footerSocialMediaItemsDefaultValue: FooterSocialMediaItem[] = [
	{ ...footerSocialMediaItem, ID: v4() },
	{ ...footerSocialMediaItem, ID: v4() },
	{ ...footerSocialMediaItem, ID: v4() },
	{ ...footerSocialMediaItem, ID: v4() },
];
