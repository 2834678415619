import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { my_custom_components } from '../../models/custom_mui';
import { ThemeOptions } from '@mui/material';
import colors from 'tailwindcss/colors';
import storage from 'redux-persist/lib/storage';

export enum DashboardLanguages {
	viVN = 'vi-VN',
	enUS = 'en-US',
}

export enum AppCoreLanguages {
	// deDE = 'de-DE',
	viVN = 'vi-VN',
	// enUS = 'en-US',
}

export interface AppDefaultLanguage {
	abbr: 'viVN';
	locale: AppCoreLanguages;
}

export const appDefaultLanguage: AppDefaultLanguage = {
	abbr: 'viVN',
	locale: AppCoreLanguages.viVN,
};

export interface DashboardSingleLanguageSchema {
	cca2: string;
	cca3: string;
	locale: DashboardLanguages;
}

export interface SingleLanguageSchema {
	cca2: string;
	cca3: string;
	locale: AppCoreLanguages;
}

export const DashboardLanguagesMap: {
	[key in DashboardLanguages]: DashboardSingleLanguageSchema;
} = {
	// [AppCoreLanguages.deDE]: {
	//   cca2: 'DE',
	//   cca3: 'DEU',
	//   locale: AppCoreLanguages.deDE,
	// },
	[DashboardLanguages.viVN]: {
		cca2: 'VN',
		cca3: 'VNM',
		locale: DashboardLanguages.viVN,
	},
	[DashboardLanguages.enUS]: {
		cca2: 'US',
		cca3: 'USA',
		locale: DashboardLanguages.enUS,
	},
};

export const LanguagesMap: {
	[key in AppCoreLanguages]: SingleLanguageSchema;
} = {
	// [AppCoreLanguages.deDE]: {
	//   cca2: 'DE',
	//   cca3: 'DEU',
	//   locale: AppCoreLanguages.deDE,
	// },
	[AppCoreLanguages.viVN]: {
		cca2: 'VN',
		cca3: 'VNM',
		locale: AppCoreLanguages.viVN,
	},
	// [AppCoreLanguages.enUS]: {
	// 	cca2: 'US',
	// 	cca3: 'USA',
	// 	locale: AppCoreLanguages.enUS,
	// },
};

export interface AppCoreState {
	language: SingleLanguageSchema;
	dashboardLanguage: DashboardSingleLanguageSchema;
	theme_options_admin_dashboard: any;
	theme_options_client_FE: any;
	theme_options_client_FE_dark: any;
}

export const initial_purity_dashboard_main_light_theme_options: any = {
	palette: {
		mode: 'light',
		primary: {
			main: '#000000',
		},
		secondary: {
			main: '#f30b0d',
		},
		background: {
			paper: '#ffffff',
			default: '#ffffff',
		},
		success: {
			main: '#0de219',
			contrastText: '#ffffff',
			dark: '#06f115',
		},
		text: {
			primary: 'rgba(0,46,109,0.56)',
			secondary: 'rgba(52,74,154,0.49)',
		},
		error: {
			main: '#fb8f89',
			contrastText: '#ffffff',
			dark: '#ff2d2d',
		},
		warning: {
			main: '#ffba54',
			contrastText: 'rgba(255,255,255,0.87)',
		},
	},
	components: my_custom_components,
	typography: {
		fontFamily: 'inherit',
	},
};

const initial_theme_options_client_FE = {
	palette: {
		mode: 'light',
		primary: {
			main: colors.black,
		},
		secondary: {
			main: colors.cyan['500'],
		},
		background: {
			paper: '#ffffff',
			default: '#ffffff',
		},
	},
	components: my_custom_components,
	typography: {
		fontFamily: 'inherit',
	},
};

const initial_theme_options_client_FE_dark: ThemeOptions = {
	palette: {
		mode: 'dark',
		primary: {
			main: '#f3cfff',
			contrastText: 'rgba(48,7,65,0.87)',
		},
		secondary: {
			main: '#6df8ff',
			contrastText: 'rgba(6,54,65,0.87)',
			dark: '#a7fbff',
		},
		background: {
			default: '#1b062d',
			paper: '#4c4cc082',
		},
		error: {
			main: '#ff9157',
			contrastText: 'rgba(92,34,7,0.87)',
		},
	},
	components: my_custom_components,
	typography: {
		fontFamily: 'inherit',
	},
};

const AppCoreStateInitialValue: AppCoreState = {
	language: LanguagesMap[AppCoreLanguages.viVN],
	dashboardLanguage: DashboardLanguagesMap[DashboardLanguages.viVN],
	theme_options_admin_dashboard: initial_purity_dashboard_main_light_theme_options,
	theme_options_client_FE: initial_theme_options_client_FE,
	theme_options_client_FE_dark: initial_theme_options_client_FE_dark,
};

export const appCoreStatePersistKey = 'app_core_state';
export const appCoreStatePersistConfig = {
	key: appCoreStatePersistKey,
	storage: storage,
	blacklist: ['theme_options_client_FE', 'language'],
};

export const AppCoreStateSlice = createSlice({
	name: 'app_core_state',
	initialState: AppCoreStateInitialValue,
	reducers: {
		set_app_core_state: (state, action: PayloadAction<Partial<AppCoreState>>) => {
			Object.assign(state, action.payload);
		},
		reset_app_core_state: (state) => {
			Object.assign(state, AppCoreStateInitialValue);
		},
	},
});

// Action creators are generated for each case reducer function
export const appCoreStateActions = AppCoreStateSlice.actions;
