import { v4 } from 'uuid';

export interface DvPageDieukienDedangContent {
	ID: string;
	title: string;
	image: string;
	order: string;
}

export const dvPageDieukienDedangSingleItem: DvPageDieukienDedangContent = {
	ID: v4(),
	title: 'Độ tuổi từ 18 đến 35',
	image: 'defaultImage.webp',
	order: '1',
};

export const dvPageDieukienDedangItemsDefaultVal: DvPageDieukienDedangContent[] = [
	{ ...dvPageDieukienDedangSingleItem, ID: v4() },
	{ ...dvPageDieukienDedangSingleItem, ID: v4() },
	{ ...dvPageDieukienDedangSingleItem, ID: v4() },
	{ ...dvPageDieukienDedangSingleItem, ID: v4() },
];
