import { v4 } from 'uuid';

export interface DvPageTieudeTintucNoibat {
	ID: string;
	title: string;
	subtitle: string;
}
export const dvPageTieudeTintucNoibatDefaultValue: DvPageTieudeTintucNoibat = {
	subtitle: 'Theo dõi các bài viết để cập nhật các thông tin du học Đức và phương pháp học ngoại ngữ mới nhất.',
	title: 'TIN TỨC NỔI BẬT',
	ID: v4(),
};
