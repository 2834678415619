import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import localesResourse from './locales/index';

i18next
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources: localesResourse,

		// The default language
		fallbackLng: 'en-US',
		lng: 'en-US',
		ns: ['translation'],
		load: 'currentOnly',
		defaultNS: 'translation',

		// Tt shows the supported languages
		supportedLngs: ['en-US', 'vi-VN'],
		// debug: process.env.NODE_ENV !== 'production',
	});
