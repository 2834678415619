import React, { useEffect, useState } from 'react';
import { Box, BoxProps } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { gql_queries } from '../models/interface/gql_queries';
import { OverallInfoPositions } from '../store/reducers/app_overall_info/valueInitialization/app_overall_info_init_value';
import { appOverallInfoActions } from '../store/reducers/app_overall_info/app_overall_info.reducer';
import { DvPageBannerItemsEditSection } from '../components/ServicePage/DvPageBanner_EditSection';
import {
	DvPageLoiichVuottroiItemsEditSection,
	DvPageTieudeLoiichVuottroi_EditSection,
} from '../components/ServicePage/DvPageLoiichVuottroi_EditSection';
import {
	DvPageDieukienDedangItemsEditSection,
	DvPageTieudeDieukienDedang_EditSection,
} from '../components/ServicePage/DvPageDieukienDedang_EditSection';
import { DvPageTieudeTintucNoibat_EditSection } from '../components/ServicePage/DvPageTieudeTintucNoibat';
import {
	DvPageNenchonDngEduItemsEditSection,
	DvPageTieudeNenchonDngEdu_EditSection,
} from '../components/ServicePage/DvPageNenchonDngEdu_EditSection';
import { BodySingleBlock } from '../components/Layouts/BodySingleBlock';

const Label = React.forwardRef(function (props: BoxProps, ref: React.ForwardedRef<HTMLButtonElement>) {
	return (
		<Box {...props} ref={ref} className='text-4xl font-bold'>
			{props.children}
		</Box>
	);
});

export const ServicePageEditor = () => {
	const dispatcher = useDispatch();

	const overallInfoQuery = useQuery(gql_queries.getOverallInformationForAdminToEdit, {
		fetchPolicy: 'cache-and-network',
		variables: {
			data: {
				positions: [OverallInfoPositions.servicepage, OverallInfoPositions.anywhere],
			},
		},
	});

	const [dataIsLoaded, setDataIsLoaded] = useState(false);

	useEffect(() => {
		const rawData = overallInfoQuery.data?.getOverallInformationForAdminToEdit;
		if (!rawData) return;
		console.log(rawData);
		dispatcher(appOverallInfoActions.set_app_overall_info_state(rawData));
		setDataIsLoaded(true);
	}, [overallInfoQuery.data]);

	return (
		<BodySingleBlock>
			{overallInfoQuery.data && dataIsLoaded ? (
				<>
					<Box className='grid grid-cols-1 gap-8 py-[100px]'>
						<Label>Banner</Label>
						<DvPageBannerItemsEditSection />
						<Label>Lợi ích vượt trội - Tiêu đề</Label>
						<DvPageTieudeLoiichVuottroi_EditSection />
						<Label>Lợi ích vượt trội - Nội dung</Label>
						<DvPageLoiichVuottroiItemsEditSection />
						<Label>Điều kiện dễ dàng - Tiêu đề</Label>
						<DvPageTieudeDieukienDedang_EditSection />
						<Label>Điều kiện dễ dàng - Nội dung</Label>
						<DvPageDieukienDedangItemsEditSection />
						<Label>Tin tức nổi bật - Tiêu đề</Label>
						<DvPageTieudeTintucNoibat_EditSection />
						<Label>Vì sao nên chọn DNG Edu - Tiêu đề</Label>
						<DvPageTieudeNenchonDngEdu_EditSection />
						<Label>Vì sao nên chọn DNG Edu - Nội dung</Label>
						<DvPageNenchonDngEduItemsEditSection />
					</Box>
				</>
			) : (
				<></>
			)}
		</BodySingleBlock>
	);
};
