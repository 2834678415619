import React, { useMemo } from 'react';
import { Snackbar as UnstyledSnackbar } from '@mui/material';
import html_parser from 'html-react-parser';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store/core.store';
import { appToolsActions } from '../../store/reducers/appTools.reducer';

export const Snackbar = () => {
	const dispatcher = useDispatch();
	const message = useSelector((e: AppState) => e.appTools.snackbar_content);
	const isOpen = useMemo(() => {
		return message !== '';
	}, [message]);

	const MySnackbar = styled(UnstyledSnackbar)`
		ul {
			padding-left: 1rem;
			margin-bottom: 0;
		}
	`;

	return (
		<MySnackbar
			open={isOpen}
			autoHideDuration={3000}
			message={html_parser(message)}
			onClose={() => {
				dispatcher(
					appToolsActions.set_apptools_info({
						snackbar_content: '',
					}),
				);
			}}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
		/>
	);
};
