import { AppCoreLanguages } from '../../store/reducers/app_core_state.reducer';
import { Box, BoxProps, TextField, TextFieldProps } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store/core.store';
import {
	OverallInfoItemNameList,
	OverallInfoPositions,
} from '../../store/reducers/app_overall_info/valueInitialization/app_overall_info_init_value';
import { appOverallInfoActions } from '../../store/reducers/app_overall_info/app_overall_info.reducer';
import React, { DetailedHTMLProps, ImgHTMLAttributes, useEffect, useMemo, useRef } from 'react';
import { appToolsActions } from '../../store/reducers/appTools.reducer';
import { api_post_data } from '../../environments/api_handler';
import { AxiosResponse } from 'axios';
import { AppMediaEntity } from '../../models/apiGqlExportTypes';
import { cdnUrl } from '../../environments/environments';
import { BlackProgress } from '../utils/Progress';
import JoditEditor from 'jodit-react';
import { jodit_config } from '../../models/customJoditEditor';
import { OverallInformationJoditContent } from '../../store/reducers/app_overall_info/valueInitialization/overallInfoJoditContent.defaultValue';
import { PrimaryBlackButton, PrimaryEmeraldButton, PrimaryGreenButton, PrimaryOrangeButton } from '../utils/Button';
import { Image } from '../utils/Image';
import { getI18n } from 'react-i18next';
import { mTrans } from '../../utils/i18n/locales/masterTranslateData';

export const OverallInfo__NonArray__ItemImageUpload = (
	props: DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
		lang: AppCoreLanguages;
		itemName: OverallInfoItemNameList;
		valueKey: string;
	},
) => {
	const { lang, itemName, valueKey, ...imgProps } = props;

	const blockInfo: any = useSelector((e: AppState) => e.app_overall_info[lang][itemName]);

	const itemImg = typeof blockInfo === 'object' && blockInfo[valueKey] ? blockInfo[valueKey] : undefined;

	const addFileRef = useRef<any>();

	const dispatcher = useDispatch();

	const isLoading = useSelector((e: AppState) => e.appTools.is_loading);

	async function upload_file(files: FileList) {
		dispatcher(
			appToolsActions.set_apptools_info({
				is_loading: true,
			}),
		);
		const form_data = new FormData();
		form_data.append('overall_info_image', files[0]);
		await api_post_data('admin_overall_info/upload_app_overall_info_image', form_data)
			.then(
				(
					response: AxiosResponse<{
						message: string;
						payload: AppMediaEntity;
					}>,
				) => {
					dispatcher(
						appOverallInfoActions.set_app_overall_info_lang_nonArray_content({
							lang,
							itemName,
							content: {
								[itemName]: {
									[valueKey]: response.data.payload.path,
								},
							},
						}),
					);
				},
			)
			.catch((error) => console.log(error));
		dispatcher(
			appToolsActions.set_apptools_info({
				is_loading: false,
			}),
		);
		return;
	}

	return (
		<Box className='grid grid-cols-1 gap-8'>
			<Box className='flex flex-col justify-center items-center'>
				{itemImg ? (
					<>
						<Image
							{...imgProps}
							src={`${cdnUrl}/${itemImg}`}
							className={`max-w-full aspect-1 object-cover rounded-3xl border-2 border-slate-400/20`.concat(
								' ',
								imgProps.className ? imgProps.className : '',
							)}
						/>
						<Box className='text-sm break-all text-center'>{itemImg}</Box>
					</>
				) : (
					<></>
				)}
			</Box>
			<input
				ref={addFileRef}
				hidden
				type='file'
				name='upload'
				onChange={async (e) => {
					if (e.target.files && e.target.files.length > 0) {
						await upload_file(e.target.files);
					}
				}}
			/>
			<Box className='text-center'>
				{isLoading ? (
					<>
						<BlackProgress />
					</>
				) : (
					<>
						<PrimaryBlackButton onClick={() => addFileRef.current.click()}>
							<i className='fa-regular fa-image mr-4' /> {getI18n().t(mTrans['Change image'])}
						</PrimaryBlackButton>
					</>
				)}
			</Box>
		</Box>
	);
};

export const OverallInfo__NonArray__ItemTextEditor = (props: {
	lang: AppCoreLanguages;
	itemName: OverallInfoItemNameList;
	valueKey: string;
	textFieldProps: TextFieldProps;
}) => {
	const dispatcher = useDispatch();

	const blockInfo: any = useSelector((e: AppState) => e.app_overall_info[props.lang][props.itemName]);

	return (
		<>
			<TextField
				{...props.textFieldProps}
				value={
					blockInfo[props.valueKey] && typeof blockInfo[props.valueKey] === 'string' ? blockInfo[props.valueKey] : ''
				}
				onChange={(e) => {
					dispatcher(
						appOverallInfoActions.set_app_overall_info_lang_nonArray_content({
							lang: props.lang,
							itemName: props.itemName,
							content: {
								[props.itemName]: {
									[props.valueKey]: e.target.value,
								},
							},
						}),
					);
				}}
			/>
		</>
	);
};

export const OverallInfo__NonArray__ItemJoditEditor = (props: {
	lang: AppCoreLanguages;
	itemName: OverallInfoItemNameList;
	valueKey: string;
}) => {
	const dispatcher = useDispatch();

	const itemSelector: any = useSelector((e: AppState) => e.app_overall_info[props.lang][props.itemName]);
	const langJoditContent: OverallInformationJoditContent = itemSelector[props.valueKey];

	const jodit_content = useMemo((): string => {
		return langJoditContent.content ? langJoditContent.content : '';
	}, [langJoditContent]);

	useEffect(() => {
		if (jodit_content) {
			const resultImages = declare_post_images(jodit_content);
			dispatcher(
				appOverallInfoActions.set_app_overall_info_lang_nonArray_content({
					content: {
						[props.itemName]: {
							[props.valueKey]: {
								...langJoditContent,
								images: resultImages,
							},
						},
					},
					itemName: props.itemName,
					lang: props.lang,
				}),
			);
		}
	}, [jodit_content]);

	const isLoading = useSelector((e: AppState) => e.appTools.is_loading);

	async function upload_file(files: FileList) {
		dispatcher(
			appToolsActions.set_apptools_info({
				is_loading: true,
			}),
		);
		const form_data = new FormData();
		form_data.append('blog_post_image', files[0]);
		await api_post_data('admin/upload_blog_post_image', form_data)
			.then((response) => {
				const new_content = `${langJoditContent.content}<img src="${cdnUrl}/${response.data.payload.path}" width="300" />`;
				dispatcher(
					appOverallInfoActions.set_app_overall_info_lang_nonArray_content({
						content: {
							[props.itemName]: {
								[props.valueKey]: {
									...langJoditContent,
									content: new_content,
								},
							},
						},
						itemName: props.itemName,
						lang: props.lang,
					}),
				);
			})
			.catch((error) => console.log(error));
		dispatcher(
			appToolsActions.set_apptools_info({
				is_loading: false,
			}),
		);
		return;
	}

	function declare_post_images(content: string) {
		const img_set: string[] = [];
		const parser = new DOMParser();
		const doc = parser.parseFromString(content, 'text/html');

		Array.from(doc.querySelectorAll('img')).forEach((img) => {
			const src = img.src;
			if (!src.includes(cdnUrl)) return;
			img_set.push(src.replace(`${cdnUrl}/`, ''));
		});

		return img_set;
	}

	const addFileRef = useRef<any>();

	return (
		<>
			<Box className='mb-8 not-prose'>
				<JoditEditor
					value={jodit_content}
					config={jodit_config}
					onChange={(newContent) => {
						const resultImages = declare_post_images(newContent);
						dispatcher(
							appOverallInfoActions.set_app_overall_info_lang_nonArray_content({
								itemName: props.itemName,
								lang: props.lang,
								content: {
									[props.itemName]: {
										[props.valueKey]: {
											content: newContent,
											images: resultImages,
										},
									},
								},
							}),
						);
					}}
				/>
			</Box>
			<Box className='flex mb-4'>
				<input
					ref={addFileRef}
					hidden
					type='file'
					name='upload'
					onChange={async (e) => {
						if (e.target.files && e.target.files.length > 0) {
							await upload_file(e.target.files);
						}
					}}
				/>
				{isLoading ? (
					<>
						<BlackProgress />
					</>
				) : (
					<>
						<PrimaryEmeraldButton onClick={() => addFileRef.current.click()}>
							<i className='fa-regular fa-image mr-4' /> {getI18n().t(mTrans['Add image'])}
						</PrimaryEmeraldButton>
					</>
				)}
			</Box>
		</>
	);
};

export const OverallInfo__Array__ItemImageUpload = (
	props: DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
		lang: AppCoreLanguages;
		itemID: string;
		itemName: OverallInfoItemNameList;
		valueKey: string;
	},
) => {
	const { lang, itemID, valueKey, itemName, ...imgProps } = props;

	const blockInfo: any = useSelector((e: AppState) => e.app_overall_info[lang][itemName]);

	const itemImg = Array.isArray(blockInfo) ? blockInfo.find((block) => block.ID === itemID)[valueKey] : undefined;

	const addFileRef = useRef<any>();

	const dispatcher = useDispatch();

	const isLoading = useSelector((e: AppState) => e.appTools.is_loading);

	async function upload_file(files: FileList) {
		dispatcher(
			appToolsActions.set_apptools_info({
				is_loading: true,
			}),
		);
		const form_data = new FormData();
		form_data.append('overall_info_image', files[0]);
		await api_post_data('admin_overall_info/upload_app_overall_info_image', form_data)
			.then(
				(
					response: AxiosResponse<{
						message: string;
						payload: AppMediaEntity;
					}>,
				) => {
					dispatcher(
						appOverallInfoActions.set_app_overall_info_lang_array_content({
							lang: lang,
							itemName: itemName,
							data: {
								[valueKey]: response.data.payload.path,
							},
							itemID: itemID,
						}),
					);
				},
			)
			.catch((error) => console.log(error));
		dispatcher(
			appToolsActions.set_apptools_info({
				is_loading: false,
			}),
		);
		return;
	}

	return (
		<Box className='grid grid-cols-1 gap-8'>
			<Box className='flex flex-col justify-center items-center gap-2'>
				{itemImg ? (
					<>
						<img
							{...imgProps}
							src={`${cdnUrl}/${itemImg}`}
							className={`w-full max-w-full aspect-1 object-cover rounded-3xl border-2 border-slate-400/20`.concat(
								' ',
								imgProps.className ? imgProps.className : '',
							)}
						/>
						<Box className='text-sm break-all text-center'>{itemImg}</Box>
					</>
				) : (
					<></>
				)}
			</Box>
			<input
				ref={addFileRef}
				hidden
				type='file'
				name='upload'
				onChange={async (e) => {
					if (e.target.files && e.target.files.length > 0) {
						await upload_file(e.target.files);
					}
				}}
			/>
			<Box className='text-center'>
				{isLoading ? (
					<>
						<BlackProgress />
					</>
				) : (
					<>
						<button className='btn-sm-cyan' onClick={() => addFileRef.current.click()}>
							<i className='fa-regular fa-image mr-4' /> {getI18n().t(mTrans['Change image'])}
						</button>
					</>
				)}
			</Box>
		</Box>
	);
};

export const OverallInfo__Array__ItemTextEditor = (props: {
	lang: AppCoreLanguages;
	itemName: OverallInfoItemNameList;
	itemID: string;
	valueKey: string;
	textFieldProps: TextFieldProps;
}) => {
	const dispatcher = useDispatch();

	const blockInfo: any = useSelector((e: AppState) => e.app_overall_info[props.lang][props.itemName]);
	const foundItem = Array.isArray(blockInfo) ? blockInfo.find((block) => block.ID === props.itemID) : undefined;
	const objValue = foundItem && typeof foundItem[props.valueKey] === 'string' ? foundItem[props.valueKey] : '';

	return (
		<>
			<TextField
				{...props.textFieldProps}
				value={objValue}
				onChange={(e) => {
					dispatcher(
						appOverallInfoActions.set_app_overall_info_lang_array_content({
							lang: props.lang,
							itemName: props.itemName,
							data: {
								[props.valueKey]: e.target.value,
							},
							itemID: props.itemID,
						}),
					);
				}}
			/>
		</>
	);
};

export const OverallInfo__ItemSaveButton = (props: {
	lang: AppCoreLanguages;
	itemName: OverallInfoItemNameList;
	dataPosition: OverallInfoPositions;
	imgArr: string[];
}) => {
	const dispatcher = useDispatch();
	const blockInfo = useSelector((e: AppState) => e.app_overall_info[props.lang][props.itemName]);
	const appIsWaiting = useSelector((e: AppState) => e.appTools.is_loading);

	const submitBlock = async () => {
		dispatcher(appToolsActions.set_apptools_info({ is_loading: true }));
		await api_post_data('admin_overall_info/save_overall_info_item', {
			info_name: props.itemName,
			data: {
				[props.itemName]: blockInfo,
			},
			locale: props.lang,
			dataPosition: props.dataPosition,
			images: props.imgArr,
		}).catch(() => dispatcher(appToolsActions.set_apptools_info({ is_loading: false })));
		dispatcher(appToolsActions.set_apptools_info({ is_loading: false }));
	};

	return (
		<>
			{appIsWaiting ? (
				<>
					<BlackProgress />
				</>
			) : (
				<>
					<PrimaryGreenButton onClick={submitBlock}>
						<i className='fa-regular fa-floppy-disk mr-4' />
						{getI18n().t(mTrans.Save)}
					</PrimaryGreenButton>
				</>
			)}
		</>
	);
};

export const OverallInfo__Array__ItemJoditEditor = (props: {
	lang: AppCoreLanguages;
	itemName: OverallInfoItemNameList;
	itemID: string;
	valueKey: string;
}) => {
	const dispatcher = useDispatch();

	const itemSelector: any = useSelector((e: AppState) => e.app_overall_info[props.lang][props.itemName]);

	const foundItemContent: OverallInformationJoditContent = Array.isArray(itemSelector)
		? itemSelector.find((block) => block.ID === props.itemID)[props.valueKey]
		: undefined;

	const jodit_content = useMemo((): string => {
		return foundItemContent.content;
	}, [foundItemContent]);

	useEffect(() => {
		if (jodit_content) {
			const resultImages = declare_post_images(jodit_content);
			dispatcher(
				appOverallInfoActions.set_app_overall_info_lang_array_content({
					data: {
						[props.valueKey]: {
							...foundItemContent,
							images: resultImages,
						},
					},
					itemID: props.itemID,
					itemName: props.itemName,
					lang: props.lang,
				}),
			);
		}
	}, [jodit_content]);

	const isLoading = useSelector((e: AppState) => e.appTools.is_loading);

	async function upload_file(files: FileList) {
		dispatcher(
			appToolsActions.set_apptools_info({
				is_loading: true,
			}),
		);
		const form_data = new FormData();
		form_data.append('blog_post_image', files[0]);
		await api_post_data('admin/upload_blog_post_image', form_data)
			.then((response) => {
				const new_content = `${foundItemContent.content}<img src="${cdnUrl}/${response.data.payload.path}" width="300" />`;
				dispatcher(
					appOverallInfoActions.set_app_overall_info_lang_array_content({
						data: {
							[props.valueKey]: {
								...foundItemContent,
								content: new_content,
							},
						},
						itemID: props.itemID,
						itemName: props.itemName,
						lang: props.lang,
					}),
				);
			})
			.catch((error) => console.log(error));
		dispatcher(
			appToolsActions.set_apptools_info({
				is_loading: false,
			}),
		);
		return;
	}

	function declare_post_images(content: string) {
		const img_set: string[] = [];
		const parser = new DOMParser();
		const doc = parser.parseFromString(content, 'text/html');

		Array.from(doc.querySelectorAll('img')).forEach((img) => {
			const src = img.src;
			if (!src.includes(cdnUrl)) return;
			img_set.push(src.replace(`${cdnUrl}/`, ''));
		});

		return img_set;
	}

	const addFileRef = useRef<any>();

	return (
		<>
			<Box className='mb-8 not-prose'>
				<JoditEditor
					value={jodit_content}
					config={jodit_config}
					onChange={(newContent) => {
						const resultImages = declare_post_images(newContent);
						dispatcher(
							appOverallInfoActions.set_app_overall_info_lang_array_content({
								itemName: props.itemName,
								lang: props.lang,
								data: {
									[props.valueKey]: {
										content: newContent,
										images: resultImages,
									},
								},
								itemID: props.itemID,
							}),
						);
					}}
				/>
			</Box>
			<Box className='flex mb-4'>
				<input
					ref={addFileRef}
					hidden
					type='file'
					name='upload'
					onChange={async (e) => {
						if (e.target.files && e.target.files.length > 0) {
							await upload_file(e.target.files);
						}
					}}
				/>
				{isLoading ? (
					<>
						<BlackProgress />
					</>
				) : (
					<>
						<PrimaryEmeraldButton onClick={() => addFileRef.current.click()}>
							<i className='fa-regular fa-image mr-4' /> {getI18n().t(mTrans['Add image'])}
						</PrimaryEmeraldButton>
					</>
				)}
			</Box>
		</>
	);
};

export const JoditEditorLabel = React.forwardRef(function (
	props: BoxProps,
	ref: React.ForwardedRef<HTMLButtonElement>,
) {
	return (
		<Box {...props} ref={ref} className='text-2xl font-bold'>
			{props.children}
		</Box>
	);
});

export const AddBlockItemBtn = (props: {
	lang: AppCoreLanguages;
	itemName: OverallInfoItemNameList;
	newValue: any;
}) => {
	const { lang, itemName, newValue } = props;

	const dispatcher = useDispatch();

	return (
		<PrimaryBlackButton
			onClick={() => {
				dispatcher(
					appOverallInfoActions.add_array_block_item({
						lang,
						itemName,
						newValue,
					}),
				);
			}}
		>
			{getI18n().t(mTrans['Add block'])}
		</PrimaryBlackButton>
	);
};

export const DeleteBlockItemBtn = (props: {
	lang: AppCoreLanguages;
	itemName: OverallInfoItemNameList;
	itemID: string;
}) => {
	const { lang, itemName, itemID } = props;

	const dispatcher = useDispatch();

	return (
		<PrimaryOrangeButton
			onClick={() => {
				dispatcher(
					appOverallInfoActions.delete_array_block_item({
						lang,
						itemName,
						itemID,
					}),
				);
			}}
		>
			{getI18n().t(mTrans['Delete block'])}
		</PrimaryOrangeButton>
	);
};
