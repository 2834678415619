import { AppCoreLanguages } from '../../store/reducers/app_core_state.reducer';
import {
	OverallInfoItemNameList,
	OverallInfoPositions,
} from '../../store/reducers/app_overall_info/valueInitialization/app_overall_info_init_value';
import {
	OverallInfo__ItemSaveButton,
	OverallInfo__NonArray__ItemImageUpload,
} from '../OverallInformation/OverallInfoItemTextEditor';
import { Fragment } from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/core.store';

const TitleSaveButton = (props: { lang: AppCoreLanguages }) => {
	const itemName = OverallInfoItemNameList.footerPageLogo;
	const dataPosition = OverallInfoPositions.mainfooter;
	const blockInfo = useSelector((e: AppState) => e.app_overall_info[props.lang][itemName]);

	return (
		<OverallInfo__ItemSaveButton
			lang={props.lang}
			itemName={itemName}
			dataPosition={dataPosition}
			imgArr={[blockInfo.src]}
		/>
	);
};

export const FooterPageLogo_EditSection = () => {
	const itemName = OverallInfoItemNameList.footerPageLogo;

	return (
		<>
			{Object.values(AppCoreLanguages).map((language, i) => (
				<Fragment key={i}>
					<Box className='grid grid-cols-1 gap-8'>
						<Box className='flex justify-center'>
							<OverallInfo__NonArray__ItemImageUpload
								lang={language}
								itemName={itemName}
								valueKey='src'
								className='w-[50px] aspect-1'
							/>
						</Box>
						<Box className='text-end'>
							<TitleSaveButton lang={language} />
						</Box>
					</Box>
				</Fragment>
			))}
		</>
	);
};
