import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserInfo, UserRoles } from '../../models/interface/user_info.interface';

export interface UserInfoState {
	data: UserInfo;
	tried_signing_in: boolean;
}

const initialState: UserInfoState = {
	data: {
		ID: 0,
		email: '',
		role: UserRoles.guest,
	},
	tried_signing_in: false,
};

export const userInfoSlice = createSlice({
	name: 'userInfo',
	initialState: initialState,
	reducers: {
		setUserInfo: (state, action) => {
			state.data = action.payload;
		},
		user_tried_signing_in: (state, action: PayloadAction<boolean>) => {
			state.tried_signing_in = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const userInfoActions = userInfoSlice.actions;
