import { Alert, Box, Card, CardContent, CardHeader } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { api_post_data } from '../../environments/api_handler';
import { QueryResult } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { Category_Editor } from './add_category_panel';
import { AppState } from '../../store/core.store';
import { BlogPostCategoryDetails, blogPostCategoryInfoActions } from '../../store/reducers/category_info.reducer';
import { appToolsActions } from '../../store/reducers/appTools.reducer';
import { PrimaryGreenButton } from '../utils/Button';
import { getI18n } from 'react-i18next';
import { mTrans } from '../../utils/i18n/locales/masterTranslateData';

export const AdminEditCategoryPanel = ({
	category_query,
	category_ID,
}: {
	category_query: QueryResult;
	category_ID: number;
}) => {
	const [current_category, set_current_category] = useState<any>(undefined);

	const category_info = useSelector((e: AppState) => e.blogpost_category_info);

	useEffect(() => {
		const data: BlogPostCategoryDetails[] = category_query.data?.all_blog_post_categories;
		if (data && category_ID) {
			set_current_category(data.find((item) => item.ID == category_ID));
		}
	}, [category_query, category_ID]);

	useEffect(() => {
		dispatcher(blogPostCategoryInfoActions.reset_blog_post_category_state());
		return () => {
			dispatcher(blogPostCategoryInfoActions.reset_blog_post_category_state());
		};
	}, [category_ID]);

	useEffect(() => {
		if (current_category) {
			dispatcher(blogPostCategoryInfoActions.set_blog_post_category_details(current_category));
		}
	}, [current_category]);

	const dispatcher = useDispatch();

	const formSubmit = () => {
		dispatcher(
			blogPostCategoryInfoActions.set_blog_post_category_state({
				touched: true,
			}),
		);
		api_post_data('admin/edit_blog_post_category', category_info.details)
			.then(async () => {
				await category_query.refetch();
				dispatcher(
					appToolsActions.set_apptools_info({
						dialog_isOpen: false,
					}),
				);
			})
			.catch((error) => console.log(error));
	};

	return (
		<>
			{current_category && category_info ? (
				<Card
					sx={{
						width: 500,
					}}
				>
					<CardHeader title={<div className='text-4xl font-bold'>Sửa danh mục</div>} />
					<CardContent>
						<Alert severity='info' className='mb-16'>
							Bạn đang sửa danh mục số {category_info.details.ID}
						</Alert>
						<Box className='mb-16'>
							<Category_Editor />
						</Box>
						<Box className='text-center'>
							<PrimaryGreenButton onClick={formSubmit}>{getI18n().t(mTrans.Save)}</PrimaryGreenButton>
						</Box>
					</CardContent>
				</Card>
			) : (
				<></>
			)}
		</>
	);
};
