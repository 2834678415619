import { Box, BoxProps } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const BodySingleBlock = React.forwardRef(function (props: BoxProps, ref: React.ForwardedRef<HTMLElement>) {
	useTranslation();
	return (
		<Box
			{...props}
			ref={ref}
			className={'w-screen max-w-screen-xl px-4 py-[50px]'.concat(' ', props.className ? props.className : '')}
		>
			{props.children}
		</Box>
	);
});
