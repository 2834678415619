import { v4 } from 'uuid';

export interface DvPageTieudeNenchonDngEdu {
	ID: string;
	title: string;
}
export const dvPageTieudeNenChonDngEduDefaultValue: DvPageTieudeNenchonDngEdu = {
	title: 'TẠI SAO NÊN CHỌN DNG EDUCATION',
	ID: v4(),
};
