import { Box } from '@mui/material';
import { Fragment } from 'react';
import { AppCoreLanguages } from '../../store/reducers/app_core_state.reducer';
import {
	AddBlockItemBtn,
	DeleteBlockItemBtn,
	OverallInfo__Array__ItemImageUpload,
	OverallInfo__Array__ItemTextEditor,
	OverallInfo__ItemSaveButton,
	OverallInfo__NonArray__ItemTextEditor,
} from '../OverallInformation/OverallInfoItemTextEditor';
import {
	OverallInfoItemNameList,
	OverallInfoPositions,
} from '../../store/reducers/app_overall_info/valueInitialization/app_overall_info_init_value';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/core.store';
import { v4 } from 'uuid';
import { InfoBox } from '../LandingPage/LandingPageUtils';
import { dvPageLoiichVuottroiSingleItem } from '../../store/reducers/app_overall_info/valueInitialization/dvPageLoiichVuottroiItems.defaultValue';

const TitleSaveButton = (props: { lang: AppCoreLanguages }) => {
	const itemName = OverallInfoItemNameList.dvPageTieudeLoiichVuottroi;
	const dataPosition = OverallInfoPositions.servicepage;

	return <OverallInfo__ItemSaveButton lang={props.lang} itemName={itemName} dataPosition={dataPosition} imgArr={[]} />;
};

export const DvPageTieudeLoiichVuottroi_EditSection = () => {
	const itemName = OverallInfoItemNameList.dvPageTieudeLoiichVuottroi;

	return (
		<>
			{Object.values(AppCoreLanguages).map((language, i) => (
				<Fragment key={i}>
					<Box className='grid grid-cols-1 gap-8'>
						<OverallInfo__NonArray__ItemTextEditor
							textFieldProps={{
								label: 'Tiêu đề phụ',
							}}
							itemName={itemName}
							valueKey='subtitle'
							lang={language}
						/>
						<OverallInfo__NonArray__ItemTextEditor
							textFieldProps={{
								label: 'Tiêu đề chính',
							}}
							itemName={itemName}
							valueKey='title'
							lang={language}
						/>
						<Box className='text-end'>
							<TitleSaveButton lang={language} />
						</Box>
					</Box>
				</Fragment>
			))}
		</>
	);
};

const Content_MainEditor = (props: { lang: AppCoreLanguages }) => {
	const itemName = OverallInfoItemNameList.dvPageLoiichVuottroiItems;
	const defaultItem = dvPageLoiichVuottroiSingleItem;

	const allBlocks = useSelector((e: AppState) => e.app_overall_info[props.lang][itemName]);

	return (
		<>
			{allBlocks && Array.isArray(allBlocks) ? (
				<>
					{allBlocks.map((block, i) => (
						<Fragment key={i}>
							<InfoBox>
								<OverallInfo__Array__ItemImageUpload
									itemName={itemName}
									itemID={block.ID}
									lang={props.lang}
									valueKey='image'
									className='w-[150px] aspect-1'
								/>
								<OverallInfo__Array__ItemTextEditor
									textFieldProps={{
										label: 'Tiêu đề',
									}}
									itemID={block.ID}
									lang={props.lang}
									valueKey='title'
									itemName={itemName}
								/>
								<OverallInfo__Array__ItemTextEditor
									textFieldProps={{
										label: 'Nội dung',
										multiline: true,
										minRows: 5,
									}}
									itemID={block.ID}
									lang={props.lang}
									valueKey='content'
									itemName={itemName}
								/>
								<OverallInfo__Array__ItemTextEditor
									textFieldProps={{
										label: 'Ưu tiên',
									}}
									itemID={block.ID}
									lang={props.lang}
									valueKey='order'
									itemName={itemName}
								/>
								<Box className='text-end'>
									<DeleteBlockItemBtn lang={props.lang} itemName={itemName} itemID={block.ID} />
								</Box>
							</InfoBox>
						</Fragment>
					))}
					<InfoBox>
						<Box>
							<AddBlockItemBtn
								lang={props.lang}
								itemName={itemName}
								newValue={{
									...defaultItem,
									ID: v4(),
								}}
							/>
						</Box>
					</InfoBox>
				</>
			) : (
				<></>
			)}
		</>
	);
};

const ContentSaveButton = (props: { lang: AppCoreLanguages }) => {
	const itemName = OverallInfoItemNameList.dvPageLoiichVuottroiItems;
	const dataPosition = OverallInfoPositions.servicepage;

	const blockInfo = useSelector((e: AppState) => e.app_overall_info[props.lang][itemName]);
	const images = blockInfo.map((block) => block.image);

	return (
		<OverallInfo__ItemSaveButton lang={props.lang} itemName={itemName} dataPosition={dataPosition} imgArr={images} />
	);
};

export const DvPageLoiichVuottroiItemsEditSection = () => {
	return (
		<>
			{Object.values(AppCoreLanguages).map((language, i) => (
				<Fragment key={i}>
					<Box className='grid grid-cols-1 gap-8'>
						<Box className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8'>
							<Content_MainEditor lang={language} />
						</Box>
						<Box className='text-end'>
							<ContentSaveButton lang={language} />
						</Box>
					</Box>
				</Fragment>
			))}
		</>
	);
};
