import { AppCoreLanguages } from '../../store/reducers/app_core_state.reducer';
import {
	OverallInfoItemNameList,
	OverallInfoPositions,
} from '../../store/reducers/app_overall_info/valueInitialization/app_overall_info_init_value';
import {
	OverallInfo__ItemSaveButton,
	OverallInfo__NonArray__ItemTextEditor,
} from '../OverallInformation/OverallInfoItemTextEditor';
import { Fragment } from 'react';
import { Box } from '@mui/material';

const TitleSaveButton = (props: { lang: AppCoreLanguages }) => {
	const itemName = OverallInfoItemNameList.dvPageTieudeTintucNoibat;
	const dataPosition = OverallInfoPositions.servicepage;

	return <OverallInfo__ItemSaveButton lang={props.lang} itemName={itemName} dataPosition={dataPosition} imgArr={[]} />;
};

export const DvPageTieudeTintucNoibat_EditSection = () => {
	const itemName = OverallInfoItemNameList.dvPageTieudeTintucNoibat;

	return (
		<>
			{Object.values(AppCoreLanguages).map((language, i) => (
				<Fragment key={i}>
					<Box className='grid grid-cols-1 gap-8'>
						<OverallInfo__NonArray__ItemTextEditor
							textFieldProps={{
								label: 'Tiêu đề chính',
							}}
							itemName={itemName}
							valueKey='title'
							lang={language}
						/>
						<OverallInfo__NonArray__ItemTextEditor
							textFieldProps={{
								label: 'Tiêu đề phụ',
							}}
							itemName={itemName}
							valueKey='subtitle'
							lang={language}
						/>
						<Box className='text-end'>
							<TitleSaveButton lang={language} />
						</Box>
					</Box>
				</Fragment>
			))}
		</>
	);
};
