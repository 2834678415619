import React, { useEffect, useState } from 'react';
import { Box, BoxProps } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { gql_queries } from '../models/interface/gql_queries';
import { OverallInfoPositions } from '../store/reducers/app_overall_info/valueInitialization/app_overall_info_init_value';
import { appOverallInfoActions } from '../store/reducers/app_overall_info/app_overall_info.reducer';
import { FooterTimhieuDuhocDuc_EditSection } from '../components/Footer/FooterTimhieuDuhocDuc_EditSection';
import { FooterTongquanveCongtyDNG_EditSection } from '../components/Footer/FooterTongquanveDNGEdu_EditSection';
import {
	FooterDichvuItemsEditSection,
	FooterTieudeDichvu_EditSection,
} from '../components/Footer/FooterDanhsachDichvu_EditSection';
import { FooterSocialMediaItemsEditSection } from '../components/Footer/FooterSocialMediaItems_EditSection';
import { FooterPageLogo_EditSection } from '../components/Footer/FooterPageLogo_EditSection';
import { FooterAddressDNG_EditSection } from '../components/Footer/FooterAddressDNG_EditSection';
import { BodySingleBlock } from '../components/Layouts/BodySingleBlock';

const Label = React.forwardRef(function (props: BoxProps, ref: React.ForwardedRef<HTMLButtonElement>) {
	return (
		<Box {...props} ref={ref} className='text-4xl font-bold'>
			{props.children}
		</Box>
	);
});

export const FooterComponentEditor = () => {
	const dispatcher = useDispatch();

	const overallInfoQuery = useQuery(gql_queries.getOverallInformationForAdminToEdit, {
		fetchPolicy: 'cache-and-network',
		variables: {
			data: {
				positions: [OverallInfoPositions.mainfooter],
			},
		},
	});

	const [dataIsLoaded, setDataIsLoaded] = useState(false);

	useEffect(() => {
		const rawData = overallInfoQuery.data?.getOverallInformationForAdminToEdit;
		if (!rawData) return;
		dispatcher(appOverallInfoActions.set_app_overall_info_state(rawData));
		setDataIsLoaded(true);
	}, [overallInfoQuery.data]);

	return (
		<BodySingleBlock>
			{overallInfoQuery.data && dataIsLoaded ? (
				<>
					<Box className='grid grid-cols-1 gap-8 py-[100px]'>
						<Label>Footer Logo</Label>
						<FooterPageLogo_EditSection />
						<Label>Tiêu đề & ảnh form tìm hiểu du học Đức</Label>
						<FooterTimhieuDuhocDuc_EditSection />
						<Label>Tổng quan về DNG Edu</Label>
						<FooterTongquanveCongtyDNG_EditSection />
						<Label>Địa chỉ liên hệ DNG Edu</Label>
						<FooterAddressDNG_EditSection />
						<Label>Tiêu đề dịch vụ</Label>
						<FooterTieudeDichvu_EditSection />
						<Label>Danh sách các dịch vụ</Label>
						<FooterDichvuItemsEditSection />
						<Label>Danh sách social media</Label>
						<FooterSocialMediaItemsEditSection />
					</Box>
				</>
			) : (
				<></>
			)}
		</BodySingleBlock>
	);
};
