import { v4 } from 'uuid';

export interface FooterDichVuItem {
	ID: string;
	link: string;
	title: string;
	order: string;
}

export const footerDichVuSingleItem: FooterDichVuItem = {
	ID: v4(),
	title: 'Du học nghề Đức/ Mỹ/ Úc',
	link: 'https://www.google.com',
	order: '1',
};

export const footerDichVuItemsDefaultValue: FooterDichVuItem[] = [
	{ ...footerDichVuSingleItem, ID: v4() },
	{ ...footerDichVuSingleItem, ID: v4() },
	{ ...footerDichVuSingleItem, ID: v4() },
	{ ...footerDichVuSingleItem, ID: v4() },
];
