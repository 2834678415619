import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { visuallyHidden } from '@mui/utils';
import { useEffect, useRef, useState } from 'react';
import { HeadCell } from '../../../models/interface/tableUI.interface';
import { api_post_data } from '../../../environments/api_handler';
import { Button, Menu, MenuItem, MenuProps } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/core.store';
import { PrimaryBlackButton, PrimaryGreenButton, PrimaryOrangeButton } from '../Button';
import { blogPostAction } from '../../../store/reducers/blog_post_info.reducer';
import { getI18n } from 'react-i18next';
import { mTrans } from '../../../utils/i18n/locales/masterTranslateData';
import { appToolsActions } from '../../../store/reducers/appTools.reducer';
import { TransferPostToCategoryPanel } from '../../Blog/TransferPostToCategoryPanel';

const StyledMenu = (props: MenuProps) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		{...props}
	/>
);

const title_list = {
	delete: 'Xóa',
	refresh: 'Tải lại',
};

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
	order: Order,
	orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
	const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
	numSelected: number;
	onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
	onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
	order: Order;
	orderBy: string;
	rowCount: number;
	headCells: HeadCell[];
}

function EnhancedTableHead(props: EnhancedTableProps) {
	const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells } = props;
	const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				<TableCell padding='checkbox'>
					<Checkbox
						color='primary'
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
					/>
				</TableCell>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align='left'
						padding={headCell.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === headCell.id ? order : false}
						hidden={headCell.hidden}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{getI18n().t(headCell.label)}
							{orderBy === headCell.id ? (
								<Box component='span' sx={visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

interface EnhancedTableToolbarProps {
	numSelected: number;
	deleteFn?: () => any;
	refresh_table_fn: () => any;
	table_title: string;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
	const { numSelected, deleteFn, refresh_table_fn, table_title } = props;
	const ToolAnchor = useRef<any>();
	const [toolOpen, setToolOpen] = useState(false);
	const dispatcher = useDispatch();

	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
				}),
			}}
		>
			{numSelected > 0 ? (
				<div style={{ flex: '1 1 100%' }} color='inherit' className='text-sm'>
					{numSelected} selected
				</div>
			) : (
				<div style={{ flex: '1 1 100%' }} id='tableTitle' className='text-2xl font-bold'>
					{table_title}
				</div>
			)}
			{numSelected > 0 ? (
				<Box className='flex gap-4'>
					<Tooltip title={title_list.delete}>
						<PrimaryOrangeButton onClick={deleteFn}>
							<i className='fa-regular fa-trash-can' />
						</PrimaryOrangeButton>
					</Tooltip>
					<PrimaryBlackButton
						ref={ToolAnchor}
						onClick={() => {
							setToolOpen(true);
						}}
					>
						<i className='fa-regular fa-hammer' />
					</PrimaryBlackButton>
					<Menu
						onClose={() => {
							setToolOpen(false);
						}}
						className='mt-4'
						anchorEl={ToolAnchor.current}
						open={toolOpen}
					>
						<MenuItem
							onClick={() => {
								dispatcher(
									appToolsActions.set_apptools_info({
										dialog_isOpen: true,
										dialogContent: <TransferPostToCategoryPanel />,
									}),
								);
							}}
						>
							{getI18n().t(mTrans['Move posts to other category'])}
						</MenuItem>
					</Menu>
				</Box>
			) : (
				<Tooltip title={title_list.refresh}>
					<PrimaryGreenButton onClick={refresh_table_fn}>
						<i className='fa-regular fa-arrows-rotate' />
					</PrimaryGreenButton>
				</Tooltip>
			)}
		</Toolbar>
	);
};

interface TableWithSelectionBodyProps {
	headCells: HeadCell[];
	tableData: any[];
	rowMainKey: string;
	deleteRowApiUrlSuffix?: string;
	afterDeleteRowFn?: () => any;
	refresh_table_fn: () => any;
	row_click_action?: (value: any) => any;
	table_title: string;
}

export const TableWithSelection_Body = (props: TableWithSelectionBodyProps) => {
	const {
		headCells,
		tableData,
		rowMainKey,
		deleteRowApiUrlSuffix,
		afterDeleteRowFn,
		refresh_table_fn,
		row_click_action,
		table_title,
	} = props;

	const dispatcher = useDispatch();

	const deleteFn = async () => {
		if (deleteRowApiUrlSuffix) {
			await api_post_data(deleteRowApiUrlSuffix, { ID: selected });
			if (afterDeleteRowFn) afterDeleteRowFn();
			return;
		}
	};

	const [order, setOrder] = React.useState<Order>('asc');
	const [orderBy, setOrderBy] = React.useState<any>('calories');
	const [selected, setSelected] = React.useState<readonly string[]>([]);
	const [page, setPage] = React.useState(0);
	const [dense, setDense] = React.useState(false);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const searchTerm = useSelector((e: AppState) => e.table_UI.search_term);
	const searchKey = useSelector((e: AppState) => e.table_UI.search_key);
	const [rows, setRows] = React.useState<any[]>([]);

	useEffect(() => {
		setRows(tableData);
	}, [tableData]);

	useEffect(() => {
		dispatcher(blogPostAction.set_blog_post_state({ selectedPosts: [...selected] }));
	}, [selected]);

	useEffect(() => {
		searchFn(searchKey);
	}, [searchKey]);

	const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelecteds = rows.map((n) => n[rowMainKey]);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected: readonly string[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
		}

		setSelected(newSelected);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
		setDense(event.target.checked);
	};

	const isSelected = (name: string) => selected.indexOf(name) !== -1;

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	const searchFn = (val: string) => {
		let data = [...tableData];
		try {
			data = data.filter((item) => String(item[searchTerm]).toLowerCase().includes(String(val).toLowerCase()));
			setRows(data);
		} catch (error) {
			return;
		}
	};

	return (
		<Box sx={{ width: '100%' }}>
			<Paper sx={{ width: '100%', mb: 2 }}>
				<EnhancedTableToolbar
					table_title={table_title}
					numSelected={selected.length}
					deleteFn={deleteFn}
					refresh_table_fn={refresh_table_fn}
				/>
				<TableContainer>
					<Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size={dense ? 'small' : 'medium'}>
						<EnhancedTableHead
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={rows.length}
							headCells={headCells}
						/>
						<TableBody>
							{/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
							{stableSort(rows, getComparator(order, orderBy))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row, index) => {
									const mainKey: any = row[rowMainKey];
									const isItemSelected = isSelected(mainKey);
									const labelId = `enhanced-table-checkbox-${index}`;

									return (
										<TableRow
											hover
											onClick={(event) => handleClick(event, mainKey)}
											role='checkbox'
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={mainKey}
											selected={isItemSelected}
										>
											<TableCell padding='checkbox'>
												<Checkbox
													color='primary'
													checked={isItemSelected}
													inputProps={{
														'aria-labelledby': labelId,
													}}
												/>
											</TableCell>
											{headCells.map((single) => (
												<TableCell
													key={single.id}
													align='left'
													hidden={single.hidden}
													onClick={(e) => {
														e.stopPropagation();
														if (single.clickable && row_click_action) {
															row_click_action(row);
														}
													}}
													style={single.clickable ? { cursor: 'pointer' } : {}}
												>
													{row[single.id]}
												</TableCell>
											))}
										</TableRow>
									);
								})}
							{emptyRows > 0 && (
								<TableRow
									style={{
										height: (dense ? 33 : 53) * emptyRows,
									}}
								>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component='div'
					count={rows.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>
			<FormControlLabel
				control={<Switch checked={dense} onChange={handleChangeDense} />}
				label={
					<Button
						onClick={() => {
							setDense((prev) => !prev);
						}}
					>
						{getI18n().t(mTrans.Narrow)}
					</Button>
				}
			/>
		</Box>
	);
};
