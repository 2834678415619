import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/style.scss';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { reduxStore, rootPersistor } from './store/core.store';
import { graphQLclient } from './models/graphQL_Client';
import { RouterProvider } from 'react-router-dom';
import { indexRouter } from './models/allRoutes';
import { PersistGate } from 'redux-persist/integration/react';
import './utils/i18n/index';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
	<React.StrictMode>
		<Provider store={reduxStore}>
			<PersistGate loading={null} persistor={rootPersistor}>
				<ApolloProvider client={graphQLclient}>
					<RouterProvider router={indexRouter} />
				</ApolloProvider>
			</PersistGate>
		</Provider>
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
