import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppCoreLanguages, appDefaultLanguage } from './app_core_state.reducer';

export interface BlogPostContent {
	ID?: number;
	title: string;
	content: string;
	excerpt: string;
}

type stateDetails_Content = {
	[key in AppCoreLanguages]: BlogPostContent;
};

type stateDetailsImages = {
	[key in AppCoreLanguages]: string[];
};

const langContent = (): stateDetails_Content => {
	const initVal: BlogPostContent = {
		ID: undefined,
		content: '',
		title: '',
		excerpt: '',
	};
	return { [AppCoreLanguages.viVN]: initVal };
};

const langImages = (): stateDetailsImages => {
	return { [AppCoreLanguages.viVN]: [] };
};

export type LangJoditContent = {
	[key in AppCoreLanguages]: {
		locale: AppCoreLanguages;
		content: string;
	};
};

export const langJoditContent: LangJoditContent = {
	[AppCoreLanguages.viVN]: {
		locale: AppCoreLanguages.viVN,
		content: '',
	},
	// [AppCoreLanguages.enUS]: {
	// 	locale: AppCoreLanguages.enUS,
	// 	content: '',
	// },
};

export type BlogPostStateDetails = {
	ID?: number;
	urlSlug: string;
	category: number[] | null;
	content: stateDetails_Content;
	images: stateDetailsImages;
	avatar: string;
};

export interface BlogPostState {
	details: BlogPostStateDetails;
	search_key: string;
	touched: boolean;
	autoUrl: boolean;
	autoUrlSrc: AppCoreLanguages;
	langJoditContent: LangJoditContent;
	selectedPosts: string[] | number[];
}

export const blogPostDetails_InitVal: BlogPostStateDetails = {
	ID: undefined,
	urlSlug: '',
	category: null,
	content: langContent(),
	images: langImages(),
	avatar: '',
};

export const blog_post_initial_state: BlogPostState = {
	details: blogPostDetails_InitVal,
	search_key: '',
	touched: false,
	autoUrl: true,
	autoUrlSrc: appDefaultLanguage.locale,
	langJoditContent,
	selectedPosts: [],
};

export const BlogPostSlice = createSlice({
	name: 'blog_post_info',
	initialState: blog_post_initial_state,
	reducers: {
		set_blog_post_info: (state, action: PayloadAction<Partial<BlogPostStateDetails>>) => {
			Object.assign(state.details, action.payload);
		},
		set_blog_post_state: (state, action: PayloadAction<Partial<BlogPostState>>) => {
			Object.assign(state, action.payload);
		},
		reset_blog_post_state: (state) => {
			Object.assign(state, blog_post_initial_state);
		},
	},
});

// Action creators are generated for each case reducer function
export const blogPostAction = BlogPostSlice.actions;
