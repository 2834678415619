import { v4 } from 'uuid';

export interface TamnhinsumenhgiatriContent {
	ID: string;
	tamnhin_title: string;
	tamnhin_content: string;
	sumenh_title: string;
	sumenh_content: string;
	giatri_title: string;
	giatri_content: string;
	image: string;
}

const defaulContent =
	'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. ';

export const tamnhinSumenhGiatriDefaultVal: TamnhinsumenhgiatriContent = {
	giatri_content: defaulContent,
	giatri_title: 'Giá trị cốt lõi',
	sumenh_content: defaulContent,
	sumenh_title: 'Sứ mệnh',
	tamnhin_content: defaulContent,
	tamnhin_title: 'Tầm nhìn',
	ID: v4(),
	image: 'defaultImage.webp',
};
