import React, { useEffect, useState } from 'react';
import { Box, BoxProps } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { gql_queries } from '../models/interface/gql_queries';
import { OverallInfoPositions } from '../store/reducers/app_overall_info/valueInitialization/app_overall_info_init_value';
import { appOverallInfoActions } from '../store/reducers/app_overall_info/app_overall_info.reducer';
import { LessonPageTiengDucdanhchoban_EditSection } from '../components/LessonPage/LessonPageTiengDucDanhchoBan_EditSection';
import {
	LessonPageNenchonTiengducDngEduItemsEditSection,
	LessonPageTieudeNenchonTiengducDNGEdu_EditSection,
} from '../components/LessonPage/LessonPageNenchonTiengDucDNGEdu_EditSection';
import {
	LessonPageCackhoahocTiengducItemsEditSection,
	LessonPageTieudeCackhoahocTiengduc_EditSection,
} from '../components/LessonPage/LessonPageCackhoahocTiengDuc_EditSection';
import { BodySingleBlock } from '../components/Layouts/BodySingleBlock';

const Label = React.forwardRef(function (props: BoxProps, ref: React.ForwardedRef<HTMLButtonElement>) {
	return (
		<Box {...props} ref={ref} className='text-4xl font-bold'>
			{props.children}
		</Box>
	);
});

export const LessonPageEditor = () => {
	const dispatcher = useDispatch();

	const overallInfoQuery = useQuery(gql_queries.getOverallInformationForAdminToEdit, {
		fetchPolicy: 'cache-and-network',
		variables: {
			data: {
				positions: [OverallInfoPositions.lessonpage],
			},
		},
	});

	const [dataIsLoaded, setDataIsLoaded] = useState(false);

	useEffect(() => {
		const rawData = overallInfoQuery.data?.getOverallInformationForAdminToEdit;
		if (!rawData) return;
		dispatcher(appOverallInfoActions.set_app_overall_info_state(rawData));
		setDataIsLoaded(true);
	}, [overallInfoQuery.data]);

	return (
		<BodySingleBlock>
			{overallInfoQuery.data && dataIsLoaded ? (
				<>
					<Box className='grid grid-cols-1 gap-8 py-[100px]'>
						<Label>Banner</Label>
						<LessonPageTiengDucdanhchoban_EditSection />
						<Label>Vì sao nên chọn DNG Edu - Tiêu đề</Label>
						<LessonPageTieudeNenchonTiengducDNGEdu_EditSection />
						<Label>Vì sao nên chọn DNG Edu - Nội dung</Label>
						<LessonPageNenchonTiengducDngEduItemsEditSection />
						<Label>Các khóa học tiếng Đức - Tiêu đề</Label>
						<LessonPageTieudeCackhoahocTiengduc_EditSection />
						<Label>Các khóa học tiếng Đức - Nội dung</Label>
						<LessonPageCackhoahocTiengducItemsEditSection />
					</Box>
				</>
			) : (
				<></>
			)}
		</BodySingleBlock>
	);
};
