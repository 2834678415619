import { v4 } from 'uuid';

export interface HomepageBannerContentType {
	ID: string;
	subtitle: string;
	title: string;
	content: string;
	firstBtnText: string;
	firstBtnUrl: string;
	secondBtnText: string;
	secondBtnUrl: string;
	image: string;
	order: string;
}

export const homepageBannerBlockDefaultItem: HomepageBannerContentType = {
	firstBtnUrl: 'https://www.google.com',
	secondBtnUrl: 'https://www.google.com',
	ID: v4(),
	subtitle: 'Bắt đầu hành trình chuẩn bị vào ĐH TOP Đức/Mỹ',
	title: 'cùng DNG EDUCATION',
	content:
		'Cá nhân hóa các hoạt động cố vấn và hỗ trợ cho học sinh, giúp các em tăng khả năng ứng tuyển thành công vào các trường ĐH như LMU Munich, Oxbridge lên 700%.',
	firstBtnText: 'Tìm hiểu thêm',
	secondBtnText: 'Đăng ký',
	image: 'defaultImage.webp',
	order: '1',
};

export const homepageBannerBlockDefaultVal: HomepageBannerContentType[] = [
	{ ...homepageBannerBlockDefaultItem, ID: v4() },
	{ ...homepageBannerBlockDefaultItem, ID: v4() },
	{ ...homepageBannerBlockDefaultItem, ID: v4() },
];
