import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Box, Divider, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { api_post_data } from '../../environments/api_handler';
import { gql_queries } from '../../models/interface/gql_queries';
import { appToolsActions } from '../../store/reducers/appTools.reducer';
import { useDispatch } from 'react-redux';
import { PrimaryGreenButton } from '../utils/Button';
import { getI18n } from 'react-i18next';
import { mTrans } from '../../utils/i18n/locales/masterTranslateData';

export const BlogPostCategoryTransferPanel = () => {
	const dispatcher = useDispatch();

	const category_query = useQuery(gql_queries.all_blog_post_categories, {
		fetchPolicy: 'cache-and-network',
	});

	const [all_categories, set_all_categories] = useState<any[] | null>(null);
	const [current_cat, set_current_cat] = useState<number>(0);
	const [chosen_cat, set_chosen_cat] = useState<number>(0);

	useEffect(() => {
		const raw_data = category_query.data?.all_blog_post_categories;

		if (raw_data) set_all_categories(raw_data);
	}, [category_query.data]);

	return (
		<>
			{all_categories && all_categories.length > 0 ? (
				<Box
					className='p-8'
					style={{
						width: 400,
						maxWidth: '100%',
					}}
				>
					<Box className='mb-16'>
						<div className='text-4xl font-bold'>{getI18n().t(mTrans['Transfer posts'])}</div>
					</Box>
					<Box>
						<FormControl fullWidth>
							<InputLabel>{getI18n().t(mTrans.From)}</InputLabel>
							<Select
								value={current_cat ? current_cat : 0}
								label={getI18n().t(mTrans.From)}
								onChange={(e) => {
									set_current_cat(parseInt(e.target.value.toString()) ? parseInt(e.target.value.toString()) : 0);
								}}
							>
								<MenuItem value={0}>{getI18n().t(mTrans['Choose one'])}...</MenuItem>
								{all_categories.map((item: any, i: number) => (
									<MenuItem key={i} value={item.ID}>
										{item.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>
					<Box className='py-8'>
						<Divider />
					</Box>
					<Box className='mb-8'>
						<FormControl fullWidth className='mb-3'>
							<InputLabel>{getI18n().t(mTrans.To)}</InputLabel>
							<Select
								value={chosen_cat ? chosen_cat : 0}
								label={getI18n().t(mTrans.To)}
								onChange={(e) => {
									set_chosen_cat(parseInt(e.target.value.toString()) ? parseInt(e.target.value.toString()) : 0);
								}}
							>
								<MenuItem value={0}>{getI18n().t(mTrans['Choose one'])}...</MenuItem>
								{all_categories.map((item: any, i: number) => (
									<MenuItem key={i} value={item.ID}>
										{item.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>
					<Box className='text-center'>
						<PrimaryGreenButton
							onClick={async () => {
								if (!window.confirm(`Are you sure you want to continue?`)) {
									return false;
								}
								const result = await api_post_data('admin/transfer_blogpost_between_categories', {
									from: [current_cat],
									to: [chosen_cat],
								});
								if (result.status === 201) {
									dispatcher(
										appToolsActions.set_apptools_info({
											dialog_isOpen: false,
										}),
									);
								}
							}}
						>
							OK
						</PrimaryGreenButton>
					</Box>
				</Box>
			) : (
				<></>
			)}
		</>
	);
};
