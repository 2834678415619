import { v4 } from 'uuid';

export interface DvPageNenchonDngContent {
	ID: string;
	title: string;
	subtitle: string;
	image: string;
	order: string;
}

export const dvPageNenchonDngContentSingleItem: DvPageNenchonDngContent = {
	ID: v4(),
	title: '10+',
	image: 'defaultImage.webp',
	subtitle: 'năm kinh nghiệm tư vấn du học nghề Đức ...',
	order: '1',
};

export const dvPageNenchonDngContentItemsDefaultVal: DvPageNenchonDngContent[] = [
	{ ...dvPageNenchonDngContentSingleItem, ID: v4() },
	{ ...dvPageNenchonDngContentSingleItem, ID: v4() },
	{ ...dvPageNenchonDngContentSingleItem, ID: v4() },
	{ ...dvPageNenchonDngContentSingleItem, ID: v4() },
];
