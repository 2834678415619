import { v4 } from 'uuid';
import { OverallInformationJoditContent } from './overallInfoJoditContent.defaultValue';

export interface LotrinhDuhocNgheDuc {
	ID: string;
	title: string;
	subtitle: string;
	btnText: string;
	btnUrl: string;
	content: OverallInformationJoditContent;
	firstImg: string;
	secondImg: string;
}
export const lotrinhDuhocNgheDucDefaultValue: LotrinhDuhocNgheDuc = {
	firstImg: 'defaultImage.webp',
	secondImg: 'defaultImage.webp',
	btnText: 'Tìm hiểu thêm ',
	btnUrl: 'https://www.google.com',
	content: {
		content:
			'Du học Đức không khó nếu ta có một lộ trình cụ thể và chuẩn xác. Nhưng không phải ai cũng biết cách vạch rõ cho mình con đường du học đúng đắn. Thời điểm hiện tại chính là thời điểm phù hợp để bắt đầu chiến dịch chinh phục giấc mơ Đức. Hãy cùng DNG EDUCATION tìm hiểu thêm thông tin về lộ trình du học Đức 2022 để trở thành những du học sinh thông thái nhé!',
		images: [],
	},
	subtitle: 'Lộ trình',
	title: 'Du học nghề Đức',
	ID: v4(),
};
