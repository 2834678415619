import { v4 } from 'uuid';

export interface TieuDeTruonghocDoitacChienluoc {
	ID: string;
	title: string;
}
export const tieuDeTruonghocDoitacChienluocDefaultValue: TieuDeTruonghocDoitacChienluoc = {
	title: 'TRƯỜNG HỌC VÀ ĐỐI TÁC CHIẾN LƯỢC ',
	ID: v4(),
};
