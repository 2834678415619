import { AppCoreLanguages } from '../../store/reducers/app_core_state.reducer';
import {
	OverallInfoItemNameList,
	OverallInfoPositions,
} from '../../store/reducers/app_overall_info/valueInitialization/app_overall_info_init_value';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/core.store';
import { Fragment } from 'react';
import { InfoBox } from '../LandingPage/LandingPageUtils';
import {
	AddBlockItemBtn,
	DeleteBlockItemBtn,
	JoditEditorLabel,
	OverallInfo__Array__ItemTextEditor,
	OverallInfo__ItemSaveButton,
} from '../OverallInformation/OverallInfoItemTextEditor';
import { Box } from '@mui/material';
import { v4 } from 'uuid';
import { footerSocialMediaItem } from '../../store/reducers/app_overall_info/valueInitialization/footerSocialMediaItems.defaultValue';

const SampleSocialMediaItem = (props: { lang: AppCoreLanguages; itemID: string }) => {
	const itemName = OverallInfoItemNameList.footerSocialMediaItems;
	const blockInfo = useSelector((e: AppState) => e.app_overall_info[props.lang][itemName]);
	const found = blockInfo.find((item) => item.ID === props.itemID);

	return <i className={`text-[32px] fa-brands fa-${found?.variant}`} />;
};

const Content_MainEditor = (props: { lang: AppCoreLanguages }) => {
	const itemName = OverallInfoItemNameList.footerSocialMediaItems;
	const defaultItem = footerSocialMediaItem;

	const allBlocks = useSelector((e: AppState) => e.app_overall_info[props.lang][itemName]);

	return (
		<>
			{allBlocks && Array.isArray(allBlocks) ? (
				<>
					{allBlocks.map((block, i) => (
						<Fragment key={i}>
							<InfoBox>
								<OverallInfo__Array__ItemTextEditor
									textFieldProps={{
										label: 'Tên brand',
										placeholder: 'vd: facebook, instagram, youtube,...',
									}}
									itemID={block.ID}
									lang={props.lang}
									valueKey='variant'
									itemName={itemName}
								/>
								<a
									className='text-inherit'
									href='https://fontawesome.com/search?o=r&f=brands'
									target='_blank'
									rel='noreferrer'
								>
									Link tham khảo brand
								</a>
								<OverallInfo__Array__ItemTextEditor
									textFieldProps={{
										label: 'Liên kết mạng xã hội',
									}}
									itemID={block.ID}
									lang={props.lang}
									valueKey='link'
									itemName={itemName}
								/>
								<OverallInfo__Array__ItemTextEditor
									textFieldProps={{
										label: 'Ưu tiên',
									}}
									itemID={block.ID}
									lang={props.lang}
									valueKey='order'
									itemName={itemName}
								/>
								<JoditEditorLabel>Mẫu</JoditEditorLabel>
								<SampleSocialMediaItem lang={props.lang} itemID={block.ID} />
								<Box className='text-end'>
									<DeleteBlockItemBtn lang={props.lang} itemName={itemName} itemID={block.ID} />
								</Box>
							</InfoBox>
						</Fragment>
					))}
					<InfoBox>
						<Box>
							<AddBlockItemBtn
								lang={props.lang}
								itemName={itemName}
								newValue={{
									...defaultItem,
									ID: v4(),
								}}
							/>
						</Box>
					</InfoBox>
				</>
			) : (
				<></>
			)}
		</>
	);
};

const ContentSaveButton = (props: { lang: AppCoreLanguages }) => {
	const itemName = OverallInfoItemNameList.footerSocialMediaItems;
	const dataPosition = OverallInfoPositions.mainfooter;

	return <OverallInfo__ItemSaveButton lang={props.lang} itemName={itemName} dataPosition={dataPosition} imgArr={[]} />;
};

export const FooterSocialMediaItemsEditSection = () => {
	return (
		<>
			{Object.values(AppCoreLanguages).map((language, i) => (
				<Fragment key={i}>
					<Box className='grid grid-cols-1 gap-8'>
						<Box className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8'>
							<Content_MainEditor lang={language} />
						</Box>
						<Box className='text-end'>
							<ContentSaveButton lang={language} />
						</Box>
					</Box>
				</Fragment>
			))}
		</>
	);
};
