import { Box, BoxProps } from '@mui/material';
import { LandingPageBannerEditSection } from '../components/LandingPage/children/LandingPageBannerEditSection';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { gql_queries } from '../models/interface/gql_queries';
import { appOverallInfoActions } from '../store/reducers/app_overall_info/app_overall_info.reducer';
import { OverallInfoPositions } from '../store/reducers/app_overall_info/valueInitialization/app_overall_info_init_value';
import { HomepageTongQuanVeDNG_EditSection } from '../components/LandingPage/children/HomepageTongQuanVeDNG_EditSection';
import { TamNhinSuMenhGiaTriEditSection } from '../components/LandingPage/children/TamNhinSuMenhGiaTri_EditSection';
import {
	HomepageDichvuTuvanItemsEditSection,
	HomepageTieudeDichvuTuvan_EditSection,
} from '../components/LandingPage/children/HomepageDichvuTuvan_EditSection';
import { HomepageLotrinhDuhocNgheDuc_EditSection } from '../components/LandingPage/children/LotrinhDuhocNgheDuc_EditSection';
import {
	HomepageTieudeDoitacChienluoc_EditSection,
	HomepageTruonghocDoiTacChienluocItemsEditSection,
} from '../components/LandingPage/children/HomepageTruonghocDoiTacChienluoc';
import {
	HomepageLangngheChiaseHocsinhItemsEditSection,
	HomepageTieudeLangngheChiaseHocsinh_EditSection,
} from '../components/LandingPage/children/HomepageLangngheChiaseHocsinh';
import { HomepageDkEmail_EditSection } from '../components/LandingPage/children/HomepageDangkyEmail';
import { HomepageTieudeTintucSukien_EditSection } from '../components/LandingPage/children/TieudeTintucSukien_EditSection';
import { BodySingleBlock } from '../components/Layouts/BodySingleBlock';

const Label = React.forwardRef(function (props: BoxProps, ref: React.ForwardedRef<HTMLButtonElement>) {
	return (
		<Box {...props} ref={ref} className='text-4xl font-bold'>
			{props.children}
		</Box>
	);
});

export const LandingPageEditor = () => {
	const dispatcher = useDispatch();

	const overallInfoQuery = useQuery(gql_queries.getOverallInformationForAdminToEdit, {
		fetchPolicy: 'cache-and-network',
		variables: {
			data: {
				positions: [OverallInfoPositions.landingpage, OverallInfoPositions.anywhere],
			},
		},
	});

	const [dataIsLoaded, setDataIsLoaded] = useState(false);

	useEffect(() => {
		const rawData = overallInfoQuery.data?.getOverallInformationForAdminToEdit;
		if (!rawData) return;
		dispatcher(appOverallInfoActions.set_app_overall_info_state(rawData));
		setDataIsLoaded(true);
	}, [overallInfoQuery.data]);

	return (
		<>
			{overallInfoQuery.data && dataIsLoaded ? (
				<BodySingleBlock>
					<Box className='grid grid-cols-1 gap-8 py-[100px]'>
						<Label>Banner</Label>
						<LandingPageBannerEditSection />
						<Label>Tổng quan về DNG</Label>
						<HomepageTongQuanVeDNG_EditSection />
						<Label>Tầm Nhìn - Sứ Mệnh - Giá Trị</Label>
						<TamNhinSuMenhGiaTriEditSection />
						<Label>Dịch vụ tư vấn - Tiêu đề</Label>
						<HomepageTieudeDichvuTuvan_EditSection />
						<Label>Dịch vụ tư vấn - Nội dung</Label>
						<HomepageDichvuTuvanItemsEditSection />
						<Label>Lộ trình du học Đức</Label>
						<HomepageLotrinhDuhocNgheDuc_EditSection />
						<Label>Đối tác chiến lược - Tiêu đề</Label>
						<HomepageTieudeDoitacChienluoc_EditSection />
						<Label>Đối tác chiến lược - Nội dung</Label>
						<HomepageTruonghocDoiTacChienluocItemsEditSection />
						<Label>Chia sẻ của học sinh - Tiêu đề</Label>
						<HomepageTieudeLangngheChiaseHocsinh_EditSection />
						<Label>Chia sẻ của học sinh - Nội dung</Label>
						<HomepageLangngheChiaseHocsinhItemsEditSection />
						<Label>Đăng ký để lại Email</Label>
						<HomepageDkEmail_EditSection />
						<Label>Tiêu đề tin tức sự kiện</Label>
						<HomepageTieudeTintucSukien_EditSection />
					</Box>
				</BodySingleBlock>
			) : (
				<></>
			)}
		</>
	);
};
