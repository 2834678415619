import { viVNTranslate } from './vi-VN/viVN-translate';
import { enUSTranslate } from './en-US/enUS-translate';

export default {
	'vi-VN': {
		translation: viVNTranslate,
	},
	'en-US': {
		translation: enUSTranslate,
	},
};
