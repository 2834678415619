import { v4 } from 'uuid';

export interface DvPageTieudeDieukienDedang {
	ID: string;
	title: string;
	subtitle: string;
}
export const dvPageTieudeDieukienDedangDefaultValue: DvPageTieudeDieukienDedang = {
	subtitle: 'Du học nghề Đức',
	title: 'Với điều kiện dễ dàng',
	ID: v4(),
};
