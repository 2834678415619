import { v4 } from 'uuid';
import { OverallInformationJoditContent } from './overallInfoJoditContent.defaultValue';

export interface FooterAddressInformation {
	ID: string;
	title: string;
	content: OverallInformationJoditContent;
}
export const footerAddressInformationDefaultValue: FooterAddressInformation = {
	title: 'Địa chỉ',
	content: {
		content:
			'Add: 599 Hoàng Sa, Phường Võ Thị Sáu, Quận 3, TP. Hồ Chí Minh, Việt Nam. Điện thoại: 090 6060 279 Email: info@dinhnguyengia.edu.vn Website: dinhnguyengia.edu.vn',
		images: [],
	},
	ID: v4(),
};
