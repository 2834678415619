import { TableWithSelection_Body } from './TableWithSelection_Body';
import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import * as React from 'react';
import { useEffect } from 'react';
import { HeadCell } from '../../../models/interface/tableUI.interface';
import { useDispatch, useSelector } from 'react-redux';
import { set_table_search_key, set_table_search_term } from '../../../store/reducers/table_UI.reducer';
import { AppState } from '../../../store/core.store';
import { getI18n } from 'react-i18next';
import { mTrans } from '../../../utils/i18n/locales/masterTranslateData';

interface TableWithSelectionProps {
	headCells: HeadCell[];
	tableData: any[];
	rowMainKey: string;
	deleteRowApiUrlSuffix?: string;
	afterDeleteRowFn?: () => void;
	refresh_table_fn: () => void;
	row_click_action?: (value: any) => void;
	table_title: string;
}

export const TableWithSelection = (props: TableWithSelectionProps) => {
	const dispatcher = useDispatch();

	const searchTerm = useSelector((e: AppState) => e.table_UI.search_term);

	useEffect(() => {
		const data = [...props.headCells.filter((item) => item.filter)];
		if (data.length > 0) {
			dispatcher(set_table_search_term(data[0].id));
		}
	}, [props.headCells]);

	const itemArr = props.headCells.filter((item) => item.filter);
	const item_list = () => {
		if (itemArr.length > 0) {
			return itemArr.map((item) => (
				<MenuItem key={item.id} value={item.id}>
					{getI18n().t(item.label)}
				</MenuItem>
			));
		}
		return undefined;
	};

	const SearchBar = () => {
		return (
			<>
				<Box className='flex gap-4 justify-end'>
					<Box>
						<TextField
							onChange={(e) => {
								dispatcher(set_table_search_key(e.target.value));
							}}
							label={getI18n().t(mTrans['Key words'])}
						/>
					</Box>
					<Box>
						<FormControl fullWidth>
							<InputLabel>{getI18n().t(mTrans['Search term'])}</InputLabel>
							<Select
								value={item_list() && searchTerm && itemArr.find((item) => item.id === searchTerm) ? searchTerm : ''}
								label={getI18n().t(mTrans['Search term'])}
								onChange={(e) => {
									dispatcher(set_table_search_term(e.target.value));
								}}
							>
								{item_list()}
							</Select>
						</FormControl>
					</Box>
				</Box>
			</>
		);
	};

	return (
		<>
			<Box className='flex flex-col gap-8'>
				<Box>
					<SearchBar />
				</Box>
				<Box>
					<TableWithSelection_Body {...props} />
				</Box>
			</Box>
		</>
	);
};
