export const mTrans = {
	'Edit Footer': 'Edit Footer',
	'Edit service page': 'Edit service page',
	Overall: 'Overall',
	'You are editing post #': 'You are editing post #',
	'Select category': 'Select category',
	'Selected post(s)': 'Selected post(s)',
	'Move posts to other category': 'Move posts to other category',
	Excerpt: 'Excerpt',
	'Start writing': 'Start writing',
	'Post content is now allowed to be empty': 'Post content is now allowed to be empty',
	'You must upload a thumbnail for this post': 'You must upload a thumbnail for this post',
	'Are you sure you want to continue?': 'Are you sure you want to continue?',
	Title: 'Title',
	'Delete block': 'Delete block',
	'Add block': 'Add block',
	'Updated successfully': 'Updated successfully',
	'Post saved': 'Post saved',
	Link: 'Link',
	Priority: 'Priority',
	'Menu name': 'Menu name',
	Home: 'Home',
	'Key words': 'Key words',
	'Search term': 'Search term',
	'Manage categories': 'Manage categories',
	'Add image': 'Add image',
	'Add new category': 'Add new category',
	'Add new post': 'Add new post',
	'Admin list': 'Admin list',
	'Auto url': 'Auto url',
	'Category List': 'Category List',
	'Category Name': 'Category Name',
	'Change image': 'Change image',
	'Choose one': 'Choose one',
	'Delete all trash media': 'Delete all trash media',
	'Edit landing page': 'Edit landing page',
	'Edit post': 'Edit post',
	'Login with': 'Login with',
	'Manage blog': 'Manage blog',
	'New admin email': 'New admin email',
	'Overall information': 'Overall information',
	'Rows per page': 'Rows per page',
	'Sign in': 'Sign in',
	'Sign out': 'Sign out',
	'Transfer posts between categories': 'Transfer posts between categories',
	'Transfer posts to category': 'Transfer posts to category',
	'Transfer posts': 'Transfer posts',
	'trash media': 'trash media',
	Category: 'Category',
	Close: 'Close',
	Confirm: 'Confirm',
	Content: 'Content',
	From: 'From',
	Loading: 'Loading',
	Manage: 'Manage',
	Name: 'Name',
	Narrow: 'Narrow',
	Order: 'Order',
	Save: 'Save',
	Thumbnail: 'Thumbnail',
	To: 'To',
	posts: 'posts',
	'Edit lesson page': 'Edit lesson page',
	'Homepage posts': 'Homepage posts',
	'Service page posts': 'Service page posts',
};
