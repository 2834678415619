import { v4 } from 'uuid';

export interface LessonPageTieudeNenchonTiengducDNGEdu {
	ID: string;
	title: string;
	subtitle: string;
}

export const lessonPageTieudeNenchonTiengducDngEduDefaultValue: LessonPageTieudeNenchonTiengducDNGEdu = {
	subtitle: 'VÌ SAO BẠN NÊN CHỌN',
	title: 'KHÓA HỌC TIẾNG ĐỨC TẠI DNG EDUCATION',
	ID: v4(),
};
