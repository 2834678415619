import { favIconBlockDefaultVal, FavIconBlockInfo } from './favIconInfo.defaultValue';
import { homepageBannerBlockDefaultVal, HomepageBannerContentType } from './homepageBannerBlock.defaultValue';
import { HomepageTongQuanVeDNG, homepageTongQuanVeDNGDefaultVal } from './homepage_TongQuanVeDNG.defaultValue';
import { TamnhinsumenhgiatriContent, tamnhinSumenhGiatriDefaultVal } from './tamnhinsumenhgiatri.defaultValue';
import { TieudeDichvuTuvan, tieudeDichvuTuvanDefaultValue } from './tieudeDichvuTuvan.defaultValue';
import {
	TieuDeTruonghocDoitacChienluoc,
	tieuDeTruonghocDoitacChienluocDefaultValue,
} from './tieuDeTruonghocDoitacChienluoc.defaultValue';
import { DkEmailTrangChu, dkEmailTrangChuDefaultValue } from './dkEmailTrangChu.defaultValue';
import {
	HomepageDichvuTuvanContent,
	homepageDichvuTuvanContentDefaultVal,
} from './homepageDichvuTuvanContent.defaultValue';
import { LotrinhDuhocNgheDuc, lotrinhDuhocNgheDucDefaultValue } from './lotrinhDuhocNgheDuc.defaultValue';
import {
	langngheChiaseHocsinhDNGDefaultVal,
	LangngheChiaseHocsinhDNGItemContent,
} from './langngheChiaseHocsinhDNGItems.defaultValue';
import {
	TieudeLangngheChiaseHocsinhDNG,
	tieuDelangngheChiaseHocsinhDNGDefaultValue,
} from './tieuDelangngheChiaseHocsinhDNG.defaultValue';
import {
	TruonghocDoitacChienluocItem,
	truonghocDoitacChienluocItemsDefaultVal,
} from './truonghocDoitacChienluocItems.defaultValue';
import { TieudeTintucSukienTrangchu } from './tieudeTintucSukienTrangchu.defaultValue';
import { MainNavMenuContent, mainNavMenuContentDefaultVal } from './mainNavMenu.defaultValue';
import { DvPageTopBanner, dvPageTopBannerDefaultVal } from './dvPageTopBanner.defaultValue';
import {
	DvPageTieudeLoiichVuottroi,
	dvPageTieudeLoiichVuottroiDefaultValue,
} from './dvPageTieudeLoiichVuottroi.defaultValue';
import { DvPageLoiichVuottroi, dvPageLoiichVuottroiItemsDefaultVal } from './dvPageLoiichVuottroiItems.defaultValue';
import {
	DvPageTieudeDieukienDedang,
	dvPageTieudeDieukienDedangDefaultValue,
} from './dvPageTieudeDieukienDedang.defaultValue';
import {
	DvPageDieukienDedangContent,
	dvPageDieukienDedangItemsDefaultVal,
} from './dvPageDieukienDedangItems.defaultValue';
import {
	DvPageTieudeNenchonDngEdu,
	dvPageTieudeNenChonDngEduDefaultValue,
} from './dvPageTieudeNenchonDNGEdu.defaultValue';
import { DvPageNenchonDngContent, dvPageNenchonDngContentItemsDefaultVal } from './dvPageNenChonDngItems.defaultValue';
import {
	DvPageTieudeTintucNoibat,
	dvPageTieudeTintucNoibatDefaultValue,
} from './dvPageTieudeTintucNoibat.defaultValue';
import {
	HomepageBlogPostCategoryNumber,
	homepageBlogPostCategoryNumberDefaultValue,
} from './homepageBlogPostCategoryNumber.initValue';
import {
	FooterTieudeFormTimhieutiengDuc,
	footerTieudeFormTimhieutiengDucDefaultValue,
} from './footerTieudeFormTimhieutiengDuc.defaultValue';
import {
	FooterTongquanveCongtyDNG,
	footerTongquanveCongtyDNGDefaultValue,
} from './footerTongquanveCongtyDNG.defaultValue';
import {
	FooterAddressInformation,
	footerAddressInformationDefaultValue,
} from './footerAddressInformation.defaultValue';
import { FooterTieudeDichvu, footerTieudeDichvuDefaulValue } from './footerTieudeDichvu.defaultValue';
import { FooterDichVuItem, footerDichVuItemsDefaultValue } from './footerDichVuItems.defaultValue';
import { FooterSocialMediaItem, footerSocialMediaItemsDefaultValue } from './footerSocialMediaItems.defaultValue';
import {
	LessonPageCackhoahocTiengducItem,
	lessonPageCackhoahocTiengducItemsDefaultValue,
} from './lessonPageCackhoahocTiengducItems.defaultValue';
import {
	LessonPageTieudeCackhoahocTiengduc,
	lessonPageTieudeCackhoahocTiengducDefaultValue,
} from './lessonPageTieudeCackhoahocTiengduc.defaultValue';
import {
	LessonPageNenchonTiengducDNGEduItem,
	lessonPageNenchonTiengducDNGEduItemsDefaultValue,
} from './lessonPageNenchonTiengducDNGEduItems.defaultValue';
import {
	LessonPageTieudeNenchonTiengducDNGEdu,
	lessonPageTieudeNenchonTiengducDngEduDefaultValue,
} from './lessonPageTieudeNenchonTiengducDNGEdu.defaultValue';
import {
	lessonPageTiengDucdanhchoban,
	LessonPageTiengDucdanhchoban,
} from './lessonPageTiengDucdanhchoban.defaultValue';
import { PageMainLogo, pageMainLogoDefaultValue } from './pageMainLogo.defaultValue';
import { FooterPageLogo, footerPageLogoDefaultValue } from './footerPageLogo.defaultValue';
import {
	DvPageBlogPostCategoryNumber,
	dvPageBlogPostCategoryNumberDefaultValue,
} from './dvPageBlogPostCategoryNumber.initValue';

export enum OverallInfoPositions {
	anywhere = 'anywhere',
	landingpage = 'landingpage',
	servicepage = 'servicepage',
	mainfooter = 'mainfooter',
	lessonpage = 'lessonpage',
}

export enum OverallInfoItemNameList {
	favicon = 'favicon',
	homePageBannerBlock = 'homePageBannerBlock',
	homepage_TongQuanVeDNG = 'homepage_TongQuanVeDNG',
	homepage_TamnhinSumenhGiaTri = 'homepage_TamnhinSumenhGiaTri',
	homepage_TieudeDichvuTuvan = 'homepage_TieudeDichvuTuvan',
	homepage_dichvuTuvanItems = 'homepage_dichvuTuvanItems',
	lotrinhDuhocngheDuc = 'lotrinhDuhocngheDuc',
	tieuDeTruonghocDoitacChienluoc = 'tieuDeTruonghocDoitacChienluoc',
	truonghocDoitacChienluocItems = 'truonghocDoitacChienluocItems',
	tieudeLangngheChiaseHocsinhDng = 'tieudeLangngheChiaseHocsinhDng',
	langngheChiaseHocsinhDNGItems = 'langngheChiaseHocsinhDNGItems',
	dkEmailTrangChu = 'dkEmailTrangChu',
	tieudeTintucSukienTrangChu = 'tieudeTintucSukienTrangChu',
	mainNavMenuItems = 'mainNavMenuItems',
	dvPageTopBanner = 'dvPageTopBanner',
	dvPageTieudeLoiichVuottroi = 'dvPageTieudeLoiichVuottroi',
	dvPageLoiichVuottroiItems = 'dvPageLoiichVuottroiItems',
	dvPageTieudeDieukienDedang = 'dvPageTieudeDieukienDedang',
	dvPageDieukienDedangItems = 'dvPageDieukienDedangItems',
	dvPageTieudeNenchonDngEdu = 'dvPageTieudeNenchonDngEdu',
	dvPageNenChonDngItems = 'dvPageNenChonDngItems',
	dvPageTieudeTintucNoibat = 'dvPageTieudeTintucNoibat',
	dvPageBlogPostCategoryNumber = 'dvPageBlogPostCategoryNumber',
	homepageBlogPostCategoryNumber = 'homepageBlogPostCategoryNumberDefaultValue',
	footerTieudeFormTimhieutiengDuc = 'footerTieudeFormTimhieutiengDuc',
	footerTongquanveCongtyDNG = 'footerTongquanveCongtyDNG',
	footerAddressInformation = 'footerAddressInformation',
	footerTieudeDichvu = 'footerTieudeDichvu',
	footerDichVuItems = 'footerDichVuItems',
	footerSocialMediaItems = 'footerSocialMediaItems',
	lessonPageTiengDucdanhchoban = 'lessonPageTiengDucdanhchoban',
	lessonPageTieudeNenchonTiengducDNGEdu = 'lessonPageTieudeNenchonTiengducDNGEdu',
	lessonPageNenchonTiengducDNGEduItems = 'lessonPageNenchonTiengducDNGEduItems',
	lessonPageTieudeCackhoahocTiengduc = 'lessonPageTieudeCackhoahocTiengduc',
	lessonPageCackhoahocTiengducItems = 'lessonPageCackhoahocTiengducItems',
	pageMainLogo = 'pageMainLogo',
	footerPageLogo = 'footerPageLogo',
}

export type SingleLangOverallInfoDetail = {
	[OverallInfoItemNameList.favicon]: FavIconBlockInfo;
	[OverallInfoItemNameList.homePageBannerBlock]: HomepageBannerContentType[];
	[OverallInfoItemNameList.homepage_TongQuanVeDNG]: HomepageTongQuanVeDNG;
	[OverallInfoItemNameList.homepage_TamnhinSumenhGiaTri]: TamnhinsumenhgiatriContent;
	[OverallInfoItemNameList.homepage_TieudeDichvuTuvan]: TieudeDichvuTuvan;
	[OverallInfoItemNameList.homepage_dichvuTuvanItems]: HomepageDichvuTuvanContent[];
	[OverallInfoItemNameList.lotrinhDuhocngheDuc]: LotrinhDuhocNgheDuc;
	[OverallInfoItemNameList.tieuDeTruonghocDoitacChienluoc]: TieuDeTruonghocDoitacChienluoc;
	[OverallInfoItemNameList.truonghocDoitacChienluocItems]: TruonghocDoitacChienluocItem[];
	[OverallInfoItemNameList.tieudeLangngheChiaseHocsinhDng]: TieudeLangngheChiaseHocsinhDNG;
	[OverallInfoItemNameList.langngheChiaseHocsinhDNGItems]: LangngheChiaseHocsinhDNGItemContent[];
	[OverallInfoItemNameList.dkEmailTrangChu]: DkEmailTrangChu;
	[OverallInfoItemNameList.tieudeTintucSukienTrangChu]: TieudeTintucSukienTrangchu;
	[OverallInfoItemNameList.mainNavMenuItems]: MainNavMenuContent[];
	[OverallInfoItemNameList.dvPageTopBanner]: DvPageTopBanner[];
	[OverallInfoItemNameList.dvPageTieudeLoiichVuottroi]: DvPageTieudeLoiichVuottroi;
	[OverallInfoItemNameList.dvPageLoiichVuottroiItems]: DvPageLoiichVuottroi[];
	[OverallInfoItemNameList.dvPageTieudeDieukienDedang]: DvPageTieudeDieukienDedang;
	[OverallInfoItemNameList.dvPageDieukienDedangItems]: DvPageDieukienDedangContent[];
	[OverallInfoItemNameList.dvPageTieudeNenchonDngEdu]: DvPageTieudeNenchonDngEdu;
	[OverallInfoItemNameList.dvPageNenChonDngItems]: DvPageNenchonDngContent[];
	[OverallInfoItemNameList.dvPageTieudeTintucNoibat]: DvPageTieudeTintucNoibat;
	[OverallInfoItemNameList.dvPageBlogPostCategoryNumber]: DvPageBlogPostCategoryNumber;
	[OverallInfoItemNameList.homepageBlogPostCategoryNumber]: HomepageBlogPostCategoryNumber;
	[OverallInfoItemNameList.footerTieudeFormTimhieutiengDuc]: FooterTieudeFormTimhieutiengDuc;
	[OverallInfoItemNameList.footerTongquanveCongtyDNG]: FooterTongquanveCongtyDNG;
	[OverallInfoItemNameList.footerAddressInformation]: FooterAddressInformation;
	[OverallInfoItemNameList.footerTieudeDichvu]: FooterTieudeDichvu;
	[OverallInfoItemNameList.footerDichVuItems]: FooterDichVuItem[];
	[OverallInfoItemNameList.footerSocialMediaItems]: FooterSocialMediaItem[];
	[OverallInfoItemNameList.lessonPageCackhoahocTiengducItems]: LessonPageCackhoahocTiengducItem[];
	[OverallInfoItemNameList.lessonPageTieudeCackhoahocTiengduc]: LessonPageTieudeCackhoahocTiengduc;
	[OverallInfoItemNameList.lessonPageNenchonTiengducDNGEduItems]: LessonPageNenchonTiengducDNGEduItem[];
	[OverallInfoItemNameList.lessonPageTieudeNenchonTiengducDNGEdu]: LessonPageTieudeNenchonTiengducDNGEdu;
	[OverallInfoItemNameList.lessonPageTiengDucdanhchoban]: LessonPageTiengDucdanhchoban;
	[OverallInfoItemNameList.pageMainLogo]: PageMainLogo;
	[OverallInfoItemNameList.footerPageLogo]: FooterPageLogo;
};

export type ConnectDetailsAndNameList<T extends OverallInfoItemNameList> = {
	// On error 'Type 'key' cannot be used to index type 'SingleLangOverallInfoDetail' please check if SingleLangOverallInfoDetail has all properties of OverallInfoItemNameList
	[key in T]: SingleLangOverallInfoDetail[key];
};

export const single_lang_overall_info_detail_init_value: ConnectDetailsAndNameList<OverallInfoItemNameList> = {
	footerPageLogo: footerPageLogoDefaultValue,
	pageMainLogo: pageMainLogoDefaultValue,
	lessonPageCackhoahocTiengducItems: lessonPageCackhoahocTiengducItemsDefaultValue,
	lessonPageNenchonTiengducDNGEduItems: lessonPageNenchonTiengducDNGEduItemsDefaultValue,
	lessonPageTiengDucdanhchoban: lessonPageTiengDucdanhchoban,
	lessonPageTieudeCackhoahocTiengduc: lessonPageTieudeCackhoahocTiengducDefaultValue,
	lessonPageTieudeNenchonTiengducDNGEdu: lessonPageTieudeNenchonTiengducDngEduDefaultValue,
	footerAddressInformation: footerAddressInformationDefaultValue,
	footerDichVuItems: footerDichVuItemsDefaultValue,
	footerSocialMediaItems: footerSocialMediaItemsDefaultValue,
	footerTieudeDichvu: footerTieudeDichvuDefaulValue,
	footerTieudeFormTimhieutiengDuc: footerTieudeFormTimhieutiengDucDefaultValue,
	footerTongquanveCongtyDNG: footerTongquanveCongtyDNGDefaultValue,
	homepageBlogPostCategoryNumberDefaultValue: homepageBlogPostCategoryNumberDefaultValue,
	dvPageDieukienDedangItems: dvPageDieukienDedangItemsDefaultVal,
	dvPageLoiichVuottroiItems: dvPageLoiichVuottroiItemsDefaultVal,
	dvPageNenChonDngItems: dvPageNenchonDngContentItemsDefaultVal,
	dvPageTieudeDieukienDedang: dvPageTieudeDieukienDedangDefaultValue,
	dvPageTieudeLoiichVuottroi: dvPageTieudeLoiichVuottroiDefaultValue,
	dvPageTieudeNenchonDngEdu: dvPageTieudeNenChonDngEduDefaultValue,
	dvPageTieudeTintucNoibat: dvPageTieudeTintucNoibatDefaultValue,
	dvPageTopBanner: dvPageTopBannerDefaultVal,
	tieuDeTruonghocDoitacChienluoc: tieuDeTruonghocDoitacChienluocDefaultValue,
	dkEmailTrangChu: dkEmailTrangChuDefaultValue,
	homepage_dichvuTuvanItems: homepageDichvuTuvanContentDefaultVal,
	lotrinhDuhocngheDuc: lotrinhDuhocNgheDucDefaultValue,
	langngheChiaseHocsinhDNGItems: langngheChiaseHocsinhDNGDefaultVal,
	tieudeLangngheChiaseHocsinhDng: tieuDelangngheChiaseHocsinhDNGDefaultValue,
	tieudeTintucSukienTrangChu: tieudeDichvuTuvanDefaultValue,
	truonghocDoitacChienluocItems: truonghocDoitacChienluocItemsDefaultVal,
	homepage_TamnhinSumenhGiaTri: tamnhinSumenhGiatriDefaultVal,
	favicon: favIconBlockDefaultVal,
	homePageBannerBlock: homepageBannerBlockDefaultVal,
	homepage_TongQuanVeDNG: homepageTongQuanVeDNGDefaultVal,
	homepage_TieudeDichvuTuvan: tieudeDichvuTuvanDefaultValue,
	mainNavMenuItems: mainNavMenuContentDefaultVal,
	dvPageBlogPostCategoryNumber: dvPageBlogPostCategoryNumberDefaultValue,
};
