import { BodySingleBlock } from '../components/Layouts/BodySingleBlock';
import { AppCoreLanguages, appDefaultLanguage } from '../store/reducers/app_core_state.reducer';
import {
	OverallInfoItemNameList,
	OverallInfoPositions,
} from '../store/reducers/app_overall_info/valueInitialization/app_overall_info_init_value';
import { QueryResult, useQuery } from '@apollo/client';
import { gql_queries } from '../models/interface/gql_queries';
import { AppOverallInfoState } from '../store/reducers/app_overall_info/app_overall_info.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../store/core.store';
import React, { useEffect } from 'react';
import { blogPostAction } from '../store/reducers/blog_post_info.reducer';
import { Box } from '@mui/material';
import { CategorySelector } from './CategoryManager';
import { api_post_data } from '../environments/api_handler';
import { PrimaryBlackButton } from '../components/utils/Button';

export const SelectServicePagePostCat = (props: { lang: AppCoreLanguages }) => {
	const itemName = OverallInfoItemNameList.dvPageBlogPostCategoryNumber;
	const itemPosition = OverallInfoPositions.servicepage;
	const category_query = useQuery(gql_queries.all_blog_post_categories, {
		fetchPolicy: 'cache-and-network',
	});
	const homepageQuery: QueryResult<
		{
			getOverallInformationForAdminToEdit: AppOverallInfoState;
		},
		any
	> = useQuery(gql_queries.getOverallInformationForAdminToEdit, {
		variables: {
			data: {
				positions: [itemPosition],
			},
		},
	});
	const blogPostCats = useSelector((e: AppState) => e.blog_post.details.category);
	const dispatcher = useDispatch();

	useEffect(() => {
		if (homepageQuery.data) {
			const cat = homepageQuery.data.getOverallInformationForAdminToEdit[props.lang][itemName].category;

			dispatcher(
				blogPostAction.set_blog_post_info({
					category: cat,
				}),
			);
		}
	}, [homepageQuery.data]);

	return (
		<Box className='grid grid-cols-1 gap-8'>
			<CategorySelector category_query={category_query} />
			<Box className='text-end'>
				<PrimaryBlackButton
					onClick={async () => {
						return await api_post_data('admin_overall_info/save_overall_info_item', {
							info_name: itemName,
							data: {
								[itemName]: {
									category: blogPostCats,
								},
							},
							locale: props.lang,
							dataPosition: itemPosition,
							images: [],
						});
					}}
				>
					OK!
				</PrimaryBlackButton>
			</Box>
		</Box>
	);
};

export function SelectServicePagePostCategory() {
	return (
		<BodySingleBlock>
			<SelectServicePagePostCat lang={appDefaultLanguage.locale} />
		</BodySingleBlock>
	);
}
