import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../store/core.store';
import { Box } from '@mui/material';
import { useQuery } from '@apollo/client';
import { gql_queries } from '../models/interface/gql_queries';
import { blogPostAction, BlogPostStateDetails, LangJoditContent } from '../store/reducers/blog_post_info.reducer';
import { useParams } from 'react-router-dom';
import { AppCoreLanguages, LanguagesMap } from '../store/reducers/app_core_state.reducer';
import { api_post_data } from '../environments/api_handler';
import { BlogPostCreator, Post_UrlSlug, PostAvatar } from './AdminCreateBlogPost';
import { LimeAlert } from '../components/utils/Alert';
import { breadCrumbActions } from '../store/reducers/breadcrumb.reducer';
import { CategoryBox } from './CategoryManager';
import { BlogPostEntity } from '../models/apiGqlExportTypes';
import { appToolsActions } from '../store/reducers/appTools.reducer';
import { PrimaryGreenButton } from '../components/utils/Button';
import { BodySingleBlock } from '../components/Layouts/BodySingleBlock';
import { BlackProgress } from '../components/utils/Progress';
import { getI18n } from 'react-i18next';
import { mTrans } from '../utils/i18n/locales/masterTranslateData';

const EditBlogPost = () => {
	const params = useParams();
	const dispatcher = useDispatch();
	const [postLoaded, setPostLoaded] = useState(false);
	const app_is_loading = useSelector((e: AppState) => e.appTools.is_loading);

	const getPostByIdQuery = useQuery(gql_queries.getBlogPostByIdForAdminToEdit, {
		variables: {
			data: {
				ID: params.post_ID ? parseFloat(params.post_ID) : 0,
			},
		},
		fetchPolicy: 'no-cache',
	});

	useEffect(() => {
		if (getPostByIdQuery.loading) {
			setPostLoaded(false);
		} else {
			setPostLoaded(true);
		}
	}, [getPostByIdQuery.loading]);

	useEffect(() => {
		const rawData: BlogPostEntity = getPostByIdQuery.data?.getBlogPostByIdForAdminToEdit;
		if (!rawData) return;
		const dispatchData: BlogPostStateDetails = {
			ID: rawData.ID,
			urlSlug: rawData.urlSlug,
			category: rawData.category.map((item) => item.ID),
			avatar: rawData.avatar.path,
			content: {
				[AppCoreLanguages.viVN]: rawData.content.viVN,
				// [AppCoreLanguages.enUS]: rawData.content.enUS,
			},
			images: {
				[AppCoreLanguages.viVN]: [],
				// [AppCoreLanguages.enUS]: [],
			},
		};
		dispatcher(blogPostAction.set_blog_post_info(dispatchData));
		dispatcher(
			breadCrumbActions.set_breadcrumb_info({
				isDynamic: true,
				dynamic_pathname: rawData.ID.toString(),
			}),
		);

		const mappedJoditContent: LangJoditContent = {
			[AppCoreLanguages.viVN]: {
				locale: AppCoreLanguages.viVN,
				content: rawData.content.viVN.content,
			},
			// [AppCoreLanguages.enUS]: {
			// 	locale: AppCoreLanguages.enUS,
			// 	content: rawData.content.enUS.content,
			// },
		};

		dispatcher(
			blogPostAction.set_blog_post_state({
				langJoditContent: mappedJoditContent,
			}),
		);

		return () => {
			dispatcher(
				breadCrumbActions.set_breadcrumb_info({
					isDynamic: false,
				}),
			);
		};
	}, [getPostByIdQuery]);

	useEffect(() => {
		dispatcher(blogPostAction.reset_blog_post_state());
	}, [params]);

	const blog_post_info = useSelector((e: AppState) => e.blog_post);

	return (
		<BodySingleBlock>
			{postLoaded ? (
				<>
					<Box className='flex flex-col gap-8'>
						<span className='text-[32px] font-bold'>{getI18n().t(mTrans['Edit post'])}</span>
						{getPostByIdQuery.data ? (
							<LimeAlert className='mb-16'>
								{getI18n().t(mTrans['You are editing post #'])} {blog_post_info.details.ID}
							</LimeAlert>
						) : (
							<></>
						)}
						<PostAvatar />
						<Box className='flex justify-end gap-4'>
							<CategoryBox />
						</Box>
						<Post_UrlSlug />
						{Object.values(AppCoreLanguages).map((lang, i) => (
							<Fragment key={i}>
								<div className='text-2xl font-bold'>
									{getI18n().t(mTrans.Content)} [{lang}]
								</div>
								<BlogPostCreator lang={LanguagesMap[lang]} />
							</Fragment>
						))}
						<Box className='text-end'>
							<Box hidden={!app_is_loading}>
								<BlackProgress />
							</Box>
							<PrimaryGreenButton
								hidden={app_is_loading}
								onClick={async () => {
									dispatcher(
										blogPostAction.set_blog_post_state({
											touched: true,
										}),
									);
									dispatcher(appToolsActions.set_apptools_info({ is_loading: true }));
									const result = await api_post_data('admin/edit_single_blog_post', blog_post_info.details).catch(
										() => {
											dispatcher(appToolsActions.set_apptools_info({ is_loading: false }));
											return null;
										},
									);
									if (result?.status === 201) {
										dispatcher(
											appToolsActions.set_apptools_info({
												is_loading: false,
											}),
										);
									}
									return;
								}}
							>
								{getI18n().t(mTrans.Save)}
							</PrimaryGreenButton>
						</Box>
					</Box>
				</>
			) : (
				<>
					<Box className='flex flex-col items-center gap-8'>
						<BlackProgress />
						<span className='font-semibold'>{getI18n().t(mTrans.Loading)}...</span>
					</Box>
				</>
			)}
		</BodySingleBlock>
	);
};

export default EditBlogPost;
