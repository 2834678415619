import { v4 } from 'uuid';
import { OverallInformationJoditContent } from './overallInfoJoditContent.defaultValue';

export interface HomepageTongQuanVeDNG {
	ID: string;
	subtitle: string;
	title: string;
	content: OverallInformationJoditContent;
	image: string;
}

export const homepageTongQuanVeDNGDefaultVal: HomepageTongQuanVeDNG = {
	ID: v4(),
	subtitle: 'TỔNG QUAN VỀ',
	title: 'DNG EDUCATION',
	content: {
		content: 'Cá nhân hóa các hoạt động cố vấn và hỗ trợ cho học sinh...',
		images: [],
	},
	image: 'defaultImage.webp',
};
