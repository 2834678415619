import { v4 } from 'uuid';

export interface TieudeDichvuTuvan {
	ID: string;
	title: string;
	subtitle: string;
}
export const tieudeDichvuTuvanDefaultValue: TieudeDichvuTuvan = {
	subtitle: 'Các dịch vụ tư vấn tại',
	title: 'DNG EDUCATION',
	ID: v4(),
};
