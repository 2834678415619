import { AppCoreLanguages } from '../../store/reducers/app_core_state.reducer';
import {
	OverallInfoItemNameList,
	OverallInfoPositions,
} from '../../store/reducers/app_overall_info/valueInitialization/app_overall_info_init_value';
import {
	OverallInfo__ItemSaveButton,
	OverallInfo__NonArray__ItemImageUpload,
	OverallInfo__NonArray__ItemJoditEditor,
	OverallInfo__NonArray__ItemTextEditor,
} from '../OverallInformation/OverallInfoItemTextEditor';
import { Fragment } from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/core.store';

const TitleSaveButton = (props: { lang: AppCoreLanguages }) => {
	const itemName = OverallInfoItemNameList.lessonPageTiengDucdanhchoban;
	const dataPosition = OverallInfoPositions.lessonpage;
	const blockInfo = useSelector((e: AppState) => e.app_overall_info[props.lang][itemName]);

	return (
		<OverallInfo__ItemSaveButton
			lang={props.lang}
			itemName={itemName}
			dataPosition={dataPosition}
			imgArr={[blockInfo.image]}
		/>
	);
};

export const LessonPageTiengDucdanhchoban_EditSection = () => {
	const itemName = OverallInfoItemNameList.lessonPageTiengDucdanhchoban;

	return (
		<>
			{Object.values(AppCoreLanguages).map((language, i) => (
				<Fragment key={i}>
					<Box className='grid grid-cols-1 gap-8'>
						<Box className='flex justify-center'>
							<OverallInfo__NonArray__ItemImageUpload
								lang={language}
								itemName={itemName}
								valueKey='image'
								className='w-[150px] aspect-1'
							/>
						</Box>
						<OverallInfo__NonArray__ItemTextEditor
							textFieldProps={{
								label: 'Tiêu đề phụ',
							}}
							itemName={itemName}
							valueKey='subtitle'
							lang={language}
						/>
						<OverallInfo__NonArray__ItemTextEditor
							textFieldProps={{
								label: 'Tiêu đề chính',
							}}
							itemName={itemName}
							valueKey='title'
							lang={language}
						/>
						<OverallInfo__NonArray__ItemJoditEditor itemName={itemName} valueKey='content' lang={language} />
						<OverallInfo__NonArray__ItemTextEditor
							textFieldProps={{
								label: 'Button text',
							}}
							itemName={itemName}
							valueKey='btnText'
							lang={language}
						/>
						<OverallInfo__NonArray__ItemTextEditor
							textFieldProps={{
								label: 'Button url',
							}}
							itemName={itemName}
							valueKey='btnUrl'
							lang={language}
						/>
						<Box className='text-end'>
							<TitleSaveButton lang={language} />
						</Box>
					</Box>
				</Fragment>
			))}
		</>
	);
};
