import { v4 } from 'uuid';

export interface LessonPageCackhoahocTiengducItem {
	ID: string;
	image: string;
	link: string;
	order: string;
}

export const lessonPageCackhoahocTiengducItem: LessonPageCackhoahocTiengducItem = {
	ID: v4(),
	image: 'defaultImage.webp',
	link: 'https://www.google.com',
	order: '1',
};

export const lessonPageCackhoahocTiengducItemsDefaultValue: LessonPageCackhoahocTiengducItem[] = [
	{ ...lessonPageCackhoahocTiengducItem, ID: v4() },
	{ ...lessonPageCackhoahocTiengducItem, ID: v4() },
	{ ...lessonPageCackhoahocTiengducItem, ID: v4() },
	{ ...lessonPageCackhoahocTiengducItem, ID: v4() },
];
