import { gql } from '@apollo/client';
import { AppCoreLanguages, appDefaultLanguage } from '../../store/reducers/app_core_state.reducer';

export const gql_queries = {
	user_info: gql(`
    query {
      user_info {
        ID,
        email,
        role
      }
    }
  `),
	all_users: gql(`
    query {
      all_users {
        ID,
        email,
        role
      }
    }
  `),
	all_post_to_manage: gql(`
    query {
      all_blog_posts_for_management {
        ID,
        content {
          ${appDefaultLanguage.abbr} {
            title
          },
        },
        date
      }
    }
  `),
	getBlogPostByIdForAdminToEdit: gql(`
    query ($data: BlogPostQueryByIdInput!) {
      getBlogPostByIdForAdminToEdit(data: $data) {
        ID,
        date,
        urlSlug,
        category {
          ID,
          name,
          order
        },
        content {
          ${Object.keys(AppCoreLanguages).map(
						(item) => `
              ${item} {
                ID,
                title,
                content,
                excerpt
              }
            `,
					)}
        },
        avatar {
          name,
          path
        }
      }
    }
  `),
	all_blog_post_categories: gql(`
    query {
      all_blog_post_categories {
        ID,
        name,
        order
      }
    }
  `),
	all_admin_list: gql(`
    query {
      all_admin_list {
        ID,
        email
      }
    }
  `),
	allTrashMedias: gql(`
    query {
      allTrashMedias {
        ID,
        name,
        path
      }
    }
  `),
	getOverallInformationForAdminToEdit: gql(`
    query ($data: QueryOverallInfomationInput!) {
      getOverallInformationForAdminToEdit(data: $data)
    }
  `),
};
