import { v4 } from 'uuid';

export interface DvPageLoiichVuottroi {
	ID: string;
	title: string;
	content: string;
	image: string;
	order: string;
}

export const dvPageLoiichVuottroiSingleItem: DvPageLoiichVuottroi = {
	ID: v4(),
	title: 'MIỄN 100% HỌC PHÍ',
	image: 'defaultImage.webp',
	content:
		'Du học nghề tại Đức đang hút nhiều sinh viên quốc tế, đặc biệt là các bạn trẻ Việt Nam bởi chính sách hỗ trợ vượt bậc của chính phủ Đức, trước hết là học phí 0đ trong suốt chương trình học.',
	order: '1',
};

export const dvPageLoiichVuottroiItemsDefaultVal: DvPageLoiichVuottroi[] = [
	{ ...dvPageLoiichVuottroiSingleItem, ID: v4() },
	{ ...dvPageLoiichVuottroiSingleItem, ID: v4() },
	{ ...dvPageLoiichVuottroiSingleItem, ID: v4() },
	{ ...dvPageLoiichVuottroiSingleItem, ID: v4() },
];
